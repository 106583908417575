import MyLocationsShowPresentation from '../components/MyLocationsShowPresentation';
import { useEffect, useState } from 'react';
import { makeRequest } from '../services/requests';
import { LocationBackend } from '../services/BackendFrontendInterfaces';

function MyLocationsShowContainer() {
  const [locationsDataRaw, setLocationsDataRaw] = useState<
    LocationBackend[] | null
  >(null);
  const [selectedLocationFromMap, setSelectedLocationFromMap] =
    useState<LocationBackend | null>(null);

  useEffect(() => {
    async function fetchData() {
      const path = `location/get_my_locations`;
      const method = 'GET';
      try {
        const response = await makeRequest({ path: path, method: method });
        if (!response.ok) {
          const response_json = await response.json();
          throw new Error(JSON.stringify(response_json.detail));
        }
        const data = await response.json();
        setLocationsDataRaw(data);
      } catch (error: unknown) {
        if (error instanceof Error) {
          alert(error.message);
        } else if (
          typeof error === 'object' &&
          error !== null &&
          'message' in error &&
          'componentStack' in error
        ) {
          const reactError = error as React.ErrorInfo;
          alert(reactError);
        } else {
          alert(JSON.stringify(error));
        }
      }
    }
    fetchData();
  }, []);

  if (locationsDataRaw === null) {
    return <h1>Cargando...</h1>;
  }

  return (
    <MyLocationsShowPresentation
      locationsDataRaw={locationsDataRaw}
      setSelectedLocationFromMap={setSelectedLocationFromMap}
      selectedLocationFromMap={selectedLocationFromMap}
    />
  );
}

export default MyLocationsShowContainer;
