import React, { useState, useEffect } from 'react';
import { makeRequest } from '../services/requests';
import MyCompletedWorksPresentation from '../components/MyCompletedWorksPresentation';
import { DeadWorkOfferBackend } from '../services/BackendFrontendInterfaces';
import { CardsGridCaller } from '../components/GridCard';
import ApproveUserCompanyContainer from './ApproveUserCompanyContainer';
import DisapproveUserCompanyContainer from './DisapproveUserCompanyContainer';
import EvaluateWorkContainer from './EvaluateWorkContainer';

interface MyCompletedWorksProps {
  id?: CardsGridCaller;
}

function MyCompletedWorksContainer({
  id = 'myDeadWorks',
}: MyCompletedWorksProps) {
  const [worksDataRaw, setWorksDataRaw] = useState<
    DeadWorkOfferBackend[] | null
  >(null);

  const [workToEvaluate, setWorkToEvaluate] =
    useState<DeadWorkOfferBackend | null>(null);
  const [workerToApprove, setWorkerToApprove] =
    useState<DeadWorkOfferBackend | null>(null);
  const [workerToDisapprove, setWorkerToDisapprove] =
    useState<DeadWorkOfferBackend | null>(null);

  const workReview = async (cardData: DeadWorkOfferBackend) => {
    setWorkToEvaluate(cardData);
  };

  const approveWorker = async (cardData: DeadWorkOfferBackend) => {
    setWorkerToApprove(cardData);
  };

  const disapproveWorker = async (cardData: DeadWorkOfferBackend) => {
    setWorkerToDisapprove(cardData);
  };

  useEffect(() => {
    async function fetchData() {
      const path = 'works/get_my_completed_works/';
      const method = 'GET';
      try {
        const response = await makeRequest({ path: path, method: method });
        const data: DeadWorkOfferBackend[] = await response.json();
        setWorksDataRaw(data);
      } catch (error: unknown) {
        if (error instanceof Error) {
          alert(error.message);
        } else if (
          typeof error === 'object' &&
          error !== null &&
          'message' in error &&
          'componentStack' in error
        ) {
          const reactError = error as React.ErrorInfo;
          alert(reactError);
        } else {
          alert(JSON.stringify(error));
        }
      }
    }
    fetchData();
  }, []);

  if (workToEvaluate != null) {
    return (
      <EvaluateWorkContainer
        id={id}
        workDataRaw={workToEvaluate.dead_work}
        evaluation={workToEvaluate.evaluation}
      />
    );
  } else if (workerToApprove != null) {
    return (
      <ApproveUserCompanyContainer
        id={id}
        deadWorkOfferDataRaw={workerToApprove}
      />
    );
  } else if (workerToDisapprove != null) {
    return (
      <DisapproveUserCompanyContainer
        deadWorkOfferDataRaw={workerToDisapprove}
        id={id}
      />
    );
  } else if (worksDataRaw === null) {
    return <h1>Cargando...</h1>;
  } else {
    return (
      <MyCompletedWorksPresentation
        id={id}
        worksDataRaw={worksDataRaw}
        buttonLabelCardOne={`Evaluar Trabajo`}
        buttonLabelCardTwo={`Evaluar Positivamente A Trabajador`}
        buttonLabelCardThree={`Puntos De Mejora Para El Trabajador`}
        handleButtonCardOne={workReview}
        handleButtonCardTwo={approveWorker}
        handleButtonCardThree={disapproveWorker}
      />
    );
  }
}

export default MyCompletedWorksContainer;
