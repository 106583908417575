import React, { useEffect, useState } from 'react';
import {
  LocationBackend,
  LocationCompanyBackend,
  WorkUserBackend,
} from '../services/BackendFrontendInterfaces';

export interface MarkerProps extends google.maps.MarkerOptions {
  onClick?: (event: google.maps.MapMouseEvent) => void;
  onMouseover?: (event: google.maps.MapMouseEvent) => void;
  id: string;
  rawData: LocationBackend | LocationCompanyBackend[] | WorkUserBackend;
  infoWindow?: google.maps.InfoWindow;
}

const MarkerWWrapper: React.FC<MarkerProps> = (options) => {
  const [marker, setMarker] = useState<google.maps.Marker | null>(null);

  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker) {
      // @ts-ignore
      marker.setOptions(options);
      marker.addListener('click', options.onClick!);
      marker.addListener('mouseover', options.onMouseover!);
      if (options.infoWindow && options.map) {
        options.infoWindow.setContent(options.infoWindow.getContent());
        options.infoWindow.open(options.map, marker);
        options.infoWindow.close();
      }
      marker.set('id', options.id);
    }
  }, [marker, options]);

  return null;
};

export default MarkerWWrapper;
