import React, { FC } from 'react';
import '../styles/TreeView.css';

interface TreeProps {
  id: string;
  categories: { [key: string]: string[] };
  selectedCategory: string | null;
  onCategoryClick: (category: string) => void;
  onSubcategoryClick: (event: React.MouseEvent, subcategory: string) => void;
}

const TreeViewPresentation: FC<TreeProps> = ({
  id = 'TreeView',
  categories,
  selectedCategory,
  onCategoryClick,
  onSubcategoryClick,
}) => (
  <div>
    <ul>
      {Object.keys(categories).map((category) => (
        <li
          key={category}
          className="category-button"
          onClick={() => onCategoryClick(category)}
          id={`Title${id}${category}`}
        >
          {category}
          {selectedCategory === category && (
            <ul>
              {categories[category].map((subcategory) => (
                <li
                  id={`Title${id}${category}${subcategory}`}
                  key={subcategory}
                  className="subcategory-button"
                  onClick={(event) => onSubcategoryClick(event, subcategory)}
                >
                  {subcategory}
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  </div>
);

export default TreeViewPresentation;
