import GridCard from './GridCard';
import {
  WorkUserBackend,
  WorkCompanyBackendFromAPI,
} from '../services/BackendFrontendInterfaces';
import { CardsGridCaller } from '../components/GridCard';

interface ReviewMyCompaniesPublishedWorksPresentationProps {
  id?: CardsGridCaller;
  worksDataRaw: WorkUserBackend[] | WorkCompanyBackendFromAPI[];
  handleButtonCardOne?: (cardData: WorkCompanyBackendFromAPI) => void;
  handleButtonCardTwo?: (cardData: WorkCompanyBackendFromAPI) => void;
  buttonLabelCardOne?: string | ((data: any) => string);
  buttonLabelCardTwo?: string | ((data: any) => string);
}

function ReviewMyCompaniesPublishedWorksPresentation({
  id = 'ReviewMyCompaniesPublishedWorks',
  worksDataRaw = [],
  handleButtonCardOne = undefined,
  handleButtonCardTwo = undefined,
  buttonLabelCardOne,
  buttonLabelCardTwo,
}: ReviewMyCompaniesPublishedWorksPresentationProps) {
  return worksDataRaw?.length > 0 ? (
    <GridCard
      cardsData={worksDataRaw}
      handleButtonCardOne={handleButtonCardOne}
      buttonLabelCardOne={buttonLabelCardOne}
      handleButtonCardTwo={handleButtonCardTwo}
      buttonLabelCardTwo={buttonLabelCardTwo}
      id={id}
    />
  ) : (
    <h2>No Se encontraron trabajos disponibles</h2>
  );
}

export default ReviewMyCompaniesPublishedWorksPresentation;
