import React, { useState } from 'react';
import { WorkOfferWMercadolibreBackend } from '../services/BackendFrontendInterfaces';
import { isWorkOfferWMercadolibreBackend } from '../services/InterfaceTypeValidator';
import { initMercadoPago, Wallet } from '@mercadopago/sdk-react';
initMercadoPago(process.env.REACT_APP_MERCADOPAGOPRODPUBLICKEY!);

interface PaymentProps {
  id?: string;
  paymentData: WorkOfferWMercadolibreBackend;
}
const Payment: React.FC<PaymentProps> = ({
  paymentData,
  id = 'paymentOffer',
}) => {
  const host = document.URL.includes('7pro')
    ? `${process.env.REACT_APP_API_ENDPOINT_PRODUCTION}`
    : `${process.env.REACT_APP_API_ENDPOINT_DEVELOPMENT}`;
  const selfie_path: string = `${host}${paymentData.offer.user?.selfie_hidden_path}`;
  const confirmation_message =
    paymentData.offer.user?.selfie_hidden_path != null
      ? 'He confirmado que el contratista todavía tiene disponibilidad en su agenda y se comprometió a hacer el trabajo, he confirmado también que el contratista es quien aparece en la foto y no es una identidad suplantada'
      : 'He confirmado que el contratista todavía tiene disponibilidad en su agenda y se comprometió a hacer el trabajo';
  const [confirmedContractor, setConfirmedContractor] = useState(false);
  let phone: string = '';
  let offerer: string = '';
  let grossPrice: number = 0;
  let extWorksBudjet: number = 0;
  let materialsBudjet: number = 0;
  let tools: number = 0;
  let m1: number =
    // @ts-ignore
    (process.env.REACT_APP_MAXBILLFIRSTTAB -
      // @ts-ignore
      process.env.REACT_APP_MINBILLFIRSTTAB) /
    // @ts-ignore
    (process.env.REACT_APP_MAXPRICEALLOWED -
      // @ts-ignore
      process.env.REACT_APP_MINPRICEALLOWED);
  let m2: number =
    // @ts-ignore
    (process.env.REACT_APP_MAXBILLBOTHTAB -
      // @ts-ignore
      process.env.REACT_APP_MINBILLBOTHTAB) /
    // @ts-ignore
    (process.env.REACT_APP_MAXPRICEALLOWED -
      // @ts-ignore
      process.env.REACT_APP_MINPRICEALLOWED);
  let b1: number =
    // @ts-ignore
    process.env.REACT_APP_MINBILLFIRSTTAB -
    // @ts-ignore
    process.env.REACT_APP_MINPRICEALLOWED * m1;
  let price_1: number = m1 * paymentData.offer.labor_budget + b1;
  let b2: number =
    // @ts-ignore
    process.env.REACT_APP_MINBILLBOTHTAB -
    // @ts-ignore
    process.env.REACT_APP_MINPRICEALLOWED * m2;
  let price_2: number = m2 * paymentData.offer.labor_budget + b2;
  if (paymentData.offer.company != null) {
    offerer = paymentData.offer.company.company_name;
    phone = `${paymentData.offer.company.company_phones[0].company_phone} Ext. ${paymentData.offer.company.company_phones[0].extension}`;
  } else {
    offerer = paymentData.offer.user?.first_name.name!;
    offerer =
      paymentData.offer.user?.second_name != null
        ? offerer.concat(' ', paymentData.offer.user.second_name.name)
        : ' ';
    offerer = offerer.concat(
      ' ',
      paymentData.offer.user?.first_lastname.lastname!,
    );
    offerer = offerer.concat(
      ' ',
      paymentData.offer.user?.second_lastname.lastname!,
    );
    phone = `${paymentData.offer.user?.cell_phones[0].cell_phone} Ext. ${paymentData.offer.user?.cell_phones[0].cell_phone}`;
  }
  grossPrice = grossPrice + paymentData.offer.labor_budget;
  paymentData.offer.external_works?.forEach((externalWork) => {
    grossPrice = grossPrice + externalWork.external_work_budget;
    extWorksBudjet = extWorksBudjet + externalWork.external_work_budget;
  });
  paymentData.offer.materials?.forEach((material) => {
    grossPrice = grossPrice + material.material_budget;
    materialsBudjet = materialsBudjet + material.material_budget;
  });
  paymentData.offer.tools?.forEach((tool) => {
    grossPrice = grossPrice + tool.tool_budget;
    tools = tools + tool.tool_budget;
  });
  let percentage_1: number = price_1 / grossPrice;

  const handleConfirmedContractor = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setConfirmedContractor(event.target.value === 'yes');
  };

  return (
    <div>
      {paymentData.offer.user?.selfie_hidden_path && (
        <div>
          <img
            src={selfie_path}
            alt="Foto del oferente"
            style={{
              width: '250px',
              height: '250px',
              borderRadius: '50%',
              objectFit: 'cover',
              marginRight: '1rem',
            }}
          />
        </div>
      )}
      <h2>{offerer}</h2>
      <h2>{phone}</h2>
      <h2>Costo Total De La Propuesta $ {grossPrice}</h2>
      <h2>Costo Mano De Obra $ {paymentData.offer.labor_budget}</h2>
      <h2>Desglose del costo total</h2>
      <h3>Presupuesto Para Trabajos Externos ${extWorksBudjet}</h3>
      {paymentData.offer.external_works?.map((extWork, index) => (
        <p key={index}>
          {extWork.external_work.external_work} - $
          {extWork.external_work_budget}
        </p>
      ))}
      <h3>Presupuesto Para Materiales ${materialsBudjet}</h3>
      {paymentData.offer.materials?.map((material, index) => (
        <p key={index}>
          {material.material.material} - ${material.material_budget}
        </p>
      ))}
      <h3>Presupuesto Para Herramientas ${tools}</h3>
      {paymentData.offer.tools?.map((tool, index) => (
        <p key={index}>
          {tool.tool.tool} - ${tool.tool_budget}
        </p>
      ))}
      <h3>Fecha De Inicio Propuesta: {paymentData.offer.start_date_time}</h3>
      <h3>Fecha De Término Propuesta: {paymentData.offer.end_date_time}</h3>
      <h2>Cobro Por Aceptar La Propuesta ${price_1.toFixed(2)}</h2>
      <h2>
        La Plataforma Cobra {(percentage_1 * 100).toFixed(2)}% Del Costo Total
        Por Su Uso Para Este Trabajo
      </h2>

      <label
        htmlFor="contractorConfirmation"
        id={'labelcontractorConfirmation'}
      >
        {confirmation_message}
      </label>
      <select id="contractorConfirmation" onChange={handleConfirmedContractor}>
        <option value="no">No</option>
        <option value="yes">Sí</option>
      </select>

      {confirmedContractor && (
        <div id={`wallet${id}`}>
          <Wallet
            initialization={{ preferenceId: `${paymentData.preference_id!}` }}
            customization={{ texts: { valueProp: 'smart_option' } }}
          />
        </div>
      )}
    </div>
  );
};

export default Payment;
