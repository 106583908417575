import React from 'react';
import {
  WorkUserBackend,
  WorkOfferBackend,
  DeadWorkOfferBackend,
  ApproveUserCompanyBackend,
  DisapproveUserCompanyBackend,
  LocationBackend,
  WorkCompanyBackendFromAPI,
} from '../services/BackendFrontendInterfaces';
import {
  isLocationBackend,
  isWorkUserBackend,
  isWorkOfferBackend,
  isDeadWorkOfferBackend,
  isWorkCompanyBackendFromAPI,
  isCompanyBackend,
  isApproveUserCompanyBackend,
  isDisapproveUserCompanyBackend,
} from '../services/InterfaceTypeValidator';

interface CardProps {
  cardData:
    | LocationBackend
    | WorkUserBackend
    | WorkOfferBackend
    | DeadWorkOfferBackend
    | ApproveUserCompanyBackend
    | DisapproveUserCompanyBackend
    | WorkCompanyBackendFromAPI;
  handleButtonCardOne?:
    | ((cardData: LocationBackend) => void)
    | ((cardData: WorkUserBackend) => void)
    | ((cardData: WorkOfferBackend) => void)
    | ((cardData: DeadWorkOfferBackend) => void)
    | ((cardData: ApproveUserCompanyBackend) => void)
    | ((cardData: DisapproveUserCompanyBackend) => void)
    | ((cardData: WorkCompanyBackendFromAPI) => void);
  handleButtonCardTwo?:
    | ((cardData: LocationBackend) => void)
    | ((cardData: WorkUserBackend) => void)
    | ((cardData: WorkOfferBackend) => void)
    | ((cardData: DeadWorkOfferBackend) => void)
    | ((cardData: ApproveUserCompanyBackend) => void)
    | ((cardData: DisapproveUserCompanyBackend) => void)
    | ((cardData: WorkCompanyBackendFromAPI) => void);
  handleButtonCardThree?:
    | ((cardData: LocationBackend) => void)
    | ((cardData: WorkUserBackend) => void)
    | ((cardData: WorkOfferBackend) => void)
    | ((cardData: ApproveUserCompanyBackend) => void)
    | ((cardData: DisapproveUserCompanyBackend) => void)
    | ((cardData: DeadWorkOfferBackend) => void)
    | ((cardData: WorkCompanyBackendFromAPI) => void);
  buttonLabelCardOne?: string;
  buttonLabelCardTwo?: string;
  buttonLabelCardThree?: string;
  id: string;
}

const Card: React.FC<CardProps> = ({
  cardData,
  id,
  handleButtonCardOne = undefined,
  handleButtonCardTwo = undefined,
  handleButtonCardThree = undefined,
  buttonLabelCardOne,
  buttonLabelCardTwo,
  buttonLabelCardThree,
}) => {
  const internalHandleButtonCardOne = (event: React.FormEvent) => {
    if (handleButtonCardOne) {
      // @ts-ignore
      handleButtonCardOne(cardData);
    }
  };

  const toGoogleCalendarFormat = (dateString: string): string => {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');
    return `${year}${month}${day}T${hours}${minutes}${seconds}Z`;
  };

  const internalHandleButtonCardTwo = (event: React.FormEvent) => {
    if (handleButtonCardTwo) {
      // @ts-ignore
      handleButtonCardTwo(cardData);
    }
  };

  const internalHandleButtonCardThree = (event: React.FormEvent) => {
    if (handleButtonCardThree) {
      // @ts-ignore
      handleButtonCardThree(cardData);
    }
  };

  const createCardData = () => {
    if (isWorkUserBackend(cardData)) {
      const startDateCalendar = toGoogleCalendarFormat(
        cardData.start_date_time,
      );
      const endDateCalendar = toGoogleCalendarFormat(cardData.end_date_time);
      const cardDataRender: React.ReactNode = (
        <div>
          <p>{cardData.summary}</p>
          <p>
            {cardData.location.street.street_name}
            {cardData.location.outer_number}
            {cardData.location.outer_letter}
            {cardData.location.inner_number}
            {cardData.location.inner_letter}
          </p>
          <p>{cardData.start_date_time}</p>
          <p>{cardData.end_date_time}</p>
          {cardData.taken_by != null ? (
            <a
              href={`https://calendar.google.com/calendar/r/eventedit?text=${encodeURIComponent(
                cardData.summary,
              )}&dates=${startDateCalendar}/${endDateCalendar}&details=${encodeURIComponent(
                cardData.summary,
              )}&location=${
                cardData.location.gps_center_maps_link
              }&sf=true&output=xml`}
            >
              Agregar Al Calendario
            </a>
          ) : (
            <p></p>
          )}
          {handleButtonCardOne != null &&
            buttonLabelCardOne != null &&
            buttonLabelCardOne != '' && (
              <button
                id={`buttonCardWorkOne${id}`}
                onClick={internalHandleButtonCardOne}
                type={'button'}
              >
                {buttonLabelCardOne}
              </button>
            )}
          {handleButtonCardTwo != null &&
            buttonLabelCardTwo != null &&
            buttonLabelCardTwo != '' && (
              <button
                id={`buttonCardWorkTwo${id}`}
                onClick={internalHandleButtonCardTwo}
                type={'button'}
              >
                {buttonLabelCardTwo}
              </button>
            )}
          {handleButtonCardThree != null &&
            buttonLabelCardThree != null &&
            buttonLabelCardThree != '' && (
              <button
                id={`buttonCardWorkThree${id}`}
                onClick={internalHandleButtonCardThree}
                type={'button'}
              >
                {buttonLabelCardThree}
              </button>
            )}
        </div>
      );
      return cardDataRender;
    } else if (isWorkOfferBackend(cardData)) {
      let offerer: string = '';
      let phone: string = '';
      let grossPrice: number = 0;
      let extWorksBudjet: number = 0;
      let materialsBudjet: number = 0;
      let tools: number = 0;
      const startDateCalendar = toGoogleCalendarFormat(
        cardData.start_date_time,
      );
      const endDateCalendar = toGoogleCalendarFormat(cardData.end_date_time);
      let m1: number =
        // @ts-ignore
        (process.env.REACT_APP_MAXBILLFIRSTTAB -
          // @ts-ignore
          process.env.REACT_APP_MINBILLFIRSTTAB) /
        // @ts-ignore
        (process.env.REACT_APP_MAXPRICEALLOWED -
          // @ts-ignore
          process.env.REACT_APP_MINPRICEALLOWED);
      let m2: number =
        // @ts-ignore
        (process.env.REACT_APP_MAXBILLBOTHTAB -
          // @ts-ignore
          process.env.REACT_APP_MINBILLBOTHTAB) /
        // @ts-ignore
        (process.env.REACT_APP_MAXPRICEALLOWED -
          // @ts-ignore
          process.env.REACT_APP_MINPRICEALLOWED);
      let b1: number =
        // @ts-ignore
        process.env.REACT_APP_MINBILLFIRSTTAB -
        // @ts-ignore
        process.env.REACT_APP_MINPRICEALLOWED * m1;
      let price_1: number = m1 * cardData.labor_budget + b1;
      let b2: number =
        // @ts-ignore
        process.env.REACT_APP_MINBILLBOTHTAB -
        // @ts-ignore
        process.env.REACT_APP_MINPRICEALLOWED * m2;
      let price_2: number = m2 * cardData.labor_budget + b2;
      if (cardData.company != null) {
        offerer = cardData.company.company_name;
        phone = `${cardData.company.company_phones[0].company_phone} Ext. ${cardData.company.company_phones[0].extension}`;
      } else {
        offerer = cardData.user?.first_name.name!;
        offerer =
          cardData.user?.second_name != null
            ? offerer.concat(' ', cardData.user.second_name.name)
            : ' ';
        offerer = offerer.concat(' ', cardData.user?.first_lastname.lastname!);
        offerer = offerer.concat(' ', cardData.user?.second_lastname.lastname!);
        phone = `${cardData.user?.cell_phones[0].cell_phone}`;
      }
      grossPrice = grossPrice + cardData.labor_budget;
      cardData.external_works?.forEach((externalWork) => {
        grossPrice = grossPrice + externalWork.external_work_budget;
        extWorksBudjet = extWorksBudjet + externalWork.external_work_budget;
      });
      cardData.materials?.forEach((material) => {
        grossPrice = grossPrice + material.material_budget;
        materialsBudjet = materialsBudjet + material.material_budget;
      });
      cardData.tools?.forEach((tool) => {
        grossPrice = grossPrice + tool.tool_budget;
        tools = tools + tool.tool_budget;
      });
      let percentage_1: number = price_1 / grossPrice;
      let percentage_2: number = price_2 / grossPrice;
      const cardDataRender: React.ReactNode = (
        <div>
          <h2>{offerer}</h2>
          <h2>{phone}</h2>
          <h2>Costo Total De La Propuesta $ {grossPrice}</h2>
          <h2>Costo Mano De Obra $ {cardData.labor_budget}</h2>
          <h2>Desglose del costo total</h2>
          <h3>Presupuesto Para Trabajos Externos ${extWorksBudjet}</h3>
          {cardData.external_works?.map((extWork, index) => (
            <p key={index}>
              {extWork.external_work.external_work} - $
              {extWork.external_work_budget}
            </p>
          ))}
          <h3>Presupuesto Para Materiales ${materialsBudjet}</h3>
          {cardData.materials?.map((material, index) => (
            <p key={index}>
              {material.material.material} - ${material.material_budget}
            </p>
          ))}
          <h3>Presupuesto Para Herramientas ${tools}</h3>
          {cardData.tools?.map((tool, index) => (
            <p key={index}>
              {tool.tool.tool} - ${tool.tool_budget}
            </p>
          ))}
          <h3>Fecha De Inicio Propuesta: {cardData.start_date_time}</h3>
          <h3>Fecha De Término Propuesta: {cardData.end_date_time}</h3>
          <h2>
            Costo Por Aceptar La Propuesta En La Plataforma${price_1.toFixed(2)}
          </h2>
          <h2>
            Costo Prcentual De Cobro Para La Pataforma{' '}
            {(percentage_1 * 100).toFixed(2)}%
          </h2>
          {cardData.work.taken_by != null ? (
            <a
              href={`https://calendar.google.com/calendar/r/eventedit?text=${encodeURIComponent(
                cardData.work.summary,
              )}&dates=${startDateCalendar}/${endDateCalendar}&details=${encodeURIComponent(
                cardData.work.summary,
              )}&location=${
                cardData.work.location.gps_center_maps_link
              }&sf=true&output=xml`}
            >
              Agregar Al Calendario
            </a>
          ) : (
            <p></p>
          )}
          {handleButtonCardOne != null &&
            buttonLabelCardOne != null &&
            buttonLabelCardOne != '' && (
              <button
                id={`buttonCardWorkOfferOne${id}`}
                onClick={internalHandleButtonCardOne}
                type={'button'}
              >
                {buttonLabelCardOne}
              </button>
            )}
          {handleButtonCardTwo != null &&
            buttonLabelCardTwo != null &&
            buttonLabelCardTwo != '' && (
              <button
                id={`buttonCardWorkOfferTwo${id}`}
                onClick={internalHandleButtonCardTwo}
                type={'button'}
              >
                {buttonLabelCardTwo}
              </button>
            )}
          {handleButtonCardThree != null &&
            buttonLabelCardThree != null &&
            buttonLabelCardThree != '' && (
              <button
                id={`buttonCardWorkOfferThree${id}`}
                onClick={internalHandleButtonCardThree}
                type={'button'}
              >
                {buttonLabelCardThree}
              </button>
            )}
        </div>
      );
      return cardDataRender;
    } else if (isApproveUserCompanyBackend(cardData)) {
      let offerer: string = '';
      let phone: string = '';
      if (isCompanyBackend(cardData.approved)) {
        offerer = cardData.approved.company_name;
        phone = `${cardData.approved.company_phones[0].company_phone} Ext. ${cardData.approved.company_phones[0].extension}`;
      } else {
        offerer = cardData.approved?.first_name.name!;
        offerer =
          cardData.approved?.second_name != null
            ? offerer.concat(' ', cardData.approved.second_name.name)
            : ' ';
        offerer = offerer.concat(
          ' ',
          cardData.approved?.first_lastname.lastname!,
        );
        offerer = offerer.concat(
          ' ',
          cardData.approved?.second_lastname.lastname!,
        );
        phone = `${cardData.approved?.cell_phones[0].cell_phone}`;
      }
      const cardDataRender: React.ReactNode = (
        <div style={{ color: '#1a294f' }}>
          <h2>{offerer}</h2>
          <h2>{phone}</h2>
          <h2>{cardData.approval.rate}</h2>
          <h2>{cardData.approval.summary}</h2>
          <h2>{cardData.approval.details}</h2>
          {handleButtonCardOne != null &&
            buttonLabelCardOne != null &&
            buttonLabelCardOne != '' && (
              <button
                id={`buttonCardWorkOfferOne${id}`}
                onClick={internalHandleButtonCardOne}
                type={'button'}
              >
                {buttonLabelCardOne}
              </button>
            )}
          {handleButtonCardTwo != null &&
            buttonLabelCardTwo != null &&
            buttonLabelCardTwo != '' && (
              <button
                id={`buttonCardWorkOfferTwo${id}`}
                onClick={internalHandleButtonCardTwo}
                type={'button'}
              >
                {buttonLabelCardTwo}
              </button>
            )}
          {handleButtonCardThree != null &&
            buttonLabelCardThree != null &&
            buttonLabelCardThree != '' && (
              <button
                id={`buttonCardWorkOfferThree${id}`}
                onClick={internalHandleButtonCardThree}
                type={'button'}
              >
                {buttonLabelCardThree}
              </button>
            )}
        </div>
      );
      return cardDataRender;
    } else if (isDisapproveUserCompanyBackend(cardData)) {
      let offerer: string = '';
      let phone: string = '';
      if (isCompanyBackend(cardData.disapproved)) {
        offerer = cardData.disapproved.company_name;
        phone = `${cardData.disapproved.company_phones[0].company_phone} Ext. ${cardData.disapproved.company_phones[0].extension}`;
      } else {
        offerer = cardData.disapproved?.first_name.name!;
        offerer =
          cardData.disapproved?.second_name != null
            ? offerer.concat(' ', cardData.disapproved.second_name.name)
            : ' ';
        offerer = offerer.concat(
          ' ',
          cardData.disapproved?.first_lastname.lastname!,
        );
        offerer = offerer.concat(
          ' ',
          cardData.disapproved?.second_lastname.lastname!,
        );
        phone = `${cardData.disapproved?.cell_phones[0].cell_phone}`;
      }
      const cardDataRender: React.ReactNode = (
        <div style={{ color: '#5c1a0f' }}>
          <h2>{offerer}</h2>
          <h2>{phone}</h2>
          {cardData.disapproval.scammer ? <h2>Estafador</h2> : <p></p>}
          <h2>{cardData.disapproval.rate}</h2>
          <h2>{cardData.disapproval.summary}</h2>
          <h2>{cardData.disapproval.details}</h2>
          {handleButtonCardOne != null &&
            buttonLabelCardOne != null &&
            buttonLabelCardOne != '' && (
              <button
                id={`buttonCardWorkOfferOne${id}`}
                onClick={internalHandleButtonCardOne}
                type={'button'}
              >
                {buttonLabelCardOne}
              </button>
            )}
          {handleButtonCardTwo != null &&
            buttonLabelCardTwo != null &&
            buttonLabelCardTwo != '' && (
              <button
                id={`buttonCardWorkOfferTwo${id}`}
                onClick={internalHandleButtonCardTwo}
                type={'button'}
              >
                {buttonLabelCardTwo}
              </button>
            )}
          {handleButtonCardThree != null &&
            buttonLabelCardThree != null &&
            buttonLabelCardThree != '' && (
              <button
                id={`buttonCardWorkOfferThree${id}`}
                onClick={internalHandleButtonCardThree}
                type={'button'}
              >
                {buttonLabelCardThree}
              </button>
            )}
        </div>
      );
      return cardDataRender;
    } else if (isDeadWorkOfferBackend(cardData)) {
      let offerer: string = '';
      let grossPrice: number = 0;
      let extWorksBudjet: number = 0;
      let materialsBudjet: number = 0;
      let tools: number = 0;
      if (cardData.company != null) {
        offerer = cardData.company.company_name;
      } else {
        offerer = cardData.user?.first_name.name!;
        offerer =
          cardData.user?.second_name != null
            ? offerer.concat(' ', cardData.user.second_name.name)
            : ' ';
        offerer = offerer.concat(' ', cardData.user?.first_lastname.lastname!);
        offerer = offerer.concat(' ', cardData.user?.second_lastname.lastname!);
      }
      grossPrice = grossPrice + cardData.labor_budget;
      cardData.external_works?.forEach((externalWork) => {
        grossPrice = grossPrice + externalWork.external_work_budget;
        extWorksBudjet = extWorksBudjet + externalWork.external_work_budget;
      });
      cardData.materials?.forEach((material) => {
        grossPrice = grossPrice + material.material_budget;
        materialsBudjet = materialsBudjet + material.material_budget;
      });
      cardData.tools?.forEach((tool) => {
        grossPrice = grossPrice + tool.tool_budget;
        tools = tools + tool.tool_budget;
      });
      const cardDataRender: React.ReactNode = (
        <div>
          <h2>{offerer}</h2>
          <h2 style={{ color: '#664000' }}>
            Calificación: {cardData.evaluation?.rate}
          </h2>
          <h2 style={{ color: '#664000' }}>
            Opinión: {cardData.evaluation?.summary}
          </h2>
          <h2>Costo Total De La Propuesta $ {grossPrice}</h2>
          <h2>Desglose del costo total</h2>
          <h3>Presupuesto Para Trabajos Externos ${extWorksBudjet}</h3>
          {cardData.external_works?.map((extWork, index) => (
            <p key={index}>
              {extWork.external_work.external_work} - $
              {extWork.external_work_budget}
            </p>
          ))}
          <h3>Presupuesto Para Materiales ${materialsBudjet}</h3>
          {cardData.materials?.map((material, index) => (
            <p key={index}>
              {material.material.material} - ${material.material_budget}
            </p>
          ))}
          <h3>Presupuesto Para Herramientas ${tools}</h3>
          {cardData.tools?.map((tool, index) => (
            <p key={index}>
              {tool.tool.tool} - ${tool.tool_budget}
            </p>
          ))}
          <h3>Fecha De Inicio Propuesta: {cardData.start_date_time}</h3>
          <h3>Fecha De Término Propuesta: {cardData.end_date_time}</h3>
          {handleButtonCardOne != null &&
            buttonLabelCardOne != null &&
            buttonLabelCardOne != '' && (
              <button
                id={`buttonCardWorkOfferOne${id}`}
                onClick={internalHandleButtonCardOne}
                type={'button'}
              >
                {buttonLabelCardOne}
              </button>
            )}
          {handleButtonCardTwo != null &&
            buttonLabelCardTwo != null &&
            buttonLabelCardTwo != '' && (
              <button
                id={`buttonCardWorkOfferTwo${id}`}
                onClick={internalHandleButtonCardTwo}
                type={'button'}
              >
                {buttonLabelCardTwo}
              </button>
            )}
          {handleButtonCardThree != null &&
            buttonLabelCardThree != null &&
            buttonLabelCardThree != '' && (
              <button
                id={`buttonCardWorkOfferThree${id}`}
                onClick={internalHandleButtonCardThree}
                type={'button'}
              >
                {buttonLabelCardThree}
              </button>
            )}
        </div>
      );
      return cardDataRender;
    } else if (isLocationBackend(cardData)) {
      const cardDataRender: React.ReactNode = (
        <div>
          <p>
            {cardData.street.street_name}
            {cardData.outer_number}
            {cardData.outer_letter}
            {cardData.inner_number}
            {cardData.inner_letter}
          </p>
          {handleButtonCardOne != null &&
            buttonLabelCardOne != null &&
            buttonLabelCardOne != '' && (
              <button
                id={
                  isWorkOfferBackend(cardData) ||
                  isDeadWorkOfferBackend(cardData)
                    ? `buttonCardWorkOfferOne${id}`
                    : `buttonCardWorkOne${id}`
                }
                onClick={internalHandleButtonCardOne}
                type={'button'}
              >
                {buttonLabelCardOne}
              </button>
            )}
          {handleButtonCardTwo != null &&
            buttonLabelCardTwo != null &&
            buttonLabelCardTwo != '' && (
              <button
                id={
                  isWorkOfferBackend(cardData) ||
                  isDeadWorkOfferBackend(cardData)
                    ? `buttonCardWorkOfferTwo${id}`
                    : `buttonCardWorkTwo${id}`
                }
                onClick={internalHandleButtonCardTwo}
                type={'button'}
              >
                {buttonLabelCardTwo}
              </button>
            )}
          {handleButtonCardThree != null &&
            buttonLabelCardThree != null &&
            buttonLabelCardThree != '' && (
              <button
                id={
                  isWorkOfferBackend(cardData) ||
                  isDeadWorkOfferBackend(cardData)
                    ? `buttonCardWorkOfferThree${id}`
                    : `buttonCardWorkThree${id}`
                }
                onClick={internalHandleButtonCardThree}
                type={'button'}
              >
                {buttonLabelCardThree}
              </button>
            )}
        </div>
      );
      return cardDataRender;
    } else if (isWorkCompanyBackendFromAPI(cardData)) {
      const startDateCalendar = toGoogleCalendarFormat(
        cardData.work.start_date_time,
      );
      const endDateCalendar = toGoogleCalendarFormat(
        cardData.work.end_date_time,
      );
      const cardDataRender: React.ReactNode = (
        <div>
          <p>{cardData.work.summary}</p>
          <p>
            {cardData.work.location.street.street_name}
            {cardData.work.location.outer_number}
            {cardData.work.location.outer_letter}
            {cardData.work.location.inner_number}
            {cardData.work.location.inner_letter}
          </p>
          <p>{cardData.work.start_date_time}</p>
          <p>{cardData.work.end_date_time}</p>
          {cardData.work.taken_by != null ? (
            <a
              href={`https://calendar.google.com/calendar/r/eventedit?text=${encodeURIComponent(
                cardData.work.summary,
              )}&dates=${startDateCalendar}/${endDateCalendar}&details=${encodeURIComponent(
                cardData.work.summary,
              )}&location=${
                cardData.work.location.gps_center_maps_link
              }&sf=true&output=xml`}
            >
              Agregar Al Calendario
            </a>
          ) : (
            <p></p>
          )}
          {handleButtonCardOne != null &&
            buttonLabelCardOne != null &&
            buttonLabelCardOne != '' && (
              <button
                id={`buttonCardWorkOne${id}`}
                onClick={internalHandleButtonCardOne}
                type={'button'}
              >
                {buttonLabelCardOne}
              </button>
            )}
          {handleButtonCardTwo != null &&
            buttonLabelCardTwo != null &&
            buttonLabelCardTwo != '' && (
              <button
                id={`buttonCardWorkTwo${id}`}
                onClick={internalHandleButtonCardTwo}
                type={'button'}
              >
                {buttonLabelCardTwo}
              </button>
            )}
          {handleButtonCardThree != null &&
            buttonLabelCardThree != null &&
            buttonLabelCardThree != '' && (
              <button
                id={`buttonCardWorkThree${id}`}
                onClick={internalHandleButtonCardThree}
                type={'button'}
              >
                {buttonLabelCardThree}
              </button>
            )}
        </div>
      );
      return cardDataRender;
    } else {
      return <p>Option Undefined</p>;
    }
  };

  return <div>{createCardData()}</div>;
};

export default Card;
