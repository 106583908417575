import React, { useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import menuOptions from '../config/menuOptions.json';
import { getIcon } from '../services/menuServices';
import '../styles/Menu.css';
import { SessionContext } from '../services/SessionContextProvider';

interface MenuProps {
  id?: string;
  selectOption: (option: string) => void;
}

function Menu({ id = 'MainMenu', selectOption }: MenuProps) {
  const { userLogged } = useContext(SessionContext);
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

  const toggleMenu: () => void = () => {
    setMenuIsOpen(!menuIsOpen);
  };

  interface ToggleSubMenu {
    id: string;
    subMenusAreOpen: { [key: string]: boolean };
    setSubmenusAreOpen: React.Dispatch<
      React.SetStateAction<{ [key: string]: boolean }>
    >;
  }

  /**
   * This function toggle the submenu from open <- -> close
   */
  const toggleSubmenu = ({
    id = 'Menu',
    subMenusAreOpen,
    setSubmenusAreOpen,
  }: ToggleSubMenu) => {
    setSubmenusAreOpen((prevState) => ({
      ...prevState,
      [id]: !subMenusAreOpen[id] || false,
    }));
  };
  interface Option {
    id: string;
    icono: string;
    texto: string;
    submenu?: Option[];
  }

  const Opcion = ({
    option,
    subMenusAreOpen,
    setSubmenusAreOpen,
  }: {
    option: Option;
    subMenusAreOpen: { [key: string]: boolean };
    setSubmenusAreOpen: React.Dispatch<
      React.SetStateAction<{ [key: string]: boolean }>
    >;
  }) => {
    const submenuIsOpen = subMenusAreOpen[option.id] || false;

    const handleClick = (event: React.MouseEvent) => {
      event.stopPropagation();
      if (option.submenu) {
        toggleSubmenu({ id: option.id, subMenusAreOpen, setSubmenusAreOpen });
      } else {
        selectOption(option.id);
        setMenuIsOpen(false);
      }
    };

    const icon = getIcon(option.icono);

    return (
      <li key={option.id} onClick={handleClick}>
        <FontAwesomeIcon icon={icon} />
        <span className="menu-item-text" id={option.id}>
          {option.texto}
        </span>
        {option.submenu && submenuIsOpen && (
          <ul className={`submenu${submenuIsOpen ? ' open' : ''}`}>
            {option.submenu.map((subOption) => (
              <Opcion
                option={subOption}
                subMenusAreOpen={subMenusAreOpen}
                setSubmenusAreOpen={setSubmenusAreOpen}
                key={subOption.id}
              />
            ))}
          </ul>
        )}
      </li>
    );
  };

  interface MenuOption {
    id: string;
    login_required: boolean;
  }

  const shownOptions = menuOptions.filter((option: MenuOption) => {
    if ((option.login_required && userLogged) || !option.login_required) {
      return true;
    }
    return false;
  });

  const initialSubMenusState: Record<string, boolean> = shownOptions.reduce(
    (acc, option) => {
      if (option.submenu) {
        acc[option.id] = false;
      }
      return acc;
    },
    {} as Record<string, boolean>,
  );

  const [subMenusAreOpen, setSubmenusAreOpen] = useState(initialSubMenusState);

  return (
    <nav>
      <button
        className="toggle-menu"
        onClick={toggleMenu}
        id={`button${id}`}
        type={'button'}
      >
        {menuIsOpen ? 'Cerrar' : 'Abrir'} menú
      </button>
      <ul className={`menu${menuIsOpen ? ' open' : ''}`}>
        {shownOptions.map((option) => (
          <Opcion
            option={option}
            subMenusAreOpen={subMenusAreOpen}
            setSubmenusAreOpen={setSubmenusAreOpen}
            key={option.id}
          />
        ))}
      </ul>
    </nav>
  );
}

export default Menu;
