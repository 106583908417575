import { useState, useContext, useEffect } from 'react';
import ChatbotHTTPContainer from '../containers/ChatbotHTTPContainer';
import { SessionContext } from '../services/SessionContextProvider';

function Footer() {
  const [showChat, setShowChat] = useState(false);
  const toggleChat = () => setShowChat(!showChat);
  const {
    componentSelected,
    publishWorkChatbotEnable,
    publishWorkChatbotCity,
    publishWorkChatbotWorker,
    publishWorkChatbotStartDate,
    setPublishWorkChatbot,
  } = useContext(SessionContext);

  useEffect(() => {}, [publishWorkChatbotEnable, componentSelected]);

  useEffect(() => {
    const allNonEmpty = [
      publishWorkChatbotCity,
      publishWorkChatbotWorker,
      publishWorkChatbotStartDate,
    ].every((value) => value !== '');
    if (allNonEmpty) {
      setPublishWorkChatbot(true);
    } else {
      setPublishWorkChatbot(false);
    }
  }, [publishWorkChatbotCity]);

  useEffect(() => {
    const allNonEmpty = [
      publishWorkChatbotCity,
      publishWorkChatbotWorker,
      publishWorkChatbotStartDate,
    ].every((value) => value !== '');
    if (allNonEmpty) {
      setPublishWorkChatbot(true);
    } else {
      setPublishWorkChatbot(false);
    }
  }, [publishWorkChatbotWorker]);

  useEffect(() => {
    const allNonEmpty = [
      publishWorkChatbotCity,
      publishWorkChatbotWorker,
      publishWorkChatbotStartDate,
    ].every((value) => value !== '');
    if (allNonEmpty) {
      setPublishWorkChatbot(true);
    } else {
      setPublishWorkChatbot(false);
    }
  }, [publishWorkChatbotStartDate]);

  return (
    <footer
      style={{
        textAlign: 'center',
        padding: '20px',
        backgroundColor: '#E0E7EF',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          textAlign: 'left',
        }}
      >
        <div>
          <h3>Legal</h3>
          <ul style={{ listStyleType: 'none', padding: 0 }}>
            <li>
              <a
                href="/static/CONTRATO CLIENTES Y PROVEEDORES.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Contrato De Adhesión Para El Uso De La Plataforma
              </a>
            </li>
            <li>
              <a
                href="/static/confidentiality_agreement"
                target="_blank"
                rel="noopener noreferrer"
              >
                Aviso De Privacidad
              </a>
            </li>
            {componentSelected!.toLowerCase().includes('publishwork') && (
              <li>
                <button
                  id={`buttonChatbot`}
                  type={'button'}
                  onClick={toggleChat}
                  disabled={!publishWorkChatbotEnable}
                >
                  {showChat ? 'Cerrar Chat' : 'Abrir Chat De Ayuda'}
                </button>
                {showChat && <ChatbotHTTPContainer />}
              </li>
            )}
            {/* <li>
              <a href="/static/about" target="_blank" rel="noopener noreferrer">
                Acerca de nosotros
              </a>
            </li> */}
          </ul>
        </div>
        <div>
          <h3>Uso</h3>
          <ul style={{ listStyleType: 'none', padding: 0 }}>
            <li>
              <a
                href="https://www.youtube.com/channel/UCFYN0iybgjrKm3dN1mqxuMQ"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-youtube"></i> Tutoriales Y Comunicados
              </a>
            </li>
            {/* <li>
              <a href="/static/faq" target="_blank" rel="noopener noreferrer">
                Preguntas frecuentes
              </a>
            </li> */}
          </ul>
        </div>
      </div>
    </footer>
  );
  // <div>
  //   <h3>Contacto</h3>
  //   <ul style={{ listStyleType: 'none', padding: 0 }}>
  //     <li>Correo de soporte: <a href="mailto:mecanismosfavira@gmail.com">mecanismosfavira@gmail.com</a></li>
  //     <li>
  //       Redes sociales:
  //       <div>
  //         <a href="https://www.facebook.com/tu_pagina" target="_blank" rel="noopener noreferrer" style={{ margin: '0 10px' }}>
  //           <i className="fab fa-facebook"></i>
  //         </a>
  //         <a href="https://www.instagram.com/tu_pagina" target="_blank" rel="noopener noreferrer" style={{ margin: '0 10px' }}>
  //           <i className="fab fa-instagram"></i>
  //         </a>
  //         <a href="https://www.linkedin.com/tu_pagina" target="_blank" rel="noopener noreferrer" style={{ margin: '0 10px' }}>
  //           <i className="fab fa-linkedin"></i>
  //         </a>
  //         <a href="https://www.youtube.com/tu_canal" target="_blank" rel="noopener noreferrer" style={{ margin: '0 10px' }}>
  //           <i className="fab fa-youtube"></i>
  //         </a>
  //       </div>
  //     </li>
  //   </ul>
  // </div>
  // <p style={{ marginTop: '20px' }}>Marca Registrada&reg; 2024</p>
}

export default Footer;
