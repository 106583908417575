import React, { SetStateAction, useEffect, Dispatch, useState } from 'react';
import WrapperForMap from '../components/WrapperForMap';
import LocationFormContainer from '../containers/LocationFormContainer';
import { LocationBackend } from '../services/BackendFrontendInterfaces';

interface MyLocationsShowProps {
  id?: string;
  locationsDataRaw: LocationBackend[];
  setSelectedLocationFromMap: Dispatch<SetStateAction<LocationBackend | null>>;
  selectedLocationFromMap: LocationBackend | null;
}

const MyLocationsShowPresentation: React.FC<MyLocationsShowProps> = ({
  id = 'myLocationsShow',
  locationsDataRaw,
  setSelectedLocationFromMap,
  selectedLocationFromMap,
}) => {
  if (locationsDataRaw.length === 0) {
    return <LocationFormContainer readOnly={false} />;
  }

  return selectedLocationFromMap != null ? (
    <LocationFormContainer
      id={`LocationForm${id}`}
      readOnly={true}
      locationDataRaw={selectedLocationFromMap}
      buttonLabel={'Borrar Ubicacion'}
    />
  ) : (
    <WrapperForMap
      id={`wrapperForMap${id}`}
      locationsDataRawList={locationsDataRaw}
      setReturnedData={setSelectedLocationFromMap}
    />
  );
};

export default MyLocationsShowPresentation;
