import { fas } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(fas);

function getIcon(name: string) {
  return fas[name];
}

export { getIcon };
