import React, { useState, useEffect, useContext } from 'react';
import PublishWorkPresentation from '../components/PublishWorkPresentation';
import constants from '../data/constants.json';
import { makeRequest } from '../services/requests';
import { Category } from '../services/TreeView';
import CompanyTypeSpanishCat from '../data/company_type_spanish_cat.json';
import CompanyTypeSpanishSubCat from '../data/company_type_spanish_subcat.json';
import {
  WorkUserFrontend,
  WorkUserBackend,
  LocationFrontend,
  LocationBackend,
  LocationCompanyBackend,
  FormDataModes,
  WorkCompanyBackendFromAPI,
} from '../services/BackendFrontendInterfaces';
import { prepareWorkTypeData } from '../services/PublishWorkService';
import MakeOfferContainer from '../containers/MakeOfferContainer';
import MakeCompanyOfferContainer from './MakeCompanyOfferContainer';
import {
  isLocationBackend,
  isLocationCompanyBackend,
  isWorkCompanyBackendFromAPI,
  isWorkUserBackend,
} from '../services/InterfaceTypeValidator';
import { SessionContext } from '../services/SessionContextProvider';

interface ContainerProps {
  handleSubmitFather?: (event: React.FormEvent) => void;
  onChange?: null | ((workData: WorkUserFrontend) => void);
  id?: string;
  readOnly?: boolean;
  workDataRaw?: WorkUserBackend | WorkCompanyBackendFromAPI;
  mode?: FormDataModes['value'];
  setFatherLocationSelected?: React.Dispatch<LocationBackend | undefined>;
  buttonLabel?: string;
  workDelete?: (event: React.MouseEvent) => void;
}

export type LocationSelectionOption =
  | null
  | 'My Location'
  | 'My Company Location'
  | 'Another Work Location For Me'
  | 'Another Work Location For My Company'
  | 'New Location';
export type OfferOption = null | 'Como Empresa' | 'Como Trabajador';

const PublishWorkContainer: React.FC<ContainerProps> = ({
  id = 'PublishWork',
  handleSubmitFather = undefined,
  onChange = undefined,
  readOnly = false,
  workDataRaw,
  mode,
  buttonLabel = 'Publicar Trabajo',
  setFatherLocationSelected,
  workDelete,
}) => {
  const { setPublishWorkChatbotStartDate, setPublishWorkChatbotWorker } =
    useContext(SessionContext);
  const localCurrenciesList = constants.currencies_spanish;
  const [locationsDataRaw, setLocationDataRaw] = useState<
    LocationBackend[] | LocationCompanyBackend[] | WorkUserBackend[]
  >([]);
  const [locationBackend, setLocationBackend] = useState<LocationBackend[]>([]);
  const [locationSelected, setLocationSelected] = useState<
    LocationBackend | undefined
  >(undefined);
  const [locationSelectionOption, setLocationSelectionOption] =
    useState<LocationSelectionOption>(null);
  const [loaded, setLoaded] = useState(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<OfferOption | null>(
    null,
  );

  const [formValues, setFormValues] = useState<WorkUserFrontend>({
    summary: '',
    workType: {},
    workBudget: 0,
    // @ts-ignore
    location: {
      country: '',
      state: '',
      city: '',
      neighborhood: '',
      district: '',
      postCode: '',
      street: '',
      building: '',
      outerNumber: '',
      outerLetter: '',
      innerNumber: '',
      innerLetter: '',
      localCurrency: Object.keys(localCurrenciesList).indexOf('MXN'),
      gpsCenterMapsLink: '',
      longitude: 99999,
      latitude: -99999,
    },
    selectedStartDateTime: '',
    selectedEndDateTime: '',
  });

  const openPopup = () => {
    setShowPopup(true);
  };

  const fixDate = (date: string): string => {
    const originalDate: Date = new Date(date);
    const formattedDate: string = originalDate.toISOString();
    return formattedDate;
  };

  const workUpdate = async (event: React.FormEvent) => {
    event.preventDefault();
    const obj_to_send = {
      summary: formValues.summary,
      work_budget: formValues.workBudget,
      start_date_time: fixDate(formValues.selectedStartDateTime),
      end_date_time: fixDate(formValues.selectedEndDateTime),
      location: {
        outer_number: parseInt(formValues.location.outerNumber),
        outer_letter:
          formValues.location.outerLetter !== ''
            ? formValues.location.outerLetter
            : undefined,
        inner_number:
          formValues.location.innerNumber !== ''
            ? parseInt(formValues.location.innerNumber)
            : undefined,
        inner_letter:
          formValues.location.innerLetter !== ''
            ? formValues.location.innerLetter
            : undefined,
        local_currency: formValues.location.localCurrency,
        gps_center_maps_link: formValues.location.gpsCenterMapsLink,
        longitude: formValues.location.longitude,
        latitude: formValues.location.latitude,
        country: {
          country_name: formValues.location.country,
        },
        state: {
          state_name: formValues.location.state,
        },
        city: {
          city_name: formValues.location.city,
        },
        neighborhood: {
          neighborhood_name: formValues.location.neighborhood,
        },
        district:
          formValues.location.district !== ''
            ? {
                district_name: formValues.location.district,
              }
            : { district_name: '' },
        post_code: {
          value: formValues.location.postCode,
        },
        street: {
          street_name: formValues.location.street,
        },
        building:
          formValues.location.building !== ''
            ? {
                building_name: formValues.location.building,
              }
            : { building_name: '' },
      },
      work_type: prepareWorkTypeData(formValues.workType)[0],
    };
    try {
      const path = isWorkUserBackend(workDataRaw)
        ? `works/update_my_work/work_id/${workDataRaw?.id}`
        : '';
      const method = 'PATCH';

      const content_type = 'application/json';
      const response = await makeRequest({
        path: path,
        method: method,
        body: JSON.stringify(obj_to_send),
        contentType: content_type,
      });

      if (!response.ok) {
        const response_json = await response.json();
        throw new Error(JSON.stringify(response_json.detail));
      }

      const output_message = 'Trabajo Actualizado Correctamente';
      alert(output_message);
      window.location.reload();
    } catch (error: unknown) {
      if (error instanceof Error) {
        alert(error.message);
      } else if (
        typeof error === 'object' &&
        error !== null &&
        'message' in error &&
        'componentStack' in error
      ) {
        const reactError = error as React.ErrorInfo;
        alert(reactError);
      } else {
        alert(JSON.stringify(error));
      }
    }
  };

  const selectOption = (opcion: OfferOption) => {
    setSelectedOption(opcion);
    setShowPopup(false);
  };

  const handleButtonCard = (cardData: LocationBackend) => {
    setLocationSelected(cardData);
  };

  const parseISODate = (isoDate: string): string => {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const WorkTypeFrontendFromBackend = (
    work: WorkUserBackend['work_type'],
  ): WorkUserFrontend['workType'] => {
    const result: WorkUserFrontend['workType'] = {};
    const key = CompanyTypeSpanishCat.CompanyTypeSpanishCat[work.service_type];
    const value: string[] = [work.worker_type.type];
    result[key] = value;
    return result;
  };

  useEffect(() => {
    if (workDataRaw != null) {
      if (isWorkUserBackend(workDataRaw)) {
        if (workDataRaw.work_budget != null) {
          setFormValues((prevValues) => ({
            ...prevValues,
            summary: workDataRaw.summary,
            workType: WorkTypeFrontendFromBackend(workDataRaw.work_type),
            selectedStartDateTime: parseISODate(workDataRaw.start_date_time),
            selectedEndDateTime: parseISODate(workDataRaw.end_date_time),
            workBudget: workDataRaw.work_budget,
          }));
        } else {
          setFormValues((prevValues) => ({
            ...prevValues,
            summary: workDataRaw.summary,
            workType: WorkTypeFrontendFromBackend(workDataRaw.work_type),
            selectedStartDateTime: parseISODate(workDataRaw.start_date_time),
            selectedEndDateTime: parseISODate(workDataRaw.end_date_time),
            workBudget: 0,
          }));
        }
      } else if (isWorkCompanyBackendFromAPI(workDataRaw)) {
        if (workDataRaw.work.work_budget != null) {
          setFormValues((prevValues) => ({
            ...prevValues,
            summary: workDataRaw.work.summary,
            workType: WorkTypeFrontendFromBackend(workDataRaw.work.work_type),
            selectedStartDateTime: parseISODate(
              workDataRaw.work.start_date_time,
            ),
            selectedEndDateTime: parseISODate(workDataRaw.work.end_date_time),
            workBudget: workDataRaw.work.work_budget,
          }));
        } else {
          setFormValues((prevValues) => ({
            ...prevValues,
            summary: workDataRaw.work.summary,
            workType: WorkTypeFrontendFromBackend(workDataRaw.work.work_type),
            selectedStartDateTime: parseISODate(
              workDataRaw.work.start_date_time,
            ),
            selectedEndDateTime: parseISODate(workDataRaw.work.end_date_time),
            workBudget: 0,
          }));
        }
      }
    }
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (selectedOption != null || locationSelected != null) {
      const dummyEvent = {
        preventDefault: () => {},
        target: {},
      } as React.FormEvent;
      if (handleSubmitFather != null && setFatherLocationSelected != null) {
        setFatherLocationSelected(locationSelected);
      } else {
        handleSubmit(dummyEvent);
      }
    }
  }, [selectedOption, locationSelected]);

  useEffect(() => {
    async function fetchData() {
      let path: string = '';
      switch (locationSelectionOption) {
        case 'My Location':
          path = `location/get_my_locations`;
          break;
        case 'My Company Location':
          path = `location/get_my_company_locations`;
          break;
        case 'Another Work Location For Me':
          path = `works/get_my_published_works/`;
          break;
        case 'Another Work Location For My Company':
          path = `works/get_my_companies_published_works/`;
          break;
      }
      const method = 'GET';
      try {
        const response = await makeRequest({ path: path, method: method });
        if (!response.ok) {
          const response_json = await response.json();
          throw new Error(JSON.stringify(response_json.detail));
        }
        const data = await response.json();
        if (data.length === 0) {
          alert('No se encontraron ubicaciones registradas');
          setLocationSelectionOption(null);
        } else {
          setLocationDataRaw(data);
        }
      } catch (error: unknown) {
        if (error instanceof Error) {
          alert(error.message);
        } else if (
          typeof error === 'object' &&
          error !== null &&
          'message' in error &&
          'componentStack' in error
        ) {
          const reactError = error as React.ErrorInfo;
          alert(reactError);
        } else {
          alert(JSON.stringify(error));
        }
      }
    }
    if (
      locationSelectionOption != null &&
      locationSelectionOption != 'New Location'
    ) {
      fetchData();
    }
  }, [locationSelectionOption]);

  useEffect(() => {
    locationsDataRaw.forEach((element, index) => {
      if (isLocationBackend(element)) {
        setLocationBackend((prevValues) => [...prevValues, element]);
      } else if (isLocationCompanyBackend(element)) {
        element.locations.forEach((location, index) => {
          setLocationBackend((prevValues) => [...prevValues, location]);
        });
      } else if (isWorkUserBackend(element)) {
        setLocationBackend((prevValues) => [...prevValues, element.location]);
      }
    });
  }, [locationsDataRaw]);

  useEffect(() => {
    if (onChange != null) {
      onChange(formValues);
    }
  }, [formValues]);

  const categoriesData = (() => {
    const result: Category = {};
    for (
      var i = 0;
      i < CompanyTypeSpanishCat.CompanyTypeSpanishCat.length;
      i++
    ) {
      const key = CompanyTypeSpanishCat.CompanyTypeSpanishCat[i];
      const values = CompanyTypeSpanishSubCat.CompanyTypeSpanishSubCat;
      result[key] = [...values];
    }
    return result;
  })();

  const treeHandler = (selection: { [key: string]: string[] }) => {
    const [key] = Object.keys(selection);
    setPublishWorkChatbotWorker(selection[key][0]);
    setFormValues((prevValues) => ({
      ...prevValues,
      workType: selection,
    }));
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    switch (selectedOption) {
      case 'Como Empresa':
        break;
      case 'Como Trabajador':
        break;
      default:
        if (id == undefined || id.includes('PublishWork')) {
          let obj_to_send;
          if (locationSelected != null) {
            obj_to_send = {
              summary: formValues.summary,
              work_budget: formValues.workBudget,
              start_date_time: fixDate(formValues.selectedStartDateTime),
              end_date_time: fixDate(formValues.selectedEndDateTime),
              location: locationSelected,
              work_type: prepareWorkTypeData(formValues.workType)[0],
            };
          } else {
            obj_to_send = {
              summary: formValues.summary,
              work_budget: formValues.workBudget,
              start_date_time: fixDate(formValues.selectedStartDateTime),
              end_date_time: fixDate(formValues.selectedEndDateTime),
              location: {
                outer_number: parseInt(formValues.location.outerNumber),
                outer_letter:
                  formValues.location.outerLetter !== ''
                    ? formValues.location.outerLetter
                    : undefined,
                inner_number:
                  formValues.location.innerNumber !== ''
                    ? parseInt(formValues.location.innerNumber)
                    : undefined,
                inner_letter:
                  formValues.location.innerLetter !== ''
                    ? formValues.location.innerLetter
                    : undefined,
                local_currency: formValues.location.localCurrency,
                gps_center_maps_link: formValues.location.gpsCenterMapsLink,
                longitude: formValues.location.longitude,
                latitude: formValues.location.latitude,
                country: {
                  country_name: formValues.location.country,
                },
                state: {
                  state_name: formValues.location.state,
                },
                city: {
                  city_name: formValues.location.city,
                },
                neighborhood: {
                  neighborhood_name: formValues.location.neighborhood,
                },
                district:
                  formValues.location.district !== ''
                    ? {
                        district_name: formValues.location.district,
                      }
                    : { district_name: '' },
                post_code: {
                  value: formValues.location.postCode,
                },
                street: {
                  street_name: formValues.location.street,
                },
                building:
                  formValues.location.building !== ''
                    ? {
                        building_name: formValues.location.building,
                      }
                    : { building_name: '' },
              },
              work_type: prepareWorkTypeData(formValues.workType)[0],
            };
          }
          try {
            const path = `works/register_my_work`;
            const method = `POST`;

            const content_type = 'application/json';
            const response = await makeRequest({
              path: path,
              method: method,
              body: JSON.stringify(obj_to_send),
              contentType: content_type,
            });

            if (!response.ok) {
              const response_json = await response.json();
              throw new Error(JSON.stringify(response_json.detail));
            }

            const output_message = `Registro exitoso`;
            alert(output_message);
            window.location.reload();
          } catch (error: unknown) {
            if (error instanceof Error) {
              alert(error.message);
            } else if (
              typeof error === 'object' &&
              error !== null &&
              'message' in error &&
              'componentStack' in error
            ) {
              const reactError = error as React.ErrorInfo;
              alert(reactError);
            } else {
              alert(JSON.stringify(error));
            }
          }
        }
    }
  };

  return (
    <div>
      {(() => {
        switch (selectedOption) {
          case 'Como Trabajador':
            return <MakeOfferContainer work={workDataRaw!} id={id} />;
          case 'Como Empresa':
            return <MakeCompanyOfferContainer work={workDataRaw!} id={id} />;
          default:
            return loaded ? (
              <PublishWorkPresentation
                buttonLabel={
                  buttonLabel != null
                    ? buttonLabel
                    : id.includes('ReviewMyPublishedWorks')
                    ? 'Editar Trabajo'
                    : 'Publicar Trabajo'
                }
                workDelete={workDelete}
                locationsDataRaw={locationBackend}
                handleButtonCard={handleButtonCard}
                locationSelectionOption={locationSelectionOption}
                handleLocationSelectionOption={(
                  optionSelected: LocationSelectionOption,
                ) => {
                  setLocationSelectionOption(optionSelected);
                }}
                showPopup={showPopup}
                selectedOption={selectedOption}
                selectOption={selectOption}
                openPopup={openPopup}
                workDataRaw={workDataRaw}
                id={id}
                treeCategories={categoriesData}
                treeHandler={treeHandler}
                setSelectedStartDateTime={(startDate) => {
                  setPublishWorkChatbotStartDate(startDate);
                  setFormValues((prevValues) => ({
                    ...prevValues,
                    selectedStartDateTime: startDate,
                  }));
                }}
                setSelectedEndDateTime={(endDate) => {
                  setFormValues((prevValues) => ({
                    ...prevValues,
                    selectedEndDateTime: endDate,
                  }));
                }}
                innerReadOnly={readOnly}
                formValues={formValues}
                showForm={true}
                handleTextAreaChange={(event) => {
                  const { name, value } = event.target;
                  setFormValues((prevValues) => ({
                    ...prevValues,
                    [name]: value,
                  }));
                }}
                handleChangeLocation={(locationData: LocationFrontend) => {
                  setFormValues((prevValues) => ({
                    ...prevValues,
                    location: locationData,
                  }));
                }}
                handleCurrencyChange={(
                  event: React.ChangeEvent<HTMLInputElement>,
                ) => {
                  setFormValues((prevValues) => ({
                    ...prevValues,
                    workBudget: parseFloat(event.target.value),
                  }));
                }}
                handleSubmit={
                  handleSubmitFather != null
                    ? handleSubmitFather
                    : id.includes('ReviewMyPublishedWorks')
                    ? workUpdate
                    : handleSubmit
                }
                mode={mode!}
              />
            ) : (
              <p>Cargando...</p>
            );
        }
      })()}
    </div>
  );
};

export default PublishWorkContainer;
