import { SupportFrontend } from '../services/BackendFrontendInterfaces';

interface SupportPresentationProps {
  id?: string;
  handleSubmit: (event: React.FormEvent) => void;
  formValues: Omit<SupportFrontend, 'work'>;
  handleTextAreaChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}
function SupportPresentation({
  id = 'Support',
  handleSubmit,
  formValues,
  handleTextAreaChange,
}: SupportPresentationProps) {
  return (
    <form onSubmit={handleSubmit} id={`Form${id}`}>
      <h1>Solicitar Soporte A La Plataforma</h1>
      <h2>Por Favor Describa Su Solicitud</h2>
      <label htmlFor={`input${id}`} id={`label${id}`}>
        Trabajo
      </label>
      <textarea
        id={`input${id}`}
        name={`input${id}`}
        disabled={false}
        required
        value={formValues.request}
        onChange={handleTextAreaChange}
        rows={8}
        style={{ width: '60%' }}
      />
      <br />
      <button id={`button${id}SubmitSupport`} type="submit">
        {'Enviar Solicitud'}
      </button>
    </form>
  );
}

export default SupportPresentation;
