import GridCard from '../components/GridCard';
import { CardsGridCaller } from '../components/GridCard';
import React, { useState, useEffect, useContext } from 'react';
import { makeRequest } from '../services/requests';
import {
  WorkUserBackend,
  WorkOfferBackend,
  WorkOfferFrontend,
} from '../services/BackendFrontendInterfaces';
import { SessionContext } from '../services/SessionContextProvider';
import MakeOfferContainer from '../containers/MakeOfferContainer';
import ArbitrationWorkContainer from './ArbitrationContainer';
import { isWorkOfferBackend } from '../services/InterfaceTypeValidator';

interface ReviewMyOffersProps {
  id?: CardsGridCaller;
  buttonLabelCardOne?: string;
  buttonLabelCardTwo?: string;
  onChange?:
    | null
    | ((offerData: Omit<WorkOfferFrontend, 'user' | 'company'>) => void);
}

function ReviewMyOffersContainer({
  id = 'ReviewMyOffers',
  buttonLabelCardOne = 'Editar',
  buttonLabelCardTwo = 'Borrar',
  onChange = undefined,
}: ReviewMyOffersProps) {
  const [backendOffersFromMeToEdit, setBackendOffersFromMeToEdit] =
    useState<WorkOfferBackend | null>(null);
  const { myOffersBackendCache, setMyOffersBackendCache } =
    useContext(SessionContext);
  const [offerToArbitrate, setOfferToArbitrate] =
    useState<WorkOfferBackend | null>(null);
  const [formValues, setFormValues] = useState<
    Omit<WorkOfferFrontend, 'user' | 'company' | 'work'>
  >({
    laborBudget: '0',
    startDateTime: '',
    endDateTime: '',
    externalWorks: [],
    tools: [],
    materials: [],
    id: 0,
  });

  const handleSubmit = async (event: React.FormEvent) => {
    const toolsFrontendToBackend = (tools: WorkOfferFrontend['tools']) => {
      if (tools != null) {
        const toReturn: WorkOfferBackend['tools'] = [];

        tools.forEach((tool) => {
          const tl = {
            tool: { tool: tool.tool },
            tool_budget: parseFloat(tool.toolBudget),
          };

          toReturn.push(tl);
        });
        return toReturn;
      }
    };

    const externalWorksFrontendToBackend = (
      externalWorks: WorkOfferFrontend['externalWorks'],
    ) => {
      if (externalWorks != null) {
        const toReturn: WorkOfferBackend['external_works'] = [];

        externalWorks.forEach((work) => {
          const ew = {
            external_work: { external_work: work.externalWork },
            external_work_budget: parseFloat(work.externalWorkBudget),
          };

          toReturn.push(ew);
        });
        return toReturn;
      }
    };

    const materialsFrontendToBackend = (
      materials: WorkOfferFrontend['materials'],
    ) => {
      if (materials != null) {
        const toReturn: WorkOfferBackend['materials'] = [];

        materials.forEach((material) => {
          const mr = {
            material: { material: material.material },
            material_budget: parseFloat(material.materialBudget),
          };

          toReturn.push(mr);
        });
        return toReturn;
      }
    };

    const fixDate = (date: string): string => {
      const originalDate: Date = new Date(date);
      const formattedDate: string = originalDate.toISOString().slice(0, -1);
      return formattedDate;
    };

    event.preventDefault();
    const obj_to_send: WorkOfferBackend = {
      work: backendOffersFromMeToEdit!.work,
      labor_budget: parseInt(formValues.laborBudget),
      start_date_time: fixDate(formValues.startDateTime),
      end_date_time: fixDate(formValues.endDateTime),
      user: undefined,
      company: undefined,
      tools: toolsFrontendToBackend(formValues.tools),
      materials: materialsFrontendToBackend(formValues.materials),
      external_works: externalWorksFrontendToBackend(formValues.externalWorks),
    };
    try {
      const path = `offers/update_my_offer/offer_id/${
        backendOffersFromMeToEdit!.id
      }`;
      const method = `PATCH`;

      const content_type = 'application/json';
      const response = await makeRequest({
        path: path,
        method: method,
        body: JSON.stringify(obj_to_send),
        contentType: content_type,
      });

      if (!response.ok) {
        const response_json = await response.json();
        throw new Error(JSON.stringify(response_json.detail));
      }

      const output_message = `Oferta Actualizada`;
      alert(output_message);
      window.location.reload();
    } catch (error: unknown) {
      if (error instanceof Error) {
        alert(error.message);
      } else if (
        typeof error === 'object' &&
        error !== null &&
        'message' in error &&
        'componentStack' in error
      ) {
        const reactError = error as React.ErrorInfo;
        alert(reactError);
      } else {
        alert(JSON.stringify(error));
      }
    }
  };

  const getOffers = async () => {
    if (myOffersBackendCache == null) {
      const path = `offers/get_my_offers/`;
      const method = 'GET';
      try {
        const response = await makeRequest({ path: path, method: method });
        const data: WorkOfferBackend[] = await response.json();
        setMyOffersBackendCache(data);
      } catch (error: unknown) {
        if (error instanceof Error) {
          alert(error.message);
        } else if (
          typeof error === 'object' &&
          error !== null &&
          'message' in error &&
          'componentStack' in error
        ) {
          const reactError = error as React.ErrorInfo;
          alert(reactError);
        } else {
          alert(JSON.stringify(error));
        }
      }
    }
  };

  const offerDeleteOrArbitrate = async (offer: WorkOfferBackend) => {
    if (offer.work.taken_by != null) {
      setOfferToArbitrate(offer);
    } else {
      const path = `offers/delete_my_offer/offer_id/${offer.id}`;
      const method = 'DELETE';
      try {
        const response = await makeRequest({ path: path, method: method });
        const data: WorkUserBackend[] = await response.json();
        // Mostrar una notificación de éxito
        alert('Oferta Borrada Correctamente');
        // Recargar el componente
        window.location.reload();
      } catch (error: unknown) {
        if (error instanceof Error) {
          alert(error.message);
        } else if (
          typeof error === 'object' &&
          error !== null &&
          'message' in error &&
          'componentStack' in error
        ) {
          const reactError = error as React.ErrorInfo;
          alert(reactError);
        } else {
          alert(JSON.stringify(error));
        }
      }
    }
  };

  const innerOnChange = (
    offer: Omit<WorkOfferFrontend, 'user' | 'company'>,
  ) => {
    setFormValues(offer);
  };

  useEffect(() => {
    async function fetchData() {
      await getOffers();
    }
    fetchData();
  }, []);
  if (offerToArbitrate != null) {
    return <ArbitrationWorkContainer workOrOfferDataRaw={offerToArbitrate} />;
  } else if (backendOffersFromMeToEdit != null) {
    return (
      <MakeOfferContainer
        offerDataRaw={backendOffersFromMeToEdit}
        work={backendOffersFromMeToEdit.work}
        handleSubmitFather={handleSubmit}
        onChange={onChange != null ? onChange : innerOnChange}
        id={id}
      />
    );
  } else if (myOffersBackendCache != null) {
    return (
      <GridCard
        cardsData={myOffersBackendCache}
        handleButtonCardOne={(cardData: WorkOfferBackend) => {
          setBackendOffersFromMeToEdit(cardData);
        }}
        buttonLabelCardOne={(cardData: WorkOfferBackend) => {
          if (cardData.work.taken_by == null) {
            return 'Editar';
          } else if (cardData.work.taken_by != null) {
            return '';
          } else {
            return `${buttonLabelCardOne}`;
          }
        }}
        handleButtonCardTwo={offerDeleteOrArbitrate}
        buttonLabelCardTwo={(cardData: WorkOfferBackend) => {
          if (cardData.work.taken_by == null) {
            return 'Borrar';
          } else if (cardData.work.taken_by != null) {
            return 'Solicitar Arbitraje';
          } else {
            return `${buttonLabelCardTwo}`;
          }
        }}
        id={id}
      />
    );
  } else {
    return <h1>Cargando...</h1>;
  }
}

export default ReviewMyOffersContainer;
