import React from 'react';
import '../styles/FromLogo.css';
import RegisteredUsers from './RegisteredUsers';

// TODO Definir una interfaz para las props
interface CoverProps {
  // TODO Si tienes props, defínelas aquí
}

function Cover(props: CoverProps) {
  return (
    <div className="cover">
      <h1 className="cover-title">7pro</h1>
      <p className="cover-subtitle">
        Mercado/Club de servicios profesionales para la construcción
      </p>
      <div className="cover-video">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/3ZyCcgDXd4Q"
          title="Video embebido"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
      <RegisteredUsers />
    </div>
  );
}

export default Cover;
