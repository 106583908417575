import React, { useState, useEffect } from 'react';
import { makeRequest } from '../services/requests';
import NotTakenWorksPresentation from '../components/NotTakenWorksPresentation';
import {
  WorkFilterFrontend,
  WorkUserBackend,
} from '../services/BackendFrontendInterfaces';

interface NotTakenWorksProps {
  id?: string;
}

function NotTakenWorksContainer({ id = 'notTakenWorks' }: NotTakenWorksProps) {
  const [worksDataRaw, setWorksDataRaw] = useState<WorkUserBackend[] | null>(
    null,
  );
  const [selectedWorkFromMap, setSelectedWorkFromMap] =
    useState<WorkUserBackend | null>(null);
  const [worksDataRawFiltered, setWorksDataRawFiltered] = useState<number[]>(
    [],
  );
  const [formValues, setFormValues] = useState<WorkFilterFrontend>({
    workerType: [],
    selectedStartDateTime: '',
    selectedEndDateTime: '',
    minimumWorkBudget: '',
    maximumWorkBudget: '',
  });

  useEffect(() => {
    async function fetchData() {
      const path = 'works/get_all_not_taken_works';
      const method = 'GET';
      try {
        const response = await makeRequest({ path: path, method: method });
        const data: WorkUserBackend[] = await response.json();
        setWorksDataRaw(data);
      } catch (error: unknown) {
        if (error instanceof Error) {
          alert(error.message);
        } else if (
          typeof error === 'object' &&
          error !== null &&
          'message' in error &&
          'componentStack' in error
        ) {
          const reactError = error as React.ErrorInfo;
          alert(reactError);
        } else {
          alert(JSON.stringify(error));
        }
      }
    }
    fetchData();
  }, []);

  const filterWorksData = () => {
    let filteredData = worksDataRaw;
    if (filteredData != null) {
      if (formValues.workerType.length > 0) {
        filteredData = filteredData.filter((work) =>
          formValues.workerType.includes(work.work_type.worker_type.type),
        );
      }

      if (
        formValues.selectedStartDateTime !== '' &&
        formValues.selectedEndDateTime !== ''
      ) {
        filteredData = filteredData.filter(
          (work) =>
            work.start_date_time >= formValues.selectedStartDateTime &&
            work.end_date_time <= formValues.selectedEndDateTime,
        );
      }

      if (formValues.minimumWorkBudget !== '') {
        filteredData = filteredData.filter(
          (work) => work.work_budget >= parseInt(formValues.minimumWorkBudget),
        );
      }

      if (formValues.maximumWorkBudget !== '') {
        filteredData = filteredData.filter(
          (work) => work.work_budget <= parseInt(formValues.maximumWorkBudget),
        );
      }
    }
    if (filteredData != null) {
      return filteredData.map((work) => work.id!);
    } else {
      return [];
    }
  };

  if (worksDataRaw === null) {
    return <h1>Cargando...</h1>;
  } else {
    return (
      <NotTakenWorksPresentation
        id={id}
        worksDataRaw={worksDataRaw}
        worksDataRawFiltered={worksDataRawFiltered}
        formValues={formValues}
        handleInputChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const { name, value } = event.target;
          setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
        }}
        setSelectedStartDateTime={(startDate) => {
          setFormValues((prevValues) => ({
            ...prevValues,
            selectedStartDateTime: startDate,
          }));
        }}
        setSelectedEndDateTime={(endDate) => {
          setFormValues((prevValues) => ({
            ...prevValues,
            selectedEndDateTime: endDate,
          }));
        }}
        handleCheckboxSelectedChange={(selectedValues) => {
          setFormValues((prevValues) => ({
            ...prevValues,
            workerType: selectedValues,
          }));
        }}
        checkBoxOptions={
          worksDataRaw !== null
            ? (() => {
                const tiposDeTrabajadorUnicosSet: Set<string> = new Set();

                worksDataRaw.forEach((work) => {
                  const workerType = work.work_type.worker_type.type;
                  if (!tiposDeTrabajadorUnicosSet.has(workerType)) {
                    tiposDeTrabajadorUnicosSet.add(workerType);
                  }
                });

                return Array.from(tiposDeTrabajadorUnicosSet);
              })()
            : []
        }
        handleSubmit={(event) => {
          event.preventDefault();
          if (worksDataRaw != null) {
            const filteredWorks: number[] = filterWorksData();
            setWorksDataRawFiltered(filteredWorks);
          }
        }}
        setSelectedWorkFromMap={setSelectedWorkFromMap}
        selectedWorkFromMap={selectedWorkFromMap}
      />
    );
  }
}

export default NotTakenWorksContainer;
