import React from 'react';

interface CustomizedMenusProps {
  id: string;
  keyOrValue: boolean;
  options: { [key: string]: string | boolean };
  selectedValue: string;
  onSelectChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  readOnly?: boolean;
}

function CustomizedMenusPresentation({
  id = 'CustomizedMenus',
  keyOrValue,
  options,
  selectedValue,
  onSelectChange,
  readOnly = false,
}: CustomizedMenusProps) {
  /**
   * This function add all the options to the menu
   */
  const optionsList = Object.entries(options).map(([key, value]) => (
    <option
      key={key}
      value={key}
      disabled={readOnly}
      id={`${id}${keyOrValue ? key.toString() : value.toString()}`}
    >
      {keyOrValue ? key.toString() : value.toString()}
    </option>
  ));

  return (
    <select value={selectedValue} onChange={onSelectChange} id={id}>
      {optionsList}
    </select>
  );
}

export default CustomizedMenusPresentation;
