import React from 'react';
import CustomizedMenusContainer from '../containers/CustomizedMenusContainer';
import constants from '../data/constants.json';
import WorkerTypeSpanish from '../data/worker_type_spanish.json';
import CellPhonesRegisterContainer from '../containers/CellPhonesRegisterContainer';
import AutocompleteContainer from '../containers/AutocompleteContainer';
import {
  UserBackend,
  UserFrontend,
} from '../services/BackendFrontendInterfaces';

const SexType = constants.SexSpanishEnum;
const selectormenu = constants.keyOrValue;

interface EditUserFormProps {
  id?: string;
  userData: UserBackend | null;
  handleSubmit: (event: React.FormEvent) => void;
  readOnly: boolean;
  formValues: UserFrontend;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  showPassword: boolean;
  handleMouseDownPassword: (event: React.MouseEvent) => void;
  handleClickShowPassword: () => void;
  handleSelectChangeSexType: (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => void;
  handleCheckboxWorkerTypeChange: (value: {
    name: string | null;
    value: string[];
  }) => void;
  handleCellPhonesRegistrados: (cellPhones: number[]) => void;
  cellPhones: number[];
}

const EditUserFormPresentation: React.FC<EditUserFormProps> = ({
  id = 'EditUser',
  userData,
  handleSubmit,
  handleInputChange,
  handleMouseDownPassword,
  handleClickShowPassword,
  handleSelectChangeSexType,
  handleCheckboxWorkerTypeChange,
  handleCellPhonesRegistrados,
  readOnly = true,
  formValues,
  showPassword,
  cellPhones,
}) => {
  return (
    <div>
      {userData !== null ? (
        <div>
          <form className="App" onSubmit={handleSubmit} id={`Form${id}`}>
            <div>
              <label htmlFor={`input${id}id`} id={`label${id}id`}>
                INE:
              </label>
              <input
                id={`input${id}id`}
                name="id_card"
                type="file"
                onChange={handleInputChange}
              />
              <label htmlFor={`input${id}id`} id={`label${id}id`}>
                SELFIE:
              </label>
              <input
                id={`input${id}selfie`}
                name="selfie"
                type="file"
                accept=".jpg"
                onChange={handleInputChange}
              />
              <br />
              <label htmlFor={`input${id}CURP`} id={`label${id}CURP`}>
                CURP:
              </label>
              <input
                id={`input${id}CURP`}
                name="curp"
                placeholder="CURP"
                disabled={true}
                required
                value={formValues.curp}
                onChange={handleInputChange}
              />
              <label htmlFor={`input${id}Email`} id={`label${id}Email`}>
                Email:
              </label>
              <input
                id={`input${id}Email`}
                name="email"
                placeholder="Email"
                type="email"
                disabled={readOnly}
                required
                value={formValues.email}
                onChange={handleInputChange}
              />
            </div>
            <br />
            <div>
              <label htmlFor={`input${id}Password`} id={`label${id}Password`}>
                Contraseña:
              </label>
              <input
                id={`input${id}Password`}
                name="password"
                placeholder="Contraseña"
                required
                disabled={readOnly}
                value={formValues.password}
                onChange={handleInputChange}
                type={showPassword ? 'text' : 'password'}
              />
              <label
                htmlFor={`input${id}ConfirmPassword`}
                id={`label${id}ConfirmPassword`}
              >
                Confirmar Contraseña:
              </label>
              <input
                id={`input${id}ConfirmPassword`}
                name="confirm_password"
                placeholder="Confirmar Contraseña"
                required
                disabled={readOnly}
                value={formValues.confirm_password}
                onChange={handleInputChange}
                type={showPassword ? 'text' : 'password'}
              />

              <button
                type="button"
                id={`button${id}ShowPassword`}
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? 'Ocultar Contraseña' : 'Mostrar Contraseña'}
              </button>
            </div>
            <br />
            <div>
              <label htmlFor={`input${id}FirstName`} id={`label${id}FirstName`}>
                Primer Nombre:
              </label>
              <input
                id={`input${id}FirstName`}
                name="firstName"
                placeholder="Primer Nombre"
                required
                disabled={readOnly}
                value={formValues.firstName}
                onChange={handleInputChange}
              />
              <label
                htmlFor={`input${id}SecondName`}
                id={`label${id}SecondName`}
              >
                Segundo Nombre:
              </label>
              <input
                id={`input${id}SecondName`}
                name="secondName"
                placeholder="Segundo Nombre"
                disabled={readOnly}
                value={formValues.secondName}
                onChange={handleInputChange}
              />
            </div>
            <br />
            <div>
              <label
                htmlFor={`input${id}FirstLastname`}
                id={`label${id}FirstLastname`}
              >
                Primer Apellido:
              </label>
              <input
                name="firstLastName"
                id={`input${id}FirstLastname`}
                placeholder="Primer Apellido"
                required
                disabled={readOnly}
                value={formValues.firstLastName}
                onChange={handleInputChange}
              />
              <label
                htmlFor={`input${id}SecondLastname`}
                id={`label${id}SecondLastname`}
              >
                Segundo Apellido:
              </label>
              <input
                id={`input${id}SecondLastname`}
                name="secondLastName"
                placeholder="Segundo Apellido"
                required
                disabled={readOnly}
                value={formValues.secondLastName}
                onChange={handleInputChange}
              />
            </div>
            <br />
            <div>
              <label
                htmlFor={`CustomizedMenus${id}`}
                id={`labelCustomizedMenus${id}`}
              >
                Sexo
              </label>
              <CustomizedMenusContainer
                id={`CustomizedMenus${id}`}
                keyOrValue={selectormenu.Key}
                options={SexType}
                readOnly={readOnly}
                initialize={[Object.values(SexType).indexOf(formValues.sex)][0]}
                onItemClick={handleSelectChangeSexType}
              />
              <AutocompleteContainer
                autocompleteTitle={'Tipo De Usuario'}
                id={`Autocomplete${id}`}
                options={WorkerTypeSpanish.WorkerTypeSpanish}
                onChange={handleCheckboxWorkerTypeChange}
                initialValues={formValues.workerType}
                readOnly={readOnly}
              />
            </div>
            <br />
            <div>
              <label htmlFor={`input${id}RFC`} id={`label${id}RFC`}>
                RFC:
              </label>
              <input
                id={`input${id}RFC`}
                name="rfc"
                placeholder="RFC"
                required
                disabled={true}
                value={formValues.rfc}
                onChange={handleInputChange}
              />
              <label
                htmlFor={`input${id}LinkCedule`}
                id={`label${id}LinkCedule`}
              >
                Número De Cédula Profesional:
              </label>
              <input
                id={`input${id}LinkCedule`}
                name="linkCedule"
                placeholder="Numero De Cédula Profesional"
                disabled={readOnly}
                value={formValues.linkCedule}
                onChange={handleInputChange}
              />
            </div>
            <br />
            <div>
              <h2 id={`h2${id}ApprovedBy`} style={{ color: '#004d26' }}>
                Número De Evaluaciones Positivas: {userData.approved_by}
              </h2>
              <h2 id={`h2${id}RateApproved`} style={{ color: '#004d26' }}>
                Promedio Evaluaciones Positivas: {userData.rate_approved}
              </h2>
            </div>
            <br />
            <div>
              <h2 id={`h2${id}DisapprovedBy`} style={{ color: '#5c1a0f' }}>
                Número De Evaluaciones Negativas: {userData.disapproved_by}
              </h2>
              <h2 id={`h2${id}RateDisapproved`} style={{ color: '#5c1a0f' }}>
                Promedio Evaluaciones Negativas: -{userData.rate_disapproved}
              </h2>
            </div>
            <br />
            <div>
              {userData.works_evaluated != null ? (
                <div>
                  <h2 id={`h2${id}WorksEvaluated`} style={{ color: '#330033' }}>
                    Número De Trabajos Evaluados: {userData.works_evaluated}
                  </h2>
                  <h2
                    id={`h2${id}RateWorksEvaluated`}
                    style={{ color: '#330033' }}
                  >
                    Promedio Evaluación De Trabajos Evaluados:
                    {userData.rate_works_evaluated}
                  </h2>
                </div>
              ) : (
                <h2></h2>
              )}
            </div>
            <div>
              <button id={`button${id}Send`} type="submit">
                {readOnly ? 'EDITAR' : 'Enviar Formulario'}
              </button>
            </div>
            <br />
            <div>
              {cellPhones.length > 0 ? (
                <CellPhonesRegisterContainer
                  id={`cellPhonesRegister${id}`}
                  onRegisteredCellPhones={handleCellPhonesRegistrados}
                  initialCellNumbers={cellPhones}
                  readOnly={readOnly}
                />
              ) : (
                <p>Cargando...</p>
              )}
            </div>
          </form>
        </div>
      ) : (
        <div>Cargando...</div>
      )}
    </div>
  );
};

export default EditUserFormPresentation;
