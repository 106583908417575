import GridCard from './GridCard';
import { DeadWorkOfferBackend } from '../services/BackendFrontendInterfaces';
import { CardsGridCaller } from '../components/GridCard';
import CustomizedMenuListContainer from '../containers/CustomizedMenuListContainer';

interface MyCompaniesCompletedWorksPresentationProps {
  id: CardsGridCaller;
  worksDataRaw: DeadWorkOfferBackend[];
  companiesList: string[];
  handleSelectChangeCompany: (
    selectedOption: React.ChangeEvent<HTMLSelectElement>,
  ) => void;
}

function MyCompaniesCompletedWorksPresentation({
  id = 'myCompaniesDeadWorks',
  worksDataRaw = [],
  companiesList,
  handleSelectChangeCompany,
}: MyCompaniesCompletedWorksPresentationProps) {
  return companiesList?.length > 0 ? (
    <div>
      <div>
        <p>Compañía:</p>
        <div>
          <CustomizedMenuListContainer
            id={`CustomizedMenuList${id}`}
            options={companiesList}
            onItemClick={handleSelectChangeCompany}
          />
        </div>
      </div>
      {worksDataRaw.length > 0 ? (
        <GridCard cardsData={worksDataRaw} id={`Grid${id}`} />
      ) : (
        <div>No se encontraron Trabajos Completados</div>
      )}
    </div>
  ) : (
    <h2>Cargando...</h2>
  );
}

export default MyCompaniesCompletedWorksPresentation;
