import React, { SetStateAction, useEffect, Dispatch, useState } from 'react';
import WrapperForMap from '../components/WrapperForMap';
import LocationCompanyFormContainer from '../containers/LocationCompanyFormContainer';
import { LocationCompanyBackend } from '../services/BackendFrontendInterfaces';

interface MyCompaniesLocationsPresentationProps {
  id?: string;
  buttonLabel?: string;
  locationsDataRaw: LocationCompanyBackend[] | null;
  setSelectedLocationFromMap: Dispatch<
    SetStateAction<LocationCompanyBackend[] | null>
  >;
  selectedLocationFromMap: LocationCompanyBackend[] | null;
}

const MyCompaniesLocationsShowPresentation: React.FC<
  MyCompaniesLocationsPresentationProps
> = ({
  id = 'MyCompaniesLocationsShow',
  locationsDataRaw = null,
  buttonLabel,
  setSelectedLocationFromMap,
  selectedLocationFromMap,
}) => {
  if (selectedLocationFromMap != null) {
    return (
      <LocationCompanyFormContainer
        readOnly={true}
        locationDataRaw={selectedLocationFromMap}
      />
    );
  } else if (locationsDataRaw === null) {
    return <h1>Cargando...</h1>;
  } else {
    const hasLocations = locationsDataRaw.some(
      (obj) => obj.locations && obj.locations.length > 0,
    );

    if (hasLocations) {
      return (
        <WrapperForMap
          id={`wrapperForMap${id}`}
          locationsDataRawList={locationsDataRaw}
          setReturnedData={setSelectedLocationFromMap}
        />
      );
    } else {
      return (
        <LocationCompanyFormContainer
          id={`locationCompanyForm${id}`}
          readOnly={false}
          buttonLabel={buttonLabel}
        />
      );
    }
  }
};

export default MyCompaniesLocationsShowPresentation;
