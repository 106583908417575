import React, { FC, useState, useEffect } from 'react';
import TreeViewPresentation from '../components/TreeViewPresentation';

interface Category {
  [key: string]: string[];
}

interface TreeContainerProps<T> {
  readOnly: boolean;
  id: string;
  categories: Category;
  treeHandler: (selection: T) => void;
}

const TreeViewContainer: FC<
  TreeContainerProps<{ [key: string]: string[] }>
> = ({ readOnly = false, id = 'TreeView', categories, treeHandler }) => {
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState<string | null>(
    null,
  );

  const handleCategoryClick = (category: string) => {
    if (!readOnly) {
      setSelectedCategory(category);
      setSelectedSubcategory(null);
    }
  };

  const handleSubcategoryClick = (
    event: React.MouseEvent,
    subcategory: string,
  ) => {
    setSelectedSubcategory(subcategory);
    event.stopPropagation();
  };

  useEffect(() => {
    if (selectedCategory !== null && selectedSubcategory !== null) {
      const optionSelected: { [key: string]: string[] } = {
        [selectedCategory]: [selectedSubcategory],
      };
      setSelectedCategory(null);
      setSelectedSubcategory(null);
      treeHandler(optionSelected);
    }
  }, [selectedSubcategory]);

  return (
    <TreeViewPresentation
      id={id}
      categories={categories}
      selectedCategory={selectedCategory}
      onCategoryClick={handleCategoryClick}
      onSubcategoryClick={handleSubcategoryClick}
    />
  );
};

export default TreeViewContainer;
