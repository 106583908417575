import { Status } from '@googlemaps/react-wrapper';
import { createCustomEqual } from 'fast-equals';
import { isLatLngLiteral } from '@googlemaps/typescript-guards';
import MapWWrapperPresentation from '../components/MapWWrapperPresentation';
import React, { SetStateAction, useEffect, Dispatch, useState } from 'react';
import {
  isLocationCompanyBackendList,
  isLocationCompanyBackend,
  isWorkUserBackend,
  isLocationBackend,
  isWorkUserBackendList,
  isLocationBackendList,
} from '../services/InterfaceTypeValidator';
import {
  LocationBackend,
  LocationCompanyBackend,
  WorkUserBackend,
} from '../services/BackendFrontendInterfaces';
import { LatLngCustomized } from '../components/WrapperForMap';

interface MapProps extends google.maps.MapOptions {
  style: { [key: string]: string };
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;
  children?: React.ReactNode;
  locationsDataRawList:
    | LocationBackend[]
    | LocationCompanyBackend[]
    | WorkUserBackend[];
  id: string;
  setMarkers: Dispatch<SetStateAction<LatLngCustomized[]>>;
  setLoaded: Dispatch<SetStateAction<boolean>>;
  setMap?: Dispatch<SetStateAction<google.maps.Map | null | undefined>>;
  map?: google.maps.Map;
  setReturnedData?:
    | Dispatch<SetStateAction<LocationBackend | null>>
    | Dispatch<SetStateAction<LocationCompanyBackend[] | null>>
    | Dispatch<SetStateAction<WorkUserBackend | null>>;
}

const MapWWrapperContainer: React.FC<MapProps> = ({
  onClick,
  onIdle,
  children,
  style,
  locationsDataRawList,
  id = 'MapWWrapper',
  setMarkers,
  setLoaded,
  setMap,
  map,
  setReturnedData,
  ...options
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [dataRaw, setDataRaw] = useState<
    LocationBackend | LocationCompanyBackend[] | WorkUserBackend | null
  >(null);

  const getUniqueLocations = (
    data: LocationBackend[] | LocationCompanyBackend[] | WorkUserBackend[],
  ) => {
    let uniqueLocations: google.maps.LatLng[] = [];
    const seenLocations = new Set<string>();
    let key: string;
    data.forEach((element, index) => {
      if (isLocationBackend(element)) {
        key = `${element.latitude},${element.longitude}`;
        if (!seenLocations.has(key)) {
          seenLocations.add(key);
          uniqueLocations.push(
            new google.maps.LatLng({
              lat: element.latitude,
              lng: element.longitude,
            }),
          );
        }
      } else if (isLocationCompanyBackend(element)) {
        element.locations.forEach((location, index) => {
          key = `${location.latitude},${location.longitude}`;
          if (!seenLocations.has(key)) {
            seenLocations.add(key);
            uniqueLocations.push(
              new google.maps.LatLng({
                lat: location.latitude,
                lng: location.longitude,
              }),
            );
          }
        });
      } else if (isWorkUserBackend(element)) {
        key = `${element.location.latitude},${element.location.longitude}`;
        if (!seenLocations.has(key)) {
          seenLocations.add(key);
          uniqueLocations.push(
            new google.maps.LatLng({
              lat: element.location.latitude,
              lng: element.location.longitude,
            }),
          );
        }
      }
    });
    return uniqueLocations;
  };

  useEffect(() => {
    const uniqueLocations = getUniqueLocations(locationsDataRawList);
    const markersData = convertData(locationsDataRawList, id, uniqueLocations);
    if (markersData != null) {
      setMarkers(markersData);
      setLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (dataRaw != null) {
      // @ts-ignore
      setReturnedData(dataRaw);
    }
  }, [dataRaw]);

  function convertData(
    data: LocationBackend[] | WorkUserBackend[] | LocationCompanyBackend[],
    id: string,
    uniqueLocations: google.maps.LatLng[],
  ) {
    let locationsToReturn: LatLngCustomized[] = [];
    if (id.includes('MyCompaniesLocationsShow')) {
      if (isLocationCompanyBackendList(data)) {
        data.forEach(function (company, i) {
          let counter: number = 0;
          company.locations.forEach(function (location) {
            let title: string = `${company.company.company_name}\n${
              location.street.street_name
            } ${location.outer_number}${
              location.outer_letter != null ? location.outer_letter : ''
            }-${location.inner_number != null ? location.inner_number : ''}${
              location.inner_letter != null ? location.inner_letter : ''
            }`;
            let locationToReturn: LatLngCustomized = new google.maps.LatLng(
              location.latitude,
              location.longitude,
            );
            const contentString =
              '<div id="content">' +
              `<h1>${company.company.company_name}</h1>` +
              `<h2>${company.locations[i].street.street_name} ${company.locations[i].outer_number}</h2>` +
              `<button id="buttonMarkerID${counter}" type={'button'}>Revisar Ubicación</button>` +
              '</div>';
            // @ts-ignore
            locationToReturn.id = counter;
            if (isLocationCompanyBackendList(data)) {
              locationToReturn.dataRaw = data;
            }
            locationToReturn.infoWindow = new google.maps.InfoWindow({
              content: contentString,
            });
            google.maps.event.addListener(
              locationToReturn.infoWindow,
              'domready',
              () => {
                const button = document.getElementById(
                  `buttonMarkerID${locationToReturn.id}`,
                );
                if (button) {
                  button.addEventListener('click', () => {
                    setDataRaw(locationToReturn.dataRaw!);
                  });
                } else {
                  console.error(
                    `El botón buttonMarkerID${locationToReturn.id} no fue encontrado en el DOM`,
                  );
                }
              },
            );
            locationsToReturn.push(locationToReturn);
            counter++;
          });
        });
        return locationsToReturn;
      }
    } else if (id.includes('notTakenWorks')) {
      if (isWorkUserBackendList(data)) {
        const findWorkID = (workID: number) => {
          return data.find((work) => work.id == workID);
        };
        uniqueLocations.forEach((location, locationIndex) => {
          let contentString: string = '';
          const worksInLocationList = data.filter(
            (work) =>
              location.lat() == work.location.latitude &&
              location.lng() == work.location.longitude,
          );
          worksInLocationList.forEach(function (work, workIndex) {
            let title: string = `${work.summary}\n$${work.work_budget}\n${
              work.start_date_time
            }\n${work.end_date_time}\n${work.location.street.street_name} ${
              work.location.outer_number
            }${
              work.location.outer_letter != null
                ? work.location.outer_letter
                : ''
            }-${
              work.location.inner_number != null
                ? work.location.inner_number
                : ''
            }${
              work.location.inner_letter != null
                ? work.location.inner_letter
                : ''
            }`;
            let locationToReturn: LatLngCustomized = new google.maps.LatLng(
              work.location.latitude,
              work.location.longitude,
            );
            contentString =
              contentString +
              '<div id="content">' +
              `<h1>${work.summary}</h1>` +
              `<h2>$${work.work_budget}</h2>` +
              `<h2>${work.start_date_time}</h2>` +
              `<h2>${work.end_date_time}</h2>` +
              `<button id="buttonMarkerID${work.id}" type={'button'}>Revisar Trabajo!</button>` +
              '</div>';
            // @ts-ignore
            locationToReturn.id = work.id;
            locationToReturn.dataRaw = work;
            locationToReturn.infoWindow = new google.maps.InfoWindow({
              content: contentString,
            });
            if (workIndex == worksInLocationList.length - 1) {
              google.maps.event.addListener(
                locationToReturn.infoWindow,
                'domready',
                () => {
                  const buttons = document.querySelectorAll('button');
                  buttons.forEach((button) => {
                    button.addEventListener('click', () => {
                      const workToReturn = findWorkID(
                        parseInt(button.id.replace('buttonMarkerID', '')),
                      );
                      if (isWorkUserBackend(workToReturn)) {
                        setDataRaw(workToReturn);
                      }
                    });
                  });
                },
              );
            }
            locationsToReturn.push(locationToReturn!);
          });
        });
        return locationsToReturn;
      }
    } else {
      if (isLocationBackendList(data)) {
        data.forEach(function (location) {
          let counter: number = 0;
          let title: string = `${location.street.street_name} ${
            location.outer_number
          }${location.outer_letter != null ? location.outer_letter : ''}-${
            location.inner_number != null ? location.inner_number : ''
          }${location.inner_letter != null ? location.inner_letter : ''}`;
          let locationToReturn: LatLngCustomized = new google.maps.LatLng(
            location.latitude,
            location.longitude,
          );
          const contentString =
            '<div id="content">' +
            `<h1>${location.street.street_name} ${location.outer_number}</h1>` +
            `<button id="buttonMarkerID${counter}" type={'button'}>Revisar Ubicación!</button>` +
            '</div>';
          // @ts-ignore
          locationToReturn.id = counter;
          locationToReturn.dataRaw = location;
          locationToReturn.infoWindow = new google.maps.InfoWindow({
            content: contentString,
          });
          google.maps.event.addListener(
            locationToReturn.infoWindow,
            'domready',
            () => {
              const button = document.getElementById(
                `buttonMarkerID${locationToReturn.id}`,
              );
              if (button) {
                button.addEventListener('click', () => {
                  setDataRaw(locationToReturn.dataRaw!);
                });
              } else {
                console.error(
                  `El botón buttonMarkerID${locationToReturn.id} no fue encontrado en el DOM`,
                );
              }
            },
          );
          locationsToReturn.push(locationToReturn!);
          counter++;
        });
        return locationsToReturn;
      }
    }
  }

  React.useEffect(() => {
    if (ref.current && !map) {
      if (setMap != null) {
        setMap(new window.google.maps.Map(ref.current, {}));
      }
    }
  }, [ref, map]);

  const deepCompareEqualsForMaps = createCustomEqual({
    createInternalComparator: (deepEqual) => (a: any, b: any) => {
      if (
        isLatLngLiteral(a) ||
        a instanceof google.maps.LatLng ||
        isLatLngLiteral(b) ||
        b instanceof google.maps.LatLng
      ) {
        const latLngA =
          a instanceof google.maps.LatLng ? a : new google.maps.LatLng(a);
        const latLngB =
          b instanceof google.maps.LatLng ? b : new google.maps.LatLng(b);
        return latLngA.equals(latLngB);
      }
      // @ts-ignore
      return deepEqual(a, b);
    },
  });

  function useDeepCompareMemoize(value: any) {
    const ref = React.useRef();

    if (!deepCompareEqualsForMaps(value, ref.current)) {
      ref.current = value;
    }

    return ref.current;
  }

  function useDeepCompareEffectForMaps(
    callback: React.EffectCallback,
    dependencies: any[],
  ) {
    React.useEffect(callback, dependencies.map(useDeepCompareMemoize));
  }

  // because React does not do deep comparisons, a custom hook is used
  // see discussion in https://github.com/googlemaps/js-samples/issues/946
  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);

  React.useEffect(() => {
    if (map) {
      ['click', 'idle'].forEach((eventName) =>
        google.maps.event.clearListeners(map, eventName),
      );

      if (onClick) {
        map.addListener('click', onClick);
      }

      if (onIdle) {
        map.addListener('idle', () => onIdle(map));
      }
    }
  }, [map, onClick, onIdle]);

  return (
    <MapWWrapperPresentation
      mapRef={ref}
      style={style}
      children={children}
      map={map != null ? map : null}
    />
  );
};

export default MapWWrapperContainer;
