import React, { useEffect, useState, ChangeEvent } from 'react';
import CellPhonesInput from '../components/CellPhonesInput';

interface CellPhonesRegisterContainerProps {
  id: string;
  initialCellNumbers?: number[];
  onRegisteredCellPhones: (cellPhones: number[]) => void;
  readOnly?: boolean;
}

const CellPhonesRegisterContainer: React.FC<
  CellPhonesRegisterContainerProps
> = ({
  id = 'CellPhonesRegister',
  initialCellNumbers = [],
  onRegisteredCellPhones,
  readOnly = false,
}) => {
  const [cellNum, setCellNum] = useState<number>(
    initialCellNumbers.length || 1,
  );
  const [cellPhones, setCellPhones] = useState<number[]>(initialCellNumbers);
  const [initialized, setInitialized] = useState<boolean>(false);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value);
    if (newValue < 1) {
      setCellNum(1);
    } else if (newValue > 10) {
      setCellNum(10);
    } else {
      setCellNum(newValue);
    }
  };

  const handleCelularChange = (
    event: ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const newCellPhones = [...cellPhones];
    newCellPhones[index] = parseInt(event.target.value);
    setCellPhones(newCellPhones);
  };

  useEffect(() => {
    if (initialized) {
      const cellPhonesList = cellPhones.slice(0, cellNum);
      onRegisteredCellPhones(cellPhonesList);
    } else {
      setInitialized(true);
    }
  }, [cellPhones, cellNum, initialized]);

  return (
    <CellPhonesInput
      id={id}
      cellNum={cellNum}
      cellPhones={cellPhones}
      readOnly={readOnly}
      handleChange={handleChange}
      handleCelularChange={handleCelularChange}
    />
  );
};

export default CellPhonesRegisterContainer;
