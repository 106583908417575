import React, { useEffect, useRef } from 'react';

interface Message {
  sender: 'bot' | 'user';
  text: string;
}

interface ChatbotHTTPPresentationProps {
  messages: Message[];
  input: string;
  setInput: React.Dispatch<React.SetStateAction<string>>;
  onSendMessage: (event: React.FormEvent) => void;
}

function ChatbotHTTPPresentation({
  messages,
  input,
  setInput,
  onSendMessage,
}: ChatbotHTTPPresentationProps) {
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  return (
    <div
      style={{
        position: 'fixed',
        bottom: '80px',
        right: '20px',
        width: '300px',
        height: '400px',
        backgroundColor: 'white',
        border: '1px solid #ddd',
        borderRadius: '8px',
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <div style={{ overflowY: 'auto', flexGrow: 1 }}>
        {messages.map((msg, index) => (
          <p
            key={index}
            style={{ textAlign: msg.sender === 'bot' ? 'left' : 'right' }}
          >
            <strong>{msg.sender === 'bot' ? 'Bot' : 'Tú'}:</strong> {msg.text}
          </p>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <form
        onSubmit={onSendMessage}
        style={{ display: 'flex', marginTop: '10px' }}
      >
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          style={{ flexGrow: 1, marginRight: '5px' }}
          placeholder="Escribe un mensaje..."
        />
        <button type="submit">Enviar Mensaje</button>
      </form>
    </div>
  );
}

export default ChatbotHTTPPresentation;
