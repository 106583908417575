import React from 'react';
import {
  WorkUserFrontend,
  LocationBackend,
} from '../services/BackendFrontendInterfaces';
import CustomizedMenuListContainer from '../containers/CustomizedMenuListContainer';
import PublishWorkContainer from '../containers/PublishWorkContainer';

interface PresentationProps {
  id?: string;
  innerReadOnly?: boolean;
  showForm: boolean;
  handleChangeWork: (workData: WorkUserFrontend) => void;
  handleSubmit: (event: React.FormEvent) => void;
  companiesList: string[];
  handleSelectChangeCompany: (
    selectedOption: React.ChangeEvent<HTMLSelectElement>,
  ) => void;
  setLocationSelected?: React.Dispatch<LocationBackend | undefined>;
  buttonLabel: string;
}

const PublishWorkForMyCompanyPresentation: React.FC<PresentationProps> = ({
  id = 'PublishWorkForMyCompany',
  innerReadOnly = false,
  showForm,
  companiesList = [],
  buttonLabel,
  handleSelectChangeCompany,
  handleChangeWork,
  handleSubmit,
  setLocationSelected,
}) => {
  return (
    <div>
      {showForm && (
        <div>
          <div>
            <p>Compañía:</p>
            {companiesList.length > 0 ? (
              <div>
                <CustomizedMenuListContainer
                  id={`CustomizedMenuList${id}`}
                  options={companiesList}
                  readOnly={innerReadOnly}
                  onItemClick={handleSelectChangeCompany}
                />
              </div>
            ) : (
              <div>Cargando...</div>
            )}
          </div>
          <PublishWorkContainer
            id={`PublishWork${id}`}
            onChange={handleChangeWork}
            readOnly={innerReadOnly}
            handleSubmitFather={handleSubmit}
            setFatherLocationSelected={setLocationSelected}
            buttonLabel={buttonLabel}
          />
        </div>
      )}
    </div>
  );
};

export default PublishWorkForMyCompanyPresentation;
