import React, { useState, useEffect, useContext } from 'react';
import { makeRequest } from '../services/requests';
import MyCompaniesDisapprovalsPresentation from '../components/MyCompaniesDisapprovalsPresentation';
import { CardsGridCaller } from '../components/GridCard';
import { DisapproveUserCompanyBackend } from '../services/BackendFrontendInterfaces';
import { SessionContext } from '../services/SessionContextProvider';

interface MyCompaniesDisapprovalsProps {
  id?: CardsGridCaller;
}

function MyCompaniesDisapprovalsContainer({
  id = 'myCompaniesDisapprovals',
}: MyCompaniesDisapprovalsProps) {
  const {
    myCompaniesDisapprovalsBackendCache,
    setMyCompaniesDisapprovalsBackendCache,
  } = useContext(SessionContext);

  async function fetchData() {
    const path = `networking/get_all_my_companies_disapprovals`;
    const method = 'GET';
    try {
      const response = await makeRequest({ path: path, method: method });
      const data: DisapproveUserCompanyBackend[] = await response.json();
      setMyCompaniesDisapprovalsBackendCache(data);
    } catch (error: unknown) {
      if (error instanceof Error) {
        alert(error.message);
      } else if (
        typeof error === 'object' &&
        error !== null &&
        'message' in error &&
        'componentStack' in error
      ) {
        const reactError = error as React.ErrorInfo;
        alert(reactError);
      } else {
        alert(JSON.stringify(error));
      }
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  if (
    myCompaniesDisapprovalsBackendCache == null ||
    myCompaniesDisapprovalsBackendCache?.length == 0
  ) {
    return <h1>No se encontraron Calificaciones Negativas</h1>;
  } else {
    return (
      <MyCompaniesDisapprovalsPresentation
        id={id}
        disapprovalsDataRaw={myCompaniesDisapprovalsBackendCache}
      />
    );
  }
}

export default MyCompaniesDisapprovalsContainer;
