import React, { useState, useEffect, useContext } from 'react';
import MakeOfferPresentation from '../components/MakeOfferPresentation';
import constants from '../data/constants.json';
import { makeRequest } from '../services/requests';
import {
  CompanyBackend,
  WorkOfferFrontend,
  WorkUserBackend,
  WorkOfferBackend,
  FormDataModes,
  WorkCompanyBackendFromAPI,
} from '../services/BackendFrontendInterfaces';
import { SessionContext } from '../services/SessionContextProvider';
import { OfferOption } from './PublishWorkContainer';
import {
  isWorkOfferBackend,
  isWorkUserBackend,
  isWorkCompanyBackendFromAPI,
} from '../services/InterfaceTypeValidator';

const FormWithDataModes = constants.FormWithDataModes;

interface ContainerProps {
  formValuesFather?: Omit<WorkOfferFrontend, 'user' | 'company'>;
  handleSubmitFather?: (event: React.FormEvent) => void;
  onChange?:
    | null
    | ((offerData: Omit<WorkOfferFrontend, 'user' | 'company'>) => void);
  work: WorkUserBackend | WorkCompanyBackendFromAPI;
  id?: string;
  readOnly?: boolean;
  offerDataRaw?: WorkOfferBackend;
  mode?: FormDataModes['value'];
}

const MakeOfferContainer: React.FC<ContainerProps> = ({
  formValuesFather,
  work,
  id = 'MakeOffer',
  handleSubmitFather = undefined,
  onChange = undefined,
  readOnly = false,
  offerDataRaw,
  mode = FormWithDataModes.Edit,
}) => {
  const [innerReadOnly, setInnerReadOnly] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [showForm, setshowForm] = useState(true);
  const [showMap, setShowMap] = useState(false);
  const [toolsTotalBudget, setToolsTotalBudget] = useState(0.0);
  const [materialsTotalBudget, setMaterialsTotalBudget] = useState(0.0);
  const [externalWorksTotalBudget, setExternalWorksTotalBudget] = useState(0.0);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const { meBackend } = useContext(SessionContext);
  const [formValues, setFormValues] = useState<
    Omit<WorkOfferFrontend, 'user' | 'company'>
  >({
    work: isWorkUserBackend(work) ? work : work.work,
    laborBudget: '0',
    startDateTime: '',
    endDateTime: '',
    externalWorks: [],
    tools: [],
    materials: [],
    id: 0,
  });

  const parseISODate = (isoDate: string): string => {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}:00`;
  };

  const toolsBackendToFrontend = (tools: WorkOfferBackend['tools']) => {
    if (tools != null) {
      const toReturn: WorkOfferFrontend['tools'] = [];

      tools.forEach((tool) => {
        const tl = {
          tool: tool.tool.tool,
          toolBudget: tool.tool_budget.toString(),
        };

        toReturn.push(tl);
      });
      return toReturn;
    }
  };

  const externalWorksBackendToFrontend = (
    externalWorks: WorkOfferBackend['external_works'],
  ) => {
    if (externalWorks != null) {
      const toReturn: WorkOfferFrontend['externalWorks'] = [];

      externalWorks.forEach((work) => {
        const ew = {
          externalWork: work.external_work.external_work,
          externalWorkBudget: work.external_work_budget.toString(),
        };

        toReturn.push(ew);
      });
      return toReturn;
    }
  };

  const materialsBackendToFrontend = (
    materials: WorkOfferBackend['materials'],
  ) => {
    if (materials != null) {
      const toReturn: WorkOfferFrontend['materials'] = [];

      materials.forEach((material) => {
        const mr = {
          material: material.material.material,
          materialBudget: material.material_budget.toString(),
        };

        toReturn.push(mr);
      });
      return toReturn;
    }
  };

  useEffect(() => {
    setInnerReadOnly(readOnly);
    if (formValuesFather != null) {
      setFormValues(formValuesFather);
    } else if (isWorkOfferBackend(offerDataRaw)) {
      setFormValues((prevValues) => ({
        ...prevValues,
        work: offerDataRaw.work,
        startDateTime: parseISODate(offerDataRaw.start_date_time),
        endDateTime: parseISODate(offerDataRaw.end_date_time),
        laborBudget: offerDataRaw.labor_budget.toString(),
        tools: toolsBackendToFrontend(offerDataRaw.tools)!,
        materials: materialsBackendToFrontend(offerDataRaw.materials)!,
        externalWorks: externalWorksBackendToFrontend(
          offerDataRaw.external_works,
        )!,
      }));
    } else if (work != null) {
      if (isWorkUserBackend(work)) {
        setFormValues((prevValues) => ({
          ...prevValues,
          work: work,
          startDateTime: parseISODate(work.start_date_time),
          endDateTime: parseISODate(work.end_date_time),
          workBudget: work.work_budget,
        }));
      } else if (isWorkCompanyBackendFromAPI(work)) {
        setFormValues((prevValues) => ({
          ...prevValues,
          work: work.work,
          startDateTime: parseISODate(work.work.start_date_time),
          endDateTime: parseISODate(work.work.end_date_time),
          workBudget: work.work.work_budget,
        }));
      }
    }
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (onChange != null) {
      onChange(formValues);
    }
    let toolsBudget = 0;
    formValues.tools.forEach((tool) => {
      toolsBudget = toolsBudget + parseFloat(tool.toolBudget);
    });
    setToolsTotalBudget(toolsBudget);
    let materialsBudget = 0;
    formValues.materials.forEach((material) => {
      materialsBudget = materialsBudget + parseFloat(material.materialBudget);
    });
    setMaterialsTotalBudget(materialsBudget);
    let externalWorksBudget = 0;
    formValues.externalWorks.forEach((work) => {
      externalWorksBudget =
        externalWorksBudget + parseFloat(work.externalWorkBudget);
    });
    setExternalWorksTotalBudget(externalWorksBudget);
  }, [formValues]);

  const handleSubmit = async (event: React.FormEvent) => {
    const toolsFrontendToBackend = (tools: WorkOfferFrontend['tools']) => {
      if (tools != null) {
        const toReturn: WorkOfferBackend['tools'] = [];

        tools.forEach((tool) => {
          const tl = {
            tool: { tool: tool.tool },
            tool_budget: parseFloat(tool.toolBudget),
          };

          toReturn.push(tl);
        });
        return toReturn;
      }
    };

    const externalWorksFrontendToBackend = (
      externalWorks: WorkOfferFrontend['externalWorks'],
    ) => {
      if (externalWorks != null) {
        const toReturn: WorkOfferBackend['external_works'] = [];

        externalWorks.forEach((work) => {
          const ew = {
            external_work: { external_work: work.externalWork },
            external_work_budget: parseFloat(work.externalWorkBudget),
          };

          toReturn.push(ew);
        });
        return toReturn;
      }
    };

    const materialsFrontendToBackend = (
      materials: WorkOfferFrontend['materials'],
    ) => {
      if (materials != null) {
        const toReturn: WorkOfferBackend['materials'] = [];

        materials.forEach((material) => {
          const mr = {
            material: { material: material.material },
            material_budget: parseFloat(material.materialBudget),
          };

          toReturn.push(mr);
        });
        return toReturn;
      }
    };

    const fixDate = (date: string): string => {
      const originalDate: Date = new Date(date);
      const formattedDate: string = originalDate.toISOString().slice(0, -1);
      return formattedDate;
    };

    event.preventDefault();
    const obj_to_send: WorkOfferBackend = {
      work: formValues.work,
      labor_budget: parseInt(formValues.laborBudget),
      start_date_time: fixDate(formValues.startDateTime),
      end_date_time: fixDate(formValues.endDateTime),
      user: undefined,
      company: undefined,
      tools: toolsFrontendToBackend(formValues.tools),
      materials: materialsFrontendToBackend(formValues.materials),
      external_works: externalWorksFrontendToBackend(formValues.externalWorks),
    };
    try {
      const path = `offers/make_user_offer`;
      const method = `POST`;

      const content_type = 'application/json';
      const response = await makeRequest({
        path: path,
        method: method,
        body: JSON.stringify(obj_to_send),
        contentType: content_type,
      });

      if (!response.ok) {
        const response_json = await response.json();
        throw new Error(JSON.stringify(response_json.detail));
      }

      const output_message = `Registro Exitoso`;
      alert(output_message);
      window.location.reload();
    } catch (error: unknown) {
      if (error instanceof Error) {
        alert(error.message);
      } else if (
        typeof error === 'object' &&
        error !== null &&
        'message' in error &&
        'componentStack' in error
      ) {
        const reactError = error as React.ErrorInfo;
        alert(reactError);
      } else {
        alert(JSON.stringify(error));
      }
    }
  };

  return (
    <div>
      {loaded ? (
        <MakeOfferPresentation
          fullBudgetOffer={
            parseFloat(formValues.laborBudget) +
            toolsTotalBudget +
            materialsTotalBudget +
            externalWorksTotalBudget
          }
          id={id}
          setstartDateTime={(startDate: string) => {
            setFormValues((prevValues) => ({
              ...prevValues,
              startDateTime: startDate,
            }));
          }}
          setendDateTime={(endDate: string) => {
            setFormValues((prevValues) => ({
              ...prevValues,
              endDateTime: endDate,
            }));
          }}
          onRegisteredTools={(tools: WorkOfferFrontend['tools']) => {
            setFormValues((prevValues) => ({
              ...prevValues,
              tools: tools,
            }));
          }}
          onRegisteredMaterials={(
            materials: WorkOfferFrontend['materials'],
          ) => {
            setFormValues((prevValues) => ({
              ...prevValues,
              materials: materials,
            }));
          }}
          onRegisteredExternalWorks={(
            externalWorks: WorkOfferFrontend['externalWorks'],
          ) => {
            setFormValues((prevValues) => ({
              ...prevValues,
              externalWorks: externalWorks,
            }));
          }}
          innerReadOnly={readOnly}
          formValues={formValues}
          showForm={showForm}
          handleBudgetChange={(event) => {
            const { name, value } = event.target;
            setFormValues((prevValues) => ({
              ...prevValues,
              laborBudget: value,
            }));
          }}
          handleSubmit={
            handleSubmitFather != null ? handleSubmitFather : handleSubmit
          }
        />
      ) : (
        <p>Cargando...</p>
      )}
    </div>
  );
};

export default MakeOfferContainer;
