import React, { useEffect, useState } from 'react';
import PhonesRegisterPresentation from '../components/PhonesRegisterPresentation';
import { CompanyFrontend } from '../services/BackendFrontendInterfaces';

interface PhonesRegisterContainerProps {
  id: string;
  onRegisteredPhones: (phones: CompanyFrontend['companyPhones']) => void;
  initialPhones: CompanyFrontend['companyPhones'];
  readOnly: boolean;
}

const PhonesRegisterContainer: React.FC<PhonesRegisterContainerProps> = ({
  id = 'PhonesRegister',
  onRegisteredPhones,
  initialPhones = [],
  readOnly = false,
}) => {
  const [phonesNumber, setPhonesNumbers] = useState<number>(
    initialPhones.length ? initialPhones.length : 1,
  );
  const [phones, setPhones] = useState<CompanyFrontend['companyPhones']>(
    initialPhones.length
      ? initialPhones
      : [{ companyPhone: '', extension: '' }],
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value);
    if (newValue < 1) {
      setPhonesNumbers(1);
    } else if (newValue > 10) {
      setPhonesNumbers(10);
      setPhones(phones.slice(0, 10));
    } else {
      setPhonesNumbers(newValue);
      const newToolsData =
        newValue > phones.length
          ? [
              ...phones,
              ...Array(newValue - phones.length).fill({
                companyPhone: '',
                extension: '',
              }),
            ]
          : phones.slice(0, newValue);

      setPhones(newToolsData);
    }
  };

  const handlePhoneChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    key: string,
  ) => {
    const newPhones = [...phones];
    newPhones[index] = {
      ...newPhones[index],
      [key]: event.target.value,
    };
    setPhones(newPhones);
  };

  useEffect(() => {
    onRegisteredPhones(phones);
  }, [phones, phonesNumber]);

  return (
    <PhonesRegisterPresentation
      id={id}
      readOnly={readOnly}
      phonesNumber={phonesNumber}
      phones={phones}
      handleChange={handleChange}
      handlePhoneChange={handlePhoneChange}
    />
  );
};

export default PhonesRegisterContainer;
