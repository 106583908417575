import GridCard from './GridCard';
import { DeadWorkOfferBackend } from '../services/BackendFrontendInterfaces';
import { CardsGridCaller } from '../components/GridCard';
import CustomizedMenuListContainer from '../containers/CustomizedMenuListContainer';

interface MyCompaniesCompletedOffersPresentationProps {
  id: CardsGridCaller;
  offersDataRaw: DeadWorkOfferBackend[];
  companiesList: string[];
  handleSelectChangeCompany: (
    selectedOption: React.ChangeEvent<HTMLSelectElement>,
  ) => void;
}

function MyCompaniesCompletedOffersPresentation({
  id = 'myCompaniesDeadOffers',
  offersDataRaw = [],
  companiesList,
  handleSelectChangeCompany,
}: MyCompaniesCompletedOffersPresentationProps) {
  return companiesList?.length > 0 ? (
    <div>
      <div>
        <p>Compañía:</p>
        <div>
          <CustomizedMenuListContainer
            id={`CustomizedMenuList${id}`}
            options={companiesList}
            onItemClick={handleSelectChangeCompany}
          />
        </div>
      </div>
      {offersDataRaw.length > 0 ? (
        <GridCard cardsData={offersDataRaw} id={`Grid${id}`} />
      ) : (
        <div>No se encontraron Ofertas Completadas</div>
      )}
    </div>
  ) : (
    <h2>Cargando...</h2>
  );
}

export default MyCompaniesCompletedOffersPresentation;
