import React from 'react';
import CustomizedMenusContainer from '../containers/CustomizedMenusContainer';
import AutocompleteContainer from '../containers/AutocompleteContainer';
import WrapperForMap from '../components/WrapperForMap';
import CacheBuildings from '../data/CacheBuildings.json';
import CacheCities from '../data/CacheCities.json';
import CacheCountries from '../data/CacheCountries.json';
import CacheDistricts from '../data/CacheDistricts.json';
import CacheNeighborhood from '../data/CacheNeighborhood.json';
import CacheStates from '../data/CacheStates.json';
import CacheStreets from '../data/CacheStreets.json';
import constants from '../data/constants.json';
import {
  LocationBackend,
  LocationFrontend,
} from '../services/BackendFrontendInterfaces';

interface LocationFormPresentationProps {
  id?: string;
  loaded?: boolean;
  showForm?: boolean;
  handleSubmit: (event: React.FormEvent) => void;
  handleAutocompleteContainerChange: (value: {
    name: string | null;
    value: string[];
  }) => void;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelectChangeCurrencyType: (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => void;
  handleSelectedPosition: (coords: { lat: number; lng: number }) => void;
  handleNextClick: () => void;
  locationsDataRawList?: LocationBackend[];
  formValues: LocationFrontend;
  innerReadOnly: boolean;
  showMap: boolean;
  buttonLabel?: string;
}

const LocationFormPresentation: React.FC<LocationFormPresentationProps> = ({
  id = 'Location',
  loaded = false,
  showForm = true,
  innerReadOnly = false,
  showMap = false,
  handleSubmit,
  handleAutocompleteContainerChange,
  handleInputChange,
  handleSelectChangeCurrencyType,
  handleSelectedPosition,
  handleNextClick,
  locationsDataRawList = [],
  formValues,
  buttonLabel = undefined,
}) => {
  const localCurrenciesList = constants.currencies_spanish;
  const selectormenu = constants.keyOrValue;
  return (
    <div>
      {loaded ? (
        <div>
          {showForm && (
            <form className="App" onSubmit={handleSubmit} id={`FormOne${id}`}>
              {!innerReadOnly ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <label
                    htmlFor={`input${id}proofOfAddress`}
                    id={`label${id}proofOfAddress`}
                  >
                    Comprobante De Domicilio:
                  </label>
                  <input
                    id={`input${id}proofOfAddress`}
                    name="proofOfAddress"
                    type="file"
                    accept=".pdf"
                    required
                    onChange={handleInputChange}
                  />
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                ></div>
              )}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <AutocompleteContainer
                  id={`Autocomplete${id}Country`}
                  autocompleteTitle={'País:'}
                  required
                  name="country"
                  options={CacheCountries.CacheCountries}
                  onChange={handleAutocompleteContainerChange}
                  initialValues={[formValues.country]}
                  readOnly={innerReadOnly}
                  multipleOptions={false}
                  acceptNew={true}
                />
                <AutocompleteContainer
                  id={`Autocomplete${id}State`}
                  autocompleteTitle={'Estado:'}
                  required
                  name="state"
                  options={CacheStates.CacheStates}
                  onChange={handleAutocompleteContainerChange}
                  initialValues={[formValues.state]}
                  readOnly={innerReadOnly}
                  multipleOptions={false}
                  acceptNew={true}
                />
              </div>
              <br />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <AutocompleteContainer
                  id={`Autocomplete${id}City`}
                  autocompleteTitle={'Ciudad:'}
                  required
                  name="city"
                  options={CacheCities.CacheCities}
                  onChange={handleAutocompleteContainerChange}
                  initialValues={[formValues.city]}
                  readOnly={innerReadOnly}
                  multipleOptions={false}
                  acceptNew={true}
                />
                <AutocompleteContainer
                  id={`Autocomplete${id}Neighborhood`}
                  autocompleteTitle={'Colonia:'}
                  required
                  name="neighborhood"
                  options={CacheNeighborhood.CacheNeighborhood}
                  onChange={handleAutocompleteContainerChange}
                  initialValues={[formValues.neighborhood]}
                  readOnly={innerReadOnly}
                  multipleOptions={false}
                  acceptNew={true}
                />
              </div>
              <br />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <AutocompleteContainer
                  id={`Autocomplete${id}District`}
                  autocompleteTitle={'Distrito:'}
                  name="district"
                  options={CacheDistricts.CacheDistricts}
                  onChange={handleAutocompleteContainerChange}
                  initialValues={[formValues.district]}
                  readOnly={innerReadOnly}
                  multipleOptions={false}
                  acceptNew={true}
                />
                <label htmlFor={`input${id}PostCode`} id={`label${id}PostCode`}>
                  Código Postal:
                </label>
                <input
                  id={`input${id}PostCode`}
                  name="postCode"
                  type="text"
                  required
                  disabled={innerReadOnly}
                  value={formValues.postCode}
                  onChange={handleInputChange}
                />
              </div>
              <br />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <AutocompleteContainer
                  id={`Autocomplete${id}Street`}
                  autocompleteTitle={'Calle:'}
                  required
                  name="street"
                  options={CacheStreets.CacheStreets}
                  onChange={handleAutocompleteContainerChange}
                  initialValues={[formValues.street]}
                  readOnly={innerReadOnly}
                  multipleOptions={false}
                  acceptNew={true}
                />
                <AutocompleteContainer
                  id={`Autocomplete${id}Building`}
                  autocompleteTitle={'Edificio:'}
                  name="building"
                  options={CacheBuildings.CacheBuildings}
                  onChange={handleAutocompleteContainerChange}
                  initialValues={[formValues.building]}
                  readOnly={innerReadOnly}
                  multipleOptions={false}
                  acceptNew={true}
                />
              </div>
              <br />
              <div>
                <label
                  htmlFor={`input${id}OuterNumber`}
                  id={`label${id}OuterNumber`}
                >
                  Número Exterior:
                </label>
                <input
                  id={`input${id}OuterNumber`}
                  name="outerNumber"
                  type="text"
                  required
                  disabled={innerReadOnly}
                  value={formValues.outerNumber}
                  onChange={handleInputChange}
                />
                <label
                  htmlFor={`input${id}OuterLetter`}
                  id={`label${id}OuterLetter`}
                >
                  Letra Exterior:
                </label>
                <input
                  id={`input${id}OuterLetter`}
                  name="outerLetter"
                  type="text"
                  disabled={innerReadOnly}
                  value={formValues.outerLetter}
                  onChange={handleInputChange}
                />
              </div>
              <br />
              <div>
                <label
                  htmlFor={`input${id}InnerNumber`}
                  id={`label${id}InnerNumber`}
                >
                  Número Interior:
                </label>
                <input
                  id={`input${id}InnerNumber`}
                  name="innerNumber"
                  type="text"
                  disabled={innerReadOnly}
                  value={formValues.innerNumber}
                  onChange={handleInputChange}
                />
                <label
                  htmlFor={`input${id}InnerLetter`}
                  id={`label${id}InnerLetter`}
                >
                  Letra Interior:
                </label>
                <input
                  id={`input${id}InnerLetter`}
                  name="innerLetter"
                  type="text"
                  disabled={innerReadOnly}
                  value={formValues.innerLetter}
                  onChange={handleInputChange}
                />
              </div>
              <br />
              <div>
                <label htmlFor={`input${id}GPSLink`} id={`label${id}GPSLink`}>
                  Link En Google Maps
                </label>
                <input
                  id={`input${id}GPSLink`}
                  name="gpsCenterMapsLink"
                  type="text"
                  required
                  disabled={innerReadOnly}
                  value={formValues.gpsCenterMapsLink}
                  onChange={handleInputChange}
                />
                <p>Divisa Local: </p>
                <CustomizedMenusContainer
                  id={`CustomizedMenus${id}Currency`}
                  keyOrValue={selectormenu.Value}
                  options={localCurrenciesList}
                  onItemClick={handleSelectChangeCurrencyType}
                  readOnly={innerReadOnly}
                  initialize={formValues.localCurrency}
                />
              </div>
              <br />
              <div>
                {buttonLabel != null &&
                  formValues.latitude != -99999 &&
                  formValues.longitude != 99999 && (
                    <button
                      id={`button${id}SubmitOrDeleteLocation`}
                      type="submit"
                    >
                      {buttonLabel}
                    </button>
                  )}
              </div>
            </form>
          )}
          {showMap && (
            <WrapperForMap
              id={`wrapperForMap${id}`}
              locationsDataRawList={locationsDataRawList}
              handleSelectedPosition={handleSelectedPosition}
            />
          )}
          {formValues.longitude && formValues.latitude && (
            <form id={`FormTwo${id}`}>
              <div>
                <label
                  htmlFor={`input${id}Longitude`}
                  id={`label${id}Longitude`}
                >
                  Longitud:
                </label>
                <input
                  id={`input${id}Longitude`}
                  name="longitude"
                  type="text"
                  required
                  disabled={true}
                  value={formValues.longitude}
                  onChange={handleInputChange}
                />
                <label htmlFor={`input${id}Latitude`} id={`label${id}Latitude`}>
                  Latitud:
                </label>
                <input
                  id={`input${id}Latitude`}
                  name="latitude"
                  type="text"
                  disabled={true}
                  value={formValues.latitude}
                  onChange={handleInputChange}
                />
              </div>
            </form>
          )}
          {showForm &&
            (innerReadOnly ? (
              <div></div>
            ) : (
              <div>
                <button
                  onClick={handleNextClick}
                  id={`button${id}SelectInMap`}
                  type="button"
                >
                  Seleccionar En Mapa
                </button>
              </div>
            ))}
        </div>
      ) : (
        <p>Cargando...</p>
      )}
    </div>
  );
};

export default LocationFormPresentation;
