import {
  createContext,
  useState,
  FC,
  Dispatch,
  SetStateAction,
  ReactNode,
} from 'react';
import {
  UserBackend,
  LocationBackend,
  CompanyBackend,
  WorkUserBackend,
  WorkCompanyBackend,
  WorkOfferBackend,
  LocationCompanyBackend,
  ApproveUserCompanyBackend,
  DisapproveUserCompanyBackend,
} from './BackendFrontendInterfaces';
import { isLogged } from './requests';

interface SessionContextType {
  componentSelected: string;
  setComponentSelected: Dispatch<SetStateAction<string>>;
  publishWorkChatbotCity: string;
  setPublishWorkChatbotCity: Dispatch<SetStateAction<string>>;
  publishWorkChatbotWorker: string;
  setPublishWorkChatbotWorker: Dispatch<SetStateAction<string>>;
  publishWorkChatbotStartDate: string;
  setPublishWorkChatbotStartDate: Dispatch<SetStateAction<string>>;
  publishWorkChatbotEnable: boolean;
  setPublishWorkChatbot: Dispatch<SetStateAction<boolean>>;
  userLogged: boolean;
  setLoggedUser: Dispatch<SetStateAction<boolean>>;
  meBackend: UserBackend | null;
  setMeBackend: Dispatch<SetStateAction<UserBackend | null>>;
  myLocationsBackendCache: LocationBackend[] | null;
  setMyLocationsBackendCache: Dispatch<
    SetStateAction<LocationBackend[] | null>
  >;
  myCompaniesBackendCache: CompanyBackend[] | null;
  setMyCompaniesBackendCache: Dispatch<SetStateAction<CompanyBackend[] | null>>;
  myApprovalsBackendCache: ApproveUserCompanyBackend[] | null;
  setMyApprovalsBackendCache: Dispatch<
    SetStateAction<ApproveUserCompanyBackend[] | null>
  >;
  myCompaniesApprovalsBackendCache: ApproveUserCompanyBackend[] | null;
  setMyCompaniesApprovalsBackendCache: Dispatch<
    SetStateAction<ApproveUserCompanyBackend[] | null>
  >;
  myCompaniesDisapprovalsBackendCache: DisapproveUserCompanyBackend[] | null;
  setMyCompaniesDisapprovalsBackendCache: Dispatch<
    SetStateAction<DisapproveUserCompanyBackend[] | null>
  >;
  myDisapprovalsBackendCache: DisapproveUserCompanyBackend[] | null;
  setMyDisapprovalsBackendCache: Dispatch<
    SetStateAction<DisapproveUserCompanyBackend[] | null>
  >;
  myLocationsCompaniesBackendCache: LocationCompanyBackend[] | null;
  setMyLocationsCompaniesBackendCache: Dispatch<
    SetStateAction<LocationCompanyBackend[] | null>
  >;
  myWorksBackendCache: WorkUserBackend[] | null;
  setMyWorksBackendCache: Dispatch<SetStateAction<WorkUserBackend[] | null>>;
  myWorksCompaniesBackendCache: WorkCompanyBackend[] | null;
  setMyWorksCompaniesBackendCache: Dispatch<
    SetStateAction<WorkCompanyBackend[] | null>
  >;
  myOffersBackendCache: WorkOfferBackend[] | null;
  setMyOffersBackendCache: Dispatch<SetStateAction<WorkOfferBackend[] | null>>;
  offersForMyWorksCache: WorkOfferBackend[] | null;
  setOffersForMyWorksCache: Dispatch<SetStateAction<WorkOfferBackend[] | null>>;
  offersForMyCompaniesWorksCache: WorkOfferBackend[] | null;
  setOffersForMyCompaniesWorksCache: Dispatch<
    SetStateAction<WorkOfferBackend[] | null>
  >;
}

// Crear un contexto con un valor inicial más específico
export const SessionContext = createContext<SessionContextType>({
  componentSelected: 'index',
  setComponentSelected: () => {},
  publishWorkChatbotCity: '',
  setPublishWorkChatbotCity: () => {},
  publishWorkChatbotWorker: '',
  setPublishWorkChatbotWorker: () => {},
  publishWorkChatbotStartDate: '',
  setPublishWorkChatbotStartDate: () => {},
  publishWorkChatbotEnable: false,
  setPublishWorkChatbot: () => {},
  userLogged: isLogged(),
  setLoggedUser: () => {},
  meBackend: null,
  setMeBackend: () => {},
  myLocationsBackendCache: null,
  setMyLocationsBackendCache: () => {},
  myCompaniesBackendCache: null,
  setMyCompaniesBackendCache: () => {},
  myApprovalsBackendCache: null,
  setMyApprovalsBackendCache: () => {},
  myCompaniesApprovalsBackendCache: null,
  setMyCompaniesApprovalsBackendCache: () => {},
  myCompaniesDisapprovalsBackendCache: null,
  setMyCompaniesDisapprovalsBackendCache: () => {},
  myDisapprovalsBackendCache: null,
  setMyDisapprovalsBackendCache: () => {},
  myLocationsCompaniesBackendCache: null,
  setMyLocationsCompaniesBackendCache: () => {},
  myWorksBackendCache: null,
  setMyWorksBackendCache: () => {},
  myWorksCompaniesBackendCache: null,
  setMyWorksCompaniesBackendCache: () => {},
  myOffersBackendCache: null,
  setMyOffersBackendCache: () => {},
  offersForMyWorksCache: null,
  setOffersForMyWorksCache: () => {},
  offersForMyCompaniesWorksCache: null,
  setOffersForMyCompaniesWorksCache: () => {},
});

interface SessionContextProviderProps {
  children: ReactNode;
}

const SessionContextProvider: FC<SessionContextProviderProps> = ({
  children,
}) => {
  const [componentSelected, setComponentSelected] = useState<string>('index');
  const [publishWorkChatbotCity, setPublishWorkChatbotCity] =
    useState<string>('');
  const [publishWorkChatbotWorker, setPublishWorkChatbotWorker] =
    useState<string>('');
  const [publishWorkChatbotStartDate, setPublishWorkChatbotStartDate] =
    useState<string>('');
  const [publishWorkChatbotEnable, setPublishWorkChatbot] =
    useState<boolean>(false);
  const [userLogged, setLoggedUser] = useState<boolean>(isLogged());
  const [meBackend, setMeBackend] = useState<UserBackend | null>(null);
  const [myLocationsBackendCache, setMyLocationsBackendCache] = useState<
    LocationBackend[] | null
  >(null);
  const [myCompaniesBackendCache, setMyCompaniesBackendCache] = useState<
    CompanyBackend[] | null
  >(null);
  const [myApprovalsBackendCache, setMyApprovalsBackendCache] = useState<
    ApproveUserCompanyBackend[] | null
  >(null);
  const [
    myCompaniesApprovalsBackendCache,
    setMyCompaniesApprovalsBackendCache,
  ] = useState<ApproveUserCompanyBackend[] | null>(null);
  const [
    myCompaniesDisapprovalsBackendCache,
    setMyCompaniesDisapprovalsBackendCache,
  ] = useState<DisapproveUserCompanyBackend[] | null>(null);
  const [myDisapprovalsBackendCache, setMyDisapprovalsBackendCache] = useState<
    DisapproveUserCompanyBackend[] | null
  >(null);
  const [
    myLocationsCompaniesBackendCache,
    setMyLocationsCompaniesBackendCache,
  ] = useState<LocationCompanyBackend[] | null>(null);
  const [myWorksBackendCache, setMyWorksBackendCache] = useState<
    WorkUserBackend[] | null
  >(null);
  const [myWorksCompaniesBackendCache, setMyWorksCompaniesBackendCache] =
    useState<WorkCompanyBackend[] | null>(null);
  const [myOffersBackendCache, setMyOffersBackendCache] = useState<
    WorkOfferBackend[] | null
  >(null);
  const [offersForMyWorksCache, setOffersForMyWorksCache] = useState<
    WorkOfferBackend[] | null
  >(null);
  const [offersForMyCompaniesWorksCache, setOffersForMyCompaniesWorksCache] =
    useState<WorkOfferBackend[] | null>(null);

  return (
    <SessionContext.Provider
      value={{
        componentSelected,
        setComponentSelected,
        publishWorkChatbotCity,
        setPublishWorkChatbotCity,
        publishWorkChatbotWorker,
        setPublishWorkChatbotWorker,
        publishWorkChatbotStartDate,
        setPublishWorkChatbotStartDate,
        publishWorkChatbotEnable,
        setPublishWorkChatbot,
        userLogged,
        setLoggedUser,
        meBackend,
        setMeBackend,
        myLocationsBackendCache,
        setMyLocationsBackendCache,
        myCompaniesBackendCache,
        setMyCompaniesBackendCache,
        myApprovalsBackendCache,
        setMyApprovalsBackendCache,
        myCompaniesApprovalsBackendCache,
        setMyCompaniesApprovalsBackendCache,
        myCompaniesDisapprovalsBackendCache,
        setMyCompaniesDisapprovalsBackendCache,
        myDisapprovalsBackendCache,
        setMyDisapprovalsBackendCache,
        myLocationsCompaniesBackendCache,
        setMyLocationsCompaniesBackendCache,
        myWorksBackendCache,
        setMyWorksBackendCache,
        myWorksCompaniesBackendCache,
        setMyWorksCompaniesBackendCache,
        myOffersBackendCache,
        setMyOffersBackendCache,
        offersForMyWorksCache,
        setOffersForMyWorksCache,
        offersForMyCompaniesWorksCache,
        setOffersForMyCompaniesWorksCache,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export { SessionContextProvider };
