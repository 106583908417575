import React, { useState, useContext } from 'react';
import ChatbotHTTPPresentation from '../components/ChatbotHTTPPresentation';
import {
  Message,
  PublishWorkChatbot,
} from '../services/BackendFrontendInterfaces';
import { SessionContext } from '../services/SessionContextProvider';
import { makeRequest } from '../services/requests';

function ChatbotHTTPContainer() {
  const [messages, setMessages] = useState<Message[]>([]);
  const [input, setInput] = useState<string>('');
  const {
    publishWorkChatbotCity,
    publishWorkChatbotWorker,
    publishWorkChatbotStartDate,
  } = useContext(SessionContext);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const obj_to_send: PublishWorkChatbot = {
        city: publishWorkChatbotCity,
        worker: publishWorkChatbotWorker,
        start_date: publishWorkChatbotStartDate,
        background: messages,
        message_from_user: input,
      };
      const path = `works/chat`;
      const method = 'POST';
      const content_type = 'application/json';

      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: 'user', text: input },
      ]);
      setInput('');

      const response = await makeRequest({
        path: path,
        method: method,
        body: JSON.stringify(obj_to_send),
        contentType: content_type,
      });
      const data = await response.json();
      if (!response.ok) {
        const response_json = await response.json();
        throw new Error(JSON.stringify(response_json.detail));
      }
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: 'bot', text: data.llm_response },
      ]);
    } catch (error: unknown) {
      if (error instanceof Error) {
        alert(error.message);
      } else if (
        typeof error === 'object' &&
        error !== null &&
        'message' in error &&
        'componentStack' in error
      ) {
        const reactError = error as React.ErrorInfo;
        alert(reactError);
      } else {
        alert(JSON.stringify(error));
      }
    }
  };

  return (
    <div style={{ overflowY: 'auto', maxHeight: '80vh' }}>
      <ChatbotHTTPPresentation
        messages={messages}
        input={input}
        setInput={setInput}
        onSendMessage={handleSubmit}
      />
    </div>
  );
}

export default ChatbotHTTPContainer;
