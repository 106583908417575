import CompanyTypeSpanishCat from '../data/company_type_spanish_cat.json';
import { autocompleteWMenuInterface } from './AutocompleteWMenu';

function prepareWorkTypeData(
  workType: autocompleteWMenuInterface['value'],
): { service_type: number; worker_type: { type: string } }[] {
  const result: { service_type: number; worker_type: { type: string } }[] = [];

  for (const serviceType in workType) {
    const serviceIndex =
      CompanyTypeSpanishCat.CompanyTypeSpanishCat.indexOf(serviceType);
    const workerTypes = workType[serviceType];

    workerTypes.forEach((workerType: string) => {
      result.push({
        service_type: serviceIndex,
        worker_type: { type: workerType },
      });
    });
  }

  return result;
}

export { prepareWorkTypeData };
