import React, { useState, useEffect } from 'react';
import { makeRequest } from '../services/requests';
import {
  WorkUserBackend,
  ArbitrationFrontend,
  WorkCompanyBackendFromAPI,
  EvaluationBackend,
  ArbitrationBackend,
  WorkOfferBackend,
} from '../services/BackendFrontendInterfaces';
import {
  isWorkUserBackend,
  isWorkCompanyBackendFromAPI,
  isWorkOfferBackend,
} from '../services/InterfaceTypeValidator';
import ArbitrationPresentation from '../components/ArbitrationPresentation';
import EvaluateWorkContainer from './EvaluateWorkContainer';

interface ArbitrationWorkProps {
  id?: string;
  workOrOfferDataRaw:
    | WorkUserBackend
    | WorkCompanyBackendFromAPI
    | WorkOfferBackend;
}

function ArbitrationWorkContainer({
  id = 'Arbitration',
  workOrOfferDataRaw,
}: ArbitrationWorkProps) {
  const [arbitration, setArbitration] = useState<ArbitrationBackend | null>(
    null,
  );
  const [arbitrated, setArbitrated] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<
    Omit<ArbitrationFrontend, 'work'>
  >({
    customerProposal: {
      customerShare: isWorkUserBackend(workOrOfferDataRaw)
        ? workOrOfferDataRaw.balance!.toString()
        : workOrOfferDataRaw.work.balance!.toString(),
      contractorShare: '0',
      justification: '',
    },
    contractorProposal: {
      customerShare: isWorkUserBackend(workOrOfferDataRaw)
        ? workOrOfferDataRaw.balance!.toString()
        : workOrOfferDataRaw.work.balance!.toString(),
      contractorShare: '0',
      justification: '',
    },
  });

  const getArbitrationOffers = async () => {
    try {
      let path: string = '';
      if (isWorkUserBackend(workOrOfferDataRaw)) {
        path = `arbitration/get_opened_arbitrations_for_work/work_id/${workOrOfferDataRaw.id}`;
      } else if (isWorkCompanyBackendFromAPI(workOrOfferDataRaw)) {
        path = `arbitration/get_opened_arbitrations_for_work/work_id/${workOrOfferDataRaw.work.id}`;
      } else if (isWorkOfferBackend(workOrOfferDataRaw)) {
        path = `arbitration/get_opened_arbitrations_for_offer/offer_id/${workOrOfferDataRaw.id}`;
      }
      const method = 'GET';

      const response = await makeRequest({ path: path, method: method });
      if (!response.ok) {
        const response_json = await response.json();
        throw new Error(JSON.stringify(response_json.detail));
      }
      const data = await response.json();
      setArbitration(data);
    } catch (error: unknown) {
      if (error instanceof Error) {
        alert(error.message);
      } else if (
        typeof error === 'object' &&
        error !== null &&
        'message' in error &&
        'componentStack' in error
      ) {
        const reactError = error as React.ErrorInfo;
        alert(reactError);
      } else {
        alert(JSON.stringify(error));
      }
    }
  };

  useEffect(() => {
    getArbitrationOffers();
  }, []);

  useEffect(() => {
    if (arbitration?.contractor_proposal != null) {
      setFormValues((prevValues) => ({
        ...prevValues,
        contractorProposal: {
          customerShare:
            arbitration.contractor_proposal!.customer_share.toString(),
          justification: arbitration.contractor_proposal!.justification,
          contractorShare:
            arbitration.contractor_proposal!.contractor_share.toString(),
        },
      }));
    }
    if (arbitration?.customer_proposal != null) {
      setFormValues((prevValues) => ({
        ...prevValues,
        customerProposal: {
          customerShare:
            arbitration.customer_proposal!.customer_share.toString(),
          justification: arbitration.customer_proposal!.justification,
          contractorShare:
            arbitration.customer_proposal!.contractor_share.toString(),
        },
      }));
    }
  }, [arbitration]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const objToSend: ArbitrationBackend = {
        work: isWorkUserBackend(workOrOfferDataRaw)
          ? workOrOfferDataRaw
          : workOrOfferDataRaw.work,
        customer_proposal: {
          customer_share: parseFloat(formValues.customerProposal.customerShare),
          contractor_share: parseFloat(
            formValues.customerProposal.contractorShare,
          ),
          justification: formValues.customerProposal.justification,
        },
        contractor_proposal: {
          customer_share: parseFloat(
            formValues.contractorProposal.customerShare,
          ),
          contractor_share: parseFloat(
            formValues.contractorProposal.contractorShare,
          ),
          justification: formValues.contractorProposal.justification,
        },
      };
      if (
        isWorkUserBackend(workOrOfferDataRaw) ||
        isWorkCompanyBackendFromAPI(workOrOfferDataRaw)
      ) {
        delete objToSend.contractor_proposal;
      } else {
        delete objToSend.customer_proposal;
      }
      let path: string = 'arbitration/request_or_update_arbitration';
      const method = 'POST';
      const content_type = 'application/json';
      const response = await makeRequest({
        path: path,
        method: method,
        body: JSON.stringify(objToSend),
        contentType: content_type,
      });
      if (!response.ok) {
        const response_json = await response.json();
        throw new Error(JSON.stringify(response_json.detail));
      }
      const output_message = 'Oferta De Arbitraje Enviada Correctamente';
      alert(output_message);
      if (
        formValues.contractorProposal.contractorShare ==
          formValues.customerProposal.contractorShare &&
        formValues.contractorProposal.customerShare ==
          formValues.customerProposal.customerShare &&
        !isWorkOfferBackend(workOrOfferDataRaw)
      ) {
        setArbitrated(true);
      } else {
        window.location.reload();
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        alert(error.message);
      } else if (
        typeof error === 'object' &&
        error !== null &&
        'message' in error &&
        'componentStack' in error
      ) {
        const reactError = error as React.ErrorInfo;
        alert(reactError);
      } else {
        alert(JSON.stringify(error));
      }
    }
  };

  return (
    <div>
      {arbitrated == true &&
      (isWorkUserBackend(workOrOfferDataRaw) ||
        isWorkCompanyBackendFromAPI(workOrOfferDataRaw)) ? (
        <EvaluateWorkContainer id={id} workDataRaw={workOrOfferDataRaw} />
      ) : (
        <ArbitrationPresentation
          id={id}
          workOrOfferDataRaw={workOrOfferDataRaw}
          handleSubmit={handleSubmit}
          formValues={formValues}
          handleTextAreaChange={(event) => {
            const { name, value } = event.target;
            let fixedName: string = '';
            if (name.includes('contractorProposal')) {
              fixedName = name.replace('contractorProposal', '');
            } else {
              fixedName = name.replace('customerProposal', '');
            }
            fixedName = 'j' + fixedName.substring(1);
            if (name.includes('contractorProposal')) {
              setFormValues((prevValues) => ({
                ...prevValues,
                contractorProposal: {
                  ...prevValues.contractorProposal,
                  [fixedName]: value,
                },
              }));
            } else {
              setFormValues((prevValues) => ({
                ...prevValues,
                customerProposal: {
                  ...prevValues.customerProposal,
                  [fixedName]: value,
                },
              }));
            }
          }}
          handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const numericValue = event.target.value.replace(/[^0-9.]/g, '');
            const decimalRegex = /^\d+(\.\d{0,2})?$/;
            const { name, value } = event.target;
            let fixedName: string = '';
            let restOfBudjet: number = 0;
            if (value != '') {
              if (isWorkUserBackend(workOrOfferDataRaw)) {
                restOfBudjet = workOrOfferDataRaw.balance! - parseFloat(value);
              } else {
                restOfBudjet =
                  workOrOfferDataRaw.work.balance! - parseFloat(value);
              }
            } else {
              if (isWorkUserBackend(workOrOfferDataRaw)) {
                restOfBudjet = workOrOfferDataRaw.balance!;
              } else {
                restOfBudjet = workOrOfferDataRaw.work.balance!;
              }
            }
            if (name.includes('contractorProposal')) {
              fixedName = name.replace('contractorProposal', '');
              fixedName = 'c' + fixedName.substring(1);
              if (fixedName == 'contractorShare') {
                if (restOfBudjet >= 0) {
                  if (
                    formValues.contractorProposal.contractorShare === '0' &&
                    decimalRegex.test(numericValue)
                  ) {
                    setFormValues((prevValues) => ({
                      ...prevValues,
                      contractorProposal: {
                        ...prevValues.contractorProposal,
                        [fixedName]: numericValue.slice(1),
                      },
                    }));
                    setFormValues((prevValues) => ({
                      ...prevValues,
                      contractorProposal: {
                        ...prevValues.contractorProposal,
                        customerShare: restOfBudjet.toString(),
                      },
                    }));
                  } else if (decimalRegex.test(event.target.value)) {
                    setFormValues((prevValues) => ({
                      ...prevValues,
                      contractorProposal: {
                        ...prevValues.contractorProposal,
                        [fixedName]: value,
                      },
                    }));
                    setFormValues((prevValues) => ({
                      ...prevValues,
                      contractorProposal: {
                        ...prevValues.contractorProposal,
                        customerShare: restOfBudjet.toString(),
                      },
                    }));
                  } else if (
                    formValues.contractorProposal.contractorShare.length ===
                      1 &&
                    event.target.value.length === 0
                  ) {
                    setFormValues((prevValues) => ({
                      ...prevValues,
                      contractorProposal: {
                        ...prevValues.contractorProposal,
                        [fixedName]: '',
                      },
                    }));
                    setFormValues((prevValues) => ({
                      ...prevValues,
                      contractorProposal: {
                        ...prevValues.contractorProposal,
                        customerShare: restOfBudjet.toString(),
                      },
                    }));
                  }
                }
              }
            } else {
              fixedName = name.replace('customerProposal', '');
              fixedName = 'c' + fixedName.substring(1);
              if (fixedName == 'contractorShare') {
                if (restOfBudjet >= 0) {
                  if (
                    formValues.customerProposal.contractorShare === '0' &&
                    decimalRegex.test(numericValue)
                  ) {
                    setFormValues((prevValues) => ({
                      ...prevValues,
                      customerProposal: {
                        ...prevValues.customerProposal,
                        [fixedName]: numericValue.slice(1),
                      },
                    }));
                    setFormValues((prevValues) => ({
                      ...prevValues,
                      customerProposal: {
                        ...prevValues.customerProposal,
                        customerShare: restOfBudjet.toString(),
                      },
                    }));
                  } else if (decimalRegex.test(event.target.value)) {
                    setFormValues((prevValues) => ({
                      ...prevValues,
                      customerProposal: {
                        ...prevValues.customerProposal,
                        [fixedName]: value,
                      },
                    }));
                    setFormValues((prevValues) => ({
                      ...prevValues,
                      customerProposal: {
                        ...prevValues.customerProposal,
                        customerShare: restOfBudjet.toString(),
                      },
                    }));
                  } else if (
                    formValues.customerProposal.contractorShare.length === 1 &&
                    event.target.value.length === 0
                  ) {
                    setFormValues((prevValues) => ({
                      ...prevValues,
                      customerProposal: {
                        ...prevValues.customerProposal,
                        [fixedName]: '',
                      },
                    }));
                    setFormValues((prevValues) => ({
                      ...prevValues,
                      customerProposal: {
                        ...prevValues.customerProposal,
                        customerShare: restOfBudjet.toString(),
                      },
                    }));
                  }
                }
              }
            }
          }}
        />
      )}
    </div>
  );
}

export default ArbitrationWorkContainer;
