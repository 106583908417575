import React, { useState, useEffect } from 'react';
import { makeRequest } from '../services/requests';
import {
  DeadWorkOfferBackend,
  DisapproveUserCompanyFrontend,
  DisapproveUserCompanyBackend,
  DisapproveTortoiseUserCompanyBackend,
} from '../services/BackendFrontendInterfaces';
import DisapproveUserCompanyPresentation from '../components/DisapproveUserCompanyPresentation';

interface DisapproveUserCompanyProps {
  id?: string;
  deadWorkOfferDataRaw: DeadWorkOfferBackend;
}

function DisapproveUserCompanyContainer({
  id = 'DisapproveUserCompanyCompleted',
  deadWorkOfferDataRaw,
}: DisapproveUserCompanyProps) {
  const [loaded, setLoaded] = useState(false);
  const [rate, setRate] = useState<number>(0);
  const [disapproval, setDisapproval] = useState<
    DisapproveTortoiseUserCompanyBackend | undefined
  >(undefined);
  const [formValues, setFormValues] = useState<DisapproveUserCompanyFrontend>({
    summary: '',
    details: '',
    rate: '0',
    scammer: false,
  });

  useEffect(() => {
    setFormValues((prevValues: DisapproveUserCompanyFrontend) => ({
      ...prevValues,
      rate: rate.toString(),
    }));
  }, [rate]);

  const getDisapproval = async () => {
    try {
      let output_message = '';
      let path: string = '';
      if (deadWorkOfferDataRaw.user != null) {
        path = `networking/get_my_user_disapproval/${deadWorkOfferDataRaw.user.id}`;
        output_message = 'Usuario Evaluado Correctamente';
      } else {
        path = `networking/get_my_company_disapproval/${deadWorkOfferDataRaw.company?.id}`;
        output_message = 'Empresa Evaluada Correctamente';
      }
      const method = 'GET';

      const response = await makeRequest({ path: path, method: method });
      if (!response.ok) {
        const response_json = await response.json();
        throw new Error(JSON.stringify(response_json.detail));
      }
      const data = await response.json();
      setDisapproval(data);
    } catch (error: unknown) {
      if (error instanceof Error) {
        alert(error.message);
      } else if (
        typeof error === 'object' &&
        error !== null &&
        'message' in error &&
        'componentStack' in error
      ) {
        const reactError = error as React.ErrorInfo;
        alert(reactError);
      } else {
        alert(JSON.stringify(error));
      }
    }
  };

  useEffect(() => {
    getDisapproval();
  }, []);

  useEffect(() => {
    if (disapproval != null) {
      setFormValues((prevValues) => ({
        ...prevValues,
        summary: disapproval.summary,
        details: disapproval.details,
        rate: disapproval.rate.toString(),
      }));
    }
    setLoaded(true);
  }, [disapproval]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const objToSend: DisapproveUserCompanyBackend = {
        disapproval: {
          summary: formValues.summary,
          details: formValues.details,
          rate: parseInt(formValues.rate),
          scammer: formValues.scammer,
        },
        disapproved:
          deadWorkOfferDataRaw!.user! != null
            ? deadWorkOfferDataRaw!.user!
            : deadWorkOfferDataRaw!.company!,
      };
      let method: string = '';
      let path: string = '';
      let output_message = '';
      if (deadWorkOfferDataRaw.user != null) {
        if (disapproval != null) {
          path = `networking/edit_user_disapproval/disapproval_id/${disapproval.id}`;
          method = 'PATCH';
          output_message = 'Evaluación modificada Correctamente';
        } else {
          path = `networking/disapprove_user`;
          method = 'POST';
          output_message = 'Usuario Evaluado Correctamente';
        }
      } else {
        if (disapproval != null) {
          path = `networking/edit_company_disapproval/disapproval_id/${disapproval.id}`;
          method = 'PATCH';
          output_message = 'Evaluación modificada Correctamente';
        } else {
          path = `networking/disapprove_company`;
          method = 'POST';
          output_message = 'Empresa Evaluada Correctamente';
        }
      }

      const content_type = 'application/json';
      const response = await makeRequest({
        path: path,
        method: method,
        body: JSON.stringify(objToSend),
        contentType: content_type,
      });

      if (!response.ok) {
        const response_json = await response.json();
        throw new Error(JSON.stringify(response_json.detail));
      }

      alert(output_message);
      window.location.reload();
    } catch (error: unknown) {
      if (error instanceof Error) {
        alert(error.message);
      } else if (
        typeof error === 'object' &&
        error !== null &&
        'message' in error &&
        'componentStack' in error
      ) {
        const reactError = error as React.ErrorInfo;
        alert(reactError);
      } else {
        alert(JSON.stringify(error));
      }
    }
  };

  return (
    <DisapproveUserCompanyPresentation
      id={id}
      loaded={loaded}
      disapproval={disapproval}
      deadWorkOfferDataRaw={deadWorkOfferDataRaw}
      handleSubmit={handleSubmit}
      formValues={formValues}
      handleTextAreaChange={(event) => {
        const { name, value } = event.target;
        setFormValues((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
      }}
      handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        if (name == 'rate') {
          const newValue = value.substring(value.length - 1, value.length);
          if (parseInt(newValue) >= 0 && parseInt(newValue) <= 5) {
            setRate(parseInt(newValue));
          }
        } else {
          setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
        }
      }}
      handleCheckboxChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        setFormValues((prevValues) => ({
          ...prevValues,
          scammer: checked,
        }));
      }}
    />
  );
}

export default DisapproveUserCompanyContainer;
