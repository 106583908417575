import {
  LocationBackend,
  LocationCompanyBackend,
  WorkUserBackend,
  WorkOfferBackend,
  SupportFrontend,
  SupportBackend,
  WorkOfferWMercadolibreBackend,
  WorkCompanyBackend,
  WorkCompanyBackendFromAPI,
  ApproveUserCompanyBackend,
  DisapproveUserCompanyBackend,
  UserBackend,
  CompanyBackend,
  DeadWorkOfferBackend,
} from './BackendFrontendInterfaces';

const isLocationBackend = (obj: any): obj is LocationBackend => {
  return (
    typeof obj === 'object' &&
    'outer_number' in obj &&
    'local_currency' in obj &&
    'gps_center_maps_link' in obj &&
    'longitude' in obj &&
    'latitude' in obj &&
    'country' in obj &&
    'state' in obj &&
    'city' in obj &&
    'neighborhood' in obj &&
    'post_code' in obj &&
    'street' in obj &&
    'id' in obj &&
    true
  );
};

const isLocationBackendList = (obj: any): obj is LocationBackend[] => {
  return (
    Array.isArray(obj) &&
    obj.every(
      (item) =>
        typeof item === 'object' &&
        'outer_number' in item &&
        'local_currency' in item &&
        'gps_center_maps_link' in item &&
        'longitude' in item &&
        'latitude' in item &&
        'country' in item &&
        'state' in item &&
        'city' in item &&
        'neighborhood' in item &&
        'post_code' in item &&
        'street' in item &&
        'id' in item &&
        true,
    )
  );
};

const isCompanyBackend = (obj: any): obj is CompanyBackend => {
  return (
    typeof obj === 'object' &&
    'company_name' in obj &&
    'company_types' in obj &&
    'company_phones' in obj &&
    'rfc' in obj &&
    'rate_approved' in obj &&
    'approved_by' in obj &&
    'rate_disapproved' in obj &&
    'disapproved_by' in obj &&
    true
  );
};

const isCompanyBackendList = (obj: any): obj is CompanyBackend[] => {
  return (
    Array.isArray(obj) &&
    obj.every(
      (item) =>
        typeof item === 'object' &&
        'company_name' in item &&
        'company_types' in item &&
        'company_phones' in item &&
        'rfc' in item &&
        'rate_approved' in item &&
        'approved_by' in item &&
        'rate_disapproved' in item &&
        'disapproved_by' in item &&
        true,
    )
  );
};

const isUserBackend = (obj: any): obj is UserBackend => {
  return (
    typeof obj === 'object' &&
    'email' in obj &&
    'sex' in obj &&
    'link_cedule' in obj &&
    'worker_type' in obj &&
    'first_name' in obj &&
    'second_name' in obj &&
    'first_lastname' in obj &&
    'cell_phones' in obj &&
    'curp' in obj &&
    'rfc' in obj &&
    'rate_approved' in obj &&
    'approved_by' in obj &&
    'rate_disapproved' in obj &&
    'disapproved_by' in obj &&
    'id' in obj &&
    true
  );
};

const isUserBackendList = (obj: any): obj is UserBackend[] => {
  return (
    Array.isArray(obj) &&
    obj.every(
      (item) =>
        typeof item === 'object' &&
        'email' in obj &&
        'sex' in obj &&
        'link_cedule' in obj &&
        'worker_type' in obj &&
        'first_name' in obj &&
        'second_name' in obj &&
        'first_lastname' in obj &&
        'cell_phones' in obj &&
        'curp' in obj &&
        'rfc' in obj &&
        'rate_approved' in obj &&
        'approved_by' in obj &&
        'rate_disapproved' in obj &&
        'disapproved_by' in obj &&
        'id' in obj &&
        true,
    )
  );
};

const isLocationCompanyBackend = (obj: any): obj is LocationCompanyBackend => {
  return (
    typeof obj === 'object' && 'company' in obj && 'locations' in obj && true
  );
};

const isLocationCompanyBackendList = (
  obj: any,
): obj is LocationCompanyBackend[] => {
  return (
    Array.isArray(obj) &&
    obj.every(
      (item) =>
        typeof item === 'object' && 'company' in item && 'locations' in item,
    )
  );
};

const isWorkUserBackend = (obj: any): obj is WorkUserBackend => {
  return (
    typeof obj === 'object' &&
    'summary' in obj &&
    'work_type' in obj &&
    'location' in obj &&
    'work_budget' in obj &&
    'start_date_time' in obj &&
    'end_date_time' in obj &&
    'taken_by' in obj &&
    'work_type' in obj &&
    'id' in obj &&
    true
  );
};

const isWorkUserBackendList = (obj: any): obj is WorkUserBackend[] => {
  return (
    Array.isArray(obj) &&
    obj.every(
      (item) =>
        typeof item === 'object' &&
        'summary' in item &&
        'work_type' in item &&
        'location' in item &&
        'work_budget' in item &&
        'start_date_time' in item &&
        'end_date_time' in item &&
        'taken_by' in item &&
        'work_type' in item &&
        'id' in item &&
        true,
    )
  );
};

const isWorkCompanyBackendFromAPI = (
  obj: any,
): obj is WorkCompanyBackendFromAPI => {
  return (
    typeof obj === 'object' && 'work' in obj && 'company_id' in obj && true
  );
};

const isWorkCompanyBackendFromAPIList = (
  obj: any,
): obj is WorkCompanyBackendFromAPI[] => {
  return (
    Array.isArray(obj) &&
    obj.every(
      (item) =>
        typeof item === 'object' &&
        'work' in obj &&
        'company_id' in obj &&
        true,
    )
  );
};

const isWorkCompanyBackend = (obj: any): obj is WorkCompanyBackend => {
  return (
    typeof obj === 'object' &&
    'work' in obj &&
    'company' in obj &&
    'id' in obj &&
    true
  );
};

const isWorkCompanyBackendList = (obj: any): obj is WorkCompanyBackend[] => {
  return (
    Array.isArray(obj) &&
    obj.every(
      (item) =>
        typeof item === 'object' &&
        'work' in item &&
        'company' in item &&
        'id' in item &&
        true,
    )
  );
};

const isWorkOfferBackend = (obj: any): obj is WorkOfferBackend => {
  return (
    typeof obj === 'object' &&
    'work' in obj &&
    'labor_budget' in obj &&
    'start_date_time' in obj &&
    'end_date_time' in obj &&
    'external_works' in obj &&
    'tools' in obj &&
    'materials' in obj &&
    'id' in obj &&
    true
  );
};

const isWorkOfferWMercadolibreBackend = (
  obj: any,
): obj is WorkOfferWMercadolibreBackend => {
  return (
    typeof obj === 'object' && 'offer' in obj && 'preference_id' in obj && true
  );
};

const isDeadWorkOfferBackend = (obj: any): obj is DeadWorkOfferBackend => {
  return (
    typeof obj === 'object' &&
    'dead_work' in obj &&
    'labor_budget' in obj &&
    'start_date_time' in obj &&
    'end_date_time' in obj &&
    'external_works' in obj &&
    'tools' in obj &&
    'materials' in obj &&
    'evaluation' &&
    'id' in obj &&
    true
  );
};

const isWorkOfferBackendList = (obj: any): obj is WorkOfferBackend[] => {
  return (
    Array.isArray(obj) &&
    obj.every(
      (item) =>
        typeof item === 'object' &&
        'work' in item &&
        'labor_budget' in item &&
        'start_date_time' in item &&
        'end_date_time' in item &&
        'external_works' in item &&
        'tools' in item &&
        'materials' in item &&
        'id' in item &&
        true,
    )
  );
};

const isSupportFrontend = (obj: any): obj is SupportFrontend => {
  return (
    typeof obj === 'object' &&
    'createdDate' in obj &&
    'resolutionDate' in obj &&
    'request' in obj &&
    'resolution' in obj &&
    'id' in obj &&
    true
  );
};

const isSupportBackend = (obj: any): obj is SupportBackend => {
  return (
    typeof obj === 'object' &&
    'created_date' in obj &&
    'resolution_date' in obj &&
    'request' in obj &&
    'resolution' in obj &&
    'id' in obj &&
    true
  );
};

const isSupportBackendList = (obj: any): obj is SupportBackend[] => {
  return (
    Array.isArray(obj) &&
    obj.every(
      (item) =>
        typeof item === 'object' &&
        'created_date' in item &&
        'resolution_date' in item &&
        'request' in item &&
        'resolution' in item &&
        'id' in item &&
        true,
    )
  );
};

const isApproveUserCompanyBackend = (
  obj: any,
): obj is ApproveUserCompanyBackend => {
  return (
    typeof obj === 'object' &&
    'approval' in obj &&
    'approved' in obj &&
    'id' in obj &&
    true
  );
};

const isApproveUserCompanyBackendList = (
  obj: any,
): obj is ApproveUserCompanyBackend[] => {
  return (
    Array.isArray(obj) &&
    obj.every(
      (item) =>
        typeof item === 'object' &&
        'approval' in item &&
        'approved' in item &&
        'id' in item &&
        true,
    )
  );
};

const isDisapproveUserCompanyBackend = (
  obj: any,
): obj is DisapproveUserCompanyBackend => {
  return (
    typeof obj === 'object' &&
    'disapproval' in obj &&
    'disapproved' in obj &&
    'id' in obj &&
    true
  );
};

const isDisapproveUserCompanyBackendList = (
  obj: any,
): obj is DisapproveUserCompanyBackend[] => {
  return (
    Array.isArray(obj) &&
    obj.every(
      (item) =>
        typeof item === 'object' &&
        'disapproval' in item &&
        'disapproved' in item &&
        'id' in item &&
        true,
    )
  );
};

const isWorkOfferWMercadolibreBackendList = (
  obj: any,
): obj is WorkOfferBackend[] => {
  return (
    Array.isArray(obj) &&
    obj.every(
      (item) =>
        typeof item === 'object' &&
        'offer' in item &&
        'preference_id' in item &&
        true,
    )
  );
};

const isDeadWorkOfferBackendList = (obj: any): obj is WorkOfferBackend[] => {
  return (
    Array.isArray(obj) &&
    obj.every(
      (item) =>
        typeof item === 'object' &&
        'dead_work' in item &&
        'labor_budget' in item &&
        'start_date_time' in item &&
        'end_date_time' in item &&
        'external_works' in item &&
        'tools' in item &&
        'materials' in item &&
        'evaluation' &&
        'id' in item &&
        true,
    )
  );
};

export {
  isLocationBackend,
  isLocationBackendList,
  isLocationCompanyBackend,
  isWorkUserBackend,
  isWorkUserBackendList,
  isLocationCompanyBackendList,
  isWorkOfferBackend,
  isSupportFrontend,
  isSupportBackend,
  isSupportBackendList,
  isWorkOfferWMercadolibreBackend,
  isWorkOfferWMercadolibreBackendList,
  isDeadWorkOfferBackend,
  isWorkOfferBackendList,
  isDeadWorkOfferBackendList,
  isWorkCompanyBackend,
  isWorkCompanyBackendList,
  isWorkCompanyBackendFromAPI,
  isWorkCompanyBackendFromAPIList,
  isApproveUserCompanyBackend,
  isApproveUserCompanyBackendList,
  isDisapproveUserCompanyBackend,
  isDisapproveUserCompanyBackendList,
  isCompanyBackend,
  isCompanyBackendList,
  isUserBackend,
  isUserBackendList,
};
