import { useEffect, useState, ChangeEvent } from 'react';
import AutocompletePresentation from '../components/AutocompletePresentation';

/**
 *
 */

interface AutocompleteContainerProps {
  autocompleteTitle: string;
  id: string;
  name?: string | null;
  options: string[];
  onChange: (value: { name: string | null; value: string[] }) => void;
  initialValues?: string[];
  readOnly?: boolean;
  multipleOptions?: boolean;
  acceptNew?: boolean;
  required?: boolean;
}

function AutocompleteContainer({
  id = 'Autocomplete',
  autocompleteTitle,
  name = null,
  options,
  onChange,
  initialValues,
  readOnly = false,
  multipleOptions = true,
  acceptNew = false,
  required = false,
}: AutocompleteContainerProps) {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [suggestedOptions, setSuggestedOptions] = useState<string[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    if (initialValues) {
      setSelectedOptions(initialValues);
      setInitialized(true);
    } else {
      setInitialized(true);
    }
  }, [initialValues]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleOptionClick = (option: string) => {
    const ret_val = {
      name: name,
      value: [] as string[],
    };

    if (multipleOptions) {
      if (!selectedOptions.includes(option)) {
        ret_val.value = [...selectedOptions, option];
      } else {
        ret_val.value = [...selectedOptions];
      }
    } else {
      ret_val.value = [option];
    }

    setSelectedOptions(ret_val.value);
    setSearchTerm('');
    onChange(ret_val);
  };

  const handleNewOptionSubmit = (newOption: string) => {
    handleOptionClick(newOption);
  };

  const handleSelectedOptionRemove = (option: string) => {
    const updatedOptions = selectedOptions.filter((o) => o !== option);
    const ret_val = {
      name: name,
      value: updatedOptions,
    };

    setSelectedOptions(updatedOptions);
    onChange(ret_val);
  };

  useEffect(() => {
    if (searchTerm.length > 0) {
      const filteredOptions = options.filter((option) =>
        option.toLowerCase().includes(searchTerm.toLowerCase()),
      );
      setSuggestedOptions(filteredOptions);
    } else {
      setSuggestedOptions([]);
    }
  }, [options, searchTerm]);

  useEffect(() => {
    if (required && initialized && selectedOptions.length === 0) {
      setError('Favor De LLenar Este Campo.');
    } else {
      setError('');
    }
  }, [required, initialized, selectedOptions]);

  return (
    <AutocompletePresentation
      autocompleteTitle={autocompleteTitle}
      id={id}
      acceptNew={acceptNew}
      handleNewOptionSubmit={handleNewOptionSubmit}
      selectedOptions={selectedOptions}
      searchTerm={searchTerm}
      suggestedOptions={suggestedOptions}
      error={error}
      readOnly={readOnly}
      handleInputChange={handleInputChange}
      handleOptionClick={handleOptionClick}
      handleSelectedOptionRemove={handleSelectedOptionRemove}
    />
  );
}

export default AutocompleteContainer;
