import GridCard from '../components/GridCard';
import { CardsGridCaller } from '../components/GridCard';
import React, { useState, useEffect, useContext } from 'react';
import { makeRequest } from '../services/requests';
import ReviewMyPublishedWorksPresentation from '../components/ReviewMyPublishedWorksPresentation';
import {
  WorkUserBackend,
  WorkOfferBackend,
  WorkCompanyBackend,
  WorkOfferWMercadolibreBackend,
} from '../services/BackendFrontendInterfaces';
import {
  isWorkUserBackend,
  isWorkCompanyBackend,
} from '../services/InterfaceTypeValidator';
import PublishWorkContainer from './PublishWorkContainer';
import PublishWorkForMyCompanyContainer from './PublishWorkForMyCompanyContainer';
import { SessionContext } from '../services/SessionContextProvider';
import EvaluateWorkContainer from './EvaluateWorkContainer';
import ArbitrationWorkContainer from './ArbitrationContainer';
import Payment from '../components/Payment';

interface ReviewMyPublishedWorksProps {
  id?: CardsGridCaller;
  buttonLabel?: string;
}

function ReviewMyPublishedWorksContainer({
  id = 'ReviewMyPublishedWorks',
  buttonLabel,
}: ReviewMyPublishedWorksProps) {
  const [offerToAccept, setOfferToAccept] =
    useState<WorkOfferWMercadolibreBackend | null>(null);
  const [worksDataRaw, setWorksDataRaw] = useState<WorkUserBackend[] | null>(
    null,
  );
  const [worksToEditDelete, setWorksToEditDelete] = useState<
    WorkUserBackend | WorkCompanyBackend | null
  >(null);
  const [workToComplete, setWorkToComplete] = useState<WorkUserBackend | null>(
    null,
  );
  const [workToArbitrate, setWorkToArbitrate] =
    useState<WorkUserBackend | null>(null);
  const [offersForThisJob, setOffersForThisJob] = useState<
    WorkOfferBackend[] | null
  >(null);
  const { offersForMyWorksCache, setOffersForMyWorksCache } =
    useContext(SessionContext);

  const workEditDeleteOrCloseWork = (cardData: WorkUserBackend) => {
    if (cardData.taken_by == null) {
      setWorksToEditDelete(cardData);
    } else {
      setWorkToComplete(cardData);
    }
  };

  const acceptOffer = async (cardData: WorkOfferBackend) => {
    try {
      const path = `offers/accept_offer_to_work`;
      const method = 'POST';

      const content_type = 'application/json';
      const response = await makeRequest({
        path: path,
        method: method,
        body: JSON.stringify(cardData),
        contentType: content_type,
      });

      if (!response.ok) {
        const response_json = await response.json();
        throw new Error(JSON.stringify(response_json.detail));
      }

      const data = await response.json();
      setOfferToAccept(data);
    } catch (error: unknown) {
      if (error instanceof Error) {
        alert(error.message);
      } else if (
        typeof error === 'object' &&
        error !== null &&
        'message' in error &&
        'componentStack' in error
      ) {
        const reactError = error as React.ErrorInfo;
        alert(reactError);
      } else {
        alert(JSON.stringify(error));
      }
    }
  };

  const viewOffersOrArbitrationRequest = async (cardData: WorkUserBackend) => {
    if (cardData.taken_by != null) {
      setWorkToArbitrate(cardData);
    } else {
      let offersData: WorkOfferBackend[] | null = offersForMyWorksCache;
      if (offersForMyWorksCache == null) {
        const path = `offers/get_offers_for_my_works`;
        const method = 'GET';
        try {
          const response = await makeRequest({ path: path, method: method });
          const data: WorkOfferBackend[] = await response.json();
          setOffersForMyWorksCache(data);
          offersData = data;
        } catch (error: unknown) {
          if (error instanceof Error) {
            alert(error.message);
          } else if (
            typeof error === 'object' &&
            error !== null &&
            'message' in error &&
            'componentStack' in error
          ) {
            const reactError = error as React.ErrorInfo;
            alert(reactError);
          } else {
            alert(JSON.stringify(error));
          }
        }
      }
      const offersForThisJob = offersData?.filter(
        (offer) => cardData.id == offer.work.id,
      );
      setOffersForThisJob(offersForThisJob != null ? offersForThisJob : null);
    }
  };

  const workDelete = async () => {
    const path = `works/delete_my_work/work_id/${worksToEditDelete!.id}`;
    const method = 'DELETE';
    try {
      const response = await makeRequest({ path: path, method: method });
      const data: WorkUserBackend[] = await response.json();
      // Mostrar una notificación de éxito
      alert('Trabajo Borrado Correctamente');
      // Recargar el componente
      window.location.reload();
    } catch (error: unknown) {
      if (error instanceof Error) {
        alert(error.message);
      } else if (
        typeof error === 'object' &&
        error !== null &&
        'message' in error &&
        'componentStack' in error
      ) {
        const reactError = error as React.ErrorInfo;
        alert(reactError);
      } else {
        alert(JSON.stringify(error));
      }
    }
  };

  useEffect(() => {
    async function fetchData() {
      const path = `works/get_my_published_works/`;
      const method = 'GET';
      try {
        const response = await makeRequest({ path: path, method: method });
        const data: WorkUserBackend[] = await response.json();
        setWorksDataRaw(data);
      } catch (error: unknown) {
        if (error instanceof Error) {
          alert(error.message);
        } else if (
          typeof error === 'object' &&
          error !== null &&
          'message' in error &&
          'componentStack' in error
        ) {
          const reactError = error as React.ErrorInfo;
          alert(reactError);
        } else {
          alert(JSON.stringify(error));
        }
      }
    }
    fetchData();
  }, [id]);
  if (offerToAccept != null) {
    return <Payment paymentData={offerToAccept} />;
  } else if (workToArbitrate != null) {
    return <ArbitrationWorkContainer workOrOfferDataRaw={workToArbitrate} />;
  } else if (workToComplete != null) {
    return <EvaluateWorkContainer workDataRaw={workToComplete} />;
  } else if (offersForThisJob != null) {
    return (
      <GridCard
        cardsData={offersForThisJob}
        handleButtonCardOne={acceptOffer}
        buttonLabelCardOne={'Aceptar Oferta'}
        id={id}
      />
    );
  } else if (worksDataRaw === null) {
    return <h1>Cargando...</h1>;
  } else if (worksToEditDelete == null) {
    return (
      <ReviewMyPublishedWorksPresentation
        id={id}
        worksDataRaw={worksDataRaw!}
        handleButtonCardOne={workEditDeleteOrCloseWork}
        handleButtonCardTwo={viewOffersOrArbitrationRequest}
        buttonLabelCardOne={(cardData: WorkUserBackend) => {
          if (cardData.taken_by == null) {
            return 'Borrar/Editar Trabajo';
          } else {
            return 'Trabajo Completado';
          }
        }}
        buttonLabelCardTwo={(cardData: WorkUserBackend) => {
          if (cardData.taken_by == null) {
            return 'Revisar Ofertas';
          } else {
            return 'Solicitar Arbitraje';
          }
        }}
      />
    );
  } else if (isWorkUserBackend(worksToEditDelete)) {
    return (
      <PublishWorkContainer
        id={id}
        workDataRaw={worksToEditDelete}
        mode={'Edit'}
        buttonLabel={buttonLabel != null ? buttonLabel : 'Guardar Cambios'}
        workDelete={workDelete}
      />
    );
  } else if (isWorkCompanyBackend(worksToEditDelete)) {
    return (
      <PublishWorkForMyCompanyContainer
        id={id}
        workDataRaw={[worksToEditDelete]}
        mode={'Edit'}
        buttonLabel={buttonLabel != null ? buttonLabel : 'Guardar Cambios'}
      />
    );
  } else {
    return <h1>No se encontró información...</h1>;
  }
}

export default ReviewMyPublishedWorksContainer;
