import {
  WorkUserBackend,
  WorkCompanyBackendFromAPI,
  ArbitrationFrontend,
  WorkOfferBackend,
} from '../services/BackendFrontendInterfaces';
import {
  isWorkUserBackend,
  isWorkCompanyBackendFromAPI,
} from '../services/InterfaceTypeValidator';

interface ArbitrationPresentationProps {
  id?: string;
  workOrOfferDataRaw:
    | WorkUserBackend
    | WorkCompanyBackendFromAPI
    | WorkOfferBackend;
  handleSubmit: (event: React.FormEvent) => void;
  formValues: Omit<ArbitrationFrontend, 'work'>;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleTextAreaChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}
function ArbitrationPresentation({
  id = 'Arbitration',
  workOrOfferDataRaw,
  handleSubmit,
  formValues,
  handleChange,
  handleTextAreaChange,
}: ArbitrationPresentationProps) {
  return (
    <form onSubmit={handleSubmit} id={`Form${id}`}>
      <h1>
        {isWorkUserBackend(workOrOfferDataRaw)
          ? `Presupuesto Restante: ${workOrOfferDataRaw.balance}`
          : `Presupuesto Restante: ${workOrOfferDataRaw.work.balance}`}
      </h1>
      <h2>Propuesta Del Cliente</h2>
      <label
        htmlFor={`input${id}customerProposalCustomerShare`}
        id={`label${id}customerProposalCustomerShare`}
      >
        Rembolso Para El Cliente:
      </label>
      <br />
      <input
        id={`input${id}customerProposalCustomerShare`}
        name="customerProposalCustomerShare"
        type="text"
        disabled={true}
        value={formValues.customerProposal.customerShare}
        onChange={handleChange}
      />
      <br />
      <label
        htmlFor={`input${id}customerProposalContractorShare`}
        id={`label${id}customerProposalContractorShare`}
      >
        Pago Para Contratista:
      </label>
      <br />
      <input
        id={`input${id}customerProposalContractorShare`}
        name="customerProposalContractorShare"
        type="text"
        disabled={
          isWorkUserBackend(workOrOfferDataRaw) ||
          isWorkCompanyBackendFromAPI(workOrOfferDataRaw)
            ? false
            : true
        }
        value={formValues.customerProposal.contractorShare}
        onChange={handleChange}
      />
      <br />
      <br />
      <label
        htmlFor={`input${id}customerProposalJustification`}
        id={`label${id}customerProposalJustification`}
      >
        Justificación De La Propuesta:
      </label>
      <br />
      <textarea
        id={`input${id}customerProposalJustification`}
        name="customerProposalJustification"
        disabled={
          isWorkUserBackend(workOrOfferDataRaw) ||
          isWorkCompanyBackendFromAPI(workOrOfferDataRaw)
            ? false
            : true
        }
        required
        value={formValues.customerProposal.justification}
        onChange={handleTextAreaChange}
        rows={8}
        style={{ width: '60%' }}
      />
      <br />
      <h2>Propuesta Del Contratista</h2>
      <label
        htmlFor={`input${id}contractorProposalCustomerShare`}
        id={`label${id}contractorProposalCustomerShare`}
      >
        Rembolso Para El Cliente:
      </label>
      <br />
      <input
        id={`input${id}contractorProposalCustomerShare`}
        name="contractorProposalCustomerShare"
        type="text"
        disabled={true}
        value={formValues.contractorProposal.customerShare}
        onChange={handleChange}
      />
      <br />
      <label
        htmlFor={`input${id}contractorProposalContractorShare`}
        id={`label${id}contractorProposalContractorShare`}
      >
        Pago Para Contratista:
      </label>
      <br />
      <input
        id={`input${id}contractorProposalContractorShare`}
        name="contractorProposalContractorShare"
        type="text"
        disabled={
          isWorkUserBackend(workOrOfferDataRaw) ||
          isWorkCompanyBackendFromAPI(workOrOfferDataRaw)
            ? true
            : false
        }
        value={formValues.contractorProposal.contractorShare}
        onChange={handleChange}
      />
      <br />
      <br />
      <label
        htmlFor={`input${id}contractorProposalJustification`}
        id={`label${id}contractorProposalJustification`}
      >
        Justificación De La Propuesta:
      </label>
      <br />
      <textarea
        id={`input${id}contractorProposalJustification`}
        name="contractorProposalJustification"
        disabled={
          isWorkUserBackend(workOrOfferDataRaw) ||
          isWorkCompanyBackendFromAPI(workOrOfferDataRaw)
            ? true
            : false
        }
        required
        value={formValues.contractorProposal.justification}
        onChange={handleTextAreaChange}
        rows={8}
        style={{ width: '60%' }}
      />
      <br />
      <button id={`button${id}SubmitArbitration`} type="submit">
        {'Enviar Propuesta'}
      </button>
    </form>
  );
}

export default ArbitrationPresentation;
