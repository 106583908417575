import React from 'react';

interface LoginPresentationProps {
  id?: string;
  curp: string;
  password: string;
  onCurpChange: (curp: string) => void;
  onPasswordChange: (password: string) => void;
  onSubmit: (event: React.FormEvent) => void;
}

const LoginPresentation: React.FC<LoginPresentationProps> = ({
  id = 'Login',
  curp,
  password,
  onCurpChange,
  onPasswordChange,
  onSubmit,
}) => {
  return (
    <form onSubmit={onSubmit} id={`Form${id}`}>
      <label htmlFor={`input${id}Curp`} id={`label${id}Curp`}>
        Curp:
      </label>
      <input
        id={`input${id}Curp`}
        type="text"
        value={curp}
        onChange={(e) => onCurpChange(e.target.value.toUpperCase())}
      />
      <label htmlFor={`input${id}Password`} id={`label${id}Password`}>
        Password:
      </label>
      <input
        id={`input${id}Password`}
        type="password"
        value={password}
        onChange={(e) => onPasswordChange(e.target.value)}
      />
      <button id={`button${id}`} type="submit">
        Login
      </button>
    </form>
  );
};

export default LoginPresentation;
