import React from 'react';
import { WorkOfferFrontend } from '../services/BackendFrontendInterfaces';
import CurrencyInputContainer from '../containers/CurrencyInputContainer';

interface MaterialsRegisterProps {
  readOnly?: boolean;
  id: string;
  materialsNumber: number;
  materials: WorkOfferFrontend['materials'];
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleMaterialChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    key: string,
  ) => void;
}

function MaterialsPresentation({
  readOnly = false,
  id = 'materialsRegister',
  materialsNumber,
  materials,
  handleChange,
  handleMaterialChange,
}: MaterialsRegisterProps) {
  const renderTextInputs = () => {
    const inputs = [];
    for (let i = 0; i < materialsNumber; i++) {
      inputs.push(
        <div key={i}>
          <label htmlFor={`input${id}Number${i}`} id={`label${id}Number${i}`}>
            Material:
          </label>
          <input
            readOnly={readOnly}
            id={`input${id}Number${i}`}
            type="text"
            value={materials[i].material}
            onChange={(e) => handleMaterialChange(e, i, 'material')}
          />
          <CurrencyInputContainer
            id={`CurrencyInput${id}Budget${i}`}
            name="offer_budget"
            label="Presupuesto"
            required={true}
            readOnly={readOnly}
            value={materials[i].materialBudget}
            onChange={(e) => handleMaterialChange(e, i, 'materialBudget')}
          />
        </div>,
      );
    }
    return inputs;
  };

  return (
    <div>
      <label
        htmlFor={`input${id}NumberMaterials`}
        id={`label${id}NumberMaterials`}
      >
        Materiales Requeridos (Refacciones, Pinturas, Cable, Etc.)
      </label>
      <input
        readOnly={readOnly}
        id={`input${id}NumberMaterials`}
        type="number"
        min={1}
        max={20}
        value={materialsNumber}
        onChange={handleChange}
      />
      {renderTextInputs()}
    </div>
  );
}

export default MaterialsPresentation;
