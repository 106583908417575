import React from 'react';

interface MapProps extends google.maps.MapOptions {
  style: { [key: string]: string };
  children?: React.ReactNode;
  mapRef: React.RefObject<HTMLDivElement>;
  map: google.maps.Map | null;
}

const MapWWrapperPresentation: React.FC<MapProps> = ({
  children,
  style,
  mapRef,
  map,
}) => {
  return (
    <>
      <div ref={mapRef} style={style} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          // @ts-ignore
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
};

export default MapWWrapperPresentation;
