import GridCard from './GridCard';
import { DisapproveUserCompanyBackend } from '../services/BackendFrontendInterfaces';
import { CardsGridCaller } from './GridCard';

interface MyDisapprovalsPresentationProps {
  id: CardsGridCaller;
  disapprovalsDataRaw: DisapproveUserCompanyBackend[];
}

function MyDisapprovalsPresentation({
  id = 'myDisapprovals',
  disapprovalsDataRaw = [],
}: MyDisapprovalsPresentationProps) {
  return disapprovalsDataRaw?.length > 0 ? (
    <div>
      {disapprovalsDataRaw.length > 0 ? (
        <GridCard cardsData={disapprovalsDataRaw} id={`Grid${id}`} />
      ) : (
        <div>No se encontraron Calificaciones Negativas</div>
      )}
    </div>
  ) : (
    <h2>Cargando...</h2>
  );
}

export default MyDisapprovalsPresentation;
