import React, { useState, useEffect, useContext } from 'react';
import { makeRequest } from '../services/requests';
import MyCompaniesCompletedOffersPresentation from '../components/MyCompaniesCompletedOffersPresentation';
import { CardsGridCaller } from '../components/GridCard';
import {
  DeadWorkOfferBackend,
  CompanyBackend,
} from '../services/BackendFrontendInterfaces';
import { SessionContext } from '../services/SessionContextProvider';

interface MyCompaniesCompletedOffersProps {
  id?: CardsGridCaller;
}

function MyCompaniesCompletedOffersContainer({
  id = 'myCompaniesDeadOffers',
}: MyCompaniesCompletedOffersProps) {
  const { myCompaniesBackendCache, setMyCompaniesBackendCache } =
    useContext(SessionContext);

  const [selectedCompanyID, setSelectedCompanyID] = useState<number>(0);
  const [companiesRFCList, setCompaniesRFCList] = useState<string[]>([]);
  const [myCompaniesBackendList, setMyCompaniesBackendList] = useState<
    CompanyBackend[]
  >([]);
  const [offersDataRaw, setOffersDataRaw] = useState<DeadWorkOfferBackend[]>(
    [],
  );

  const getMyCompanyCompletedOffers = () => {
    async function fetchData() {
      const path = `offers/get_my_company_completed_offers/company_id/${selectedCompanyID}`;
      const method = 'GET';
      try {
        const response = await makeRequest({ path: path, method: method });
        const data: DeadWorkOfferBackend[] = await response.json();
        setOffersDataRaw(data);
      } catch (error: unknown) {
        if (error instanceof Error) {
          alert(error.message);
        } else if (
          typeof error === 'object' &&
          error !== null &&
          'message' in error &&
          'componentStack' in error
        ) {
          const reactError = error as React.ErrorInfo;
          alert(reactError);
        } else {
          alert(JSON.stringify(error));
        }
      }
    }
    if (selectedCompanyID > 0) {
      fetchData();
    }
  };

  const findCompanyByRFC = (rfc: string) => {
    return myCompaniesBackendList.find((company) => company.rfc === rfc)!;
  };

  const fetchCompaniesData = async () => {
    try {
      const path = `company/get_my_companies`;
      const method = 'GET';
      let data = myCompaniesBackendCache;
      if (data == null) {
        const response = await makeRequest({ path: path, method: method });
        data = await response.json();
        setMyCompaniesBackendCache(data);
      }
      if (data != null) {
        setMyCompaniesBackendList(data);
      }
      setSelectedCompanyID(data![0].id!);
    } catch (error: unknown) {
      if (error instanceof Error) {
        alert(error.message);
      } else if (
        typeof error === 'object' &&
        error !== null &&
        'message' in error &&
        'componentStack' in error
      ) {
        const reactError = error as React.ErrorInfo;
        alert(reactError);
      } else {
        alert(JSON.stringify(error));
      }
    }
  };

  const handleSelectChangeCompany = (
    selectedOption: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setSelectedCompanyID(findCompanyByRFC(selectedOption.target.value).id!);
  };

  useEffect(() => {
    fetchCompaniesData();
  }, []);

  useEffect(() => {
    getMyCompanyCompletedOffers();
  }, [selectedCompanyID]);

  useEffect(() => {
    if (myCompaniesBackendList.length > 0) {
      setCompaniesRFCList(myCompaniesBackendList.map((obj) => obj.rfc));
    }
  }, [myCompaniesBackendList]);

  if (myCompaniesBackendCache?.length == 0) {
    return <h1>No Se Encontraron Empresas Registradas</h1>;
  } else {
    return (
      <MyCompaniesCompletedOffersPresentation
        id={id}
        offersDataRaw={offersDataRaw}
        companiesList={companiesRFCList}
        handleSelectChangeCompany={handleSelectChangeCompany}
      />
    );
  }
}

export default MyCompaniesCompletedOffersContainer;
