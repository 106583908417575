import { ChangeEvent, useState } from 'react';
import CurrencyInputPresentation from '../components/CurrencyInputPresentation';
import { CurrencyInputProps } from '../services/CurrencyInputServices';

const CurrencyInputContainer: React.FC<CurrencyInputProps> = (props) => {
  const [value, setValue] = useState(props.value);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/[^0-9.]/g, '');
    const decimalRegex = /^\d+(\.\d{0,2})?$/;
    if (value === '0' && decimalRegex.test(numericValue)) {
      const simulatedEvent = {
        target: {
          value: numericValue,
        },
      } as React.ChangeEvent<HTMLInputElement>;
      setValue(numericValue.slice(1));
      props.onChange(simulatedEvent);
    } else if (decimalRegex.test(inputValue)) {
      setValue(numericValue);
      const simulatedEvent = {
        target: {
          value: numericValue,
        },
      } as React.ChangeEvent<HTMLInputElement>;
      props.onChange(simulatedEvent);
    } else if (value.length === 1 && inputValue.length === 0) {
      const simulatedEvent = {
        target: {
          value: '0',
        },
      } as React.ChangeEvent<HTMLInputElement>;
      setValue('');
      props.onChange(simulatedEvent);
    }
  };

  return (
    <CurrencyInputPresentation
      {...props}
      value={value}
      onChange={handleChange}
    />
  );
};

export default CurrencyInputContainer;
