import React, { MouseEventHandler, useContext } from 'react';
import CurrencyInputContainer from '../containers/CurrencyInputContainer';
import TreeViewContainer from '../containers/TreeViewContainer';
import { Category, TreeContainerProps } from '../services/TreeView';
import {
  WorkUserFrontend,
  LocationFrontend,
  WorkUserBackend,
  LocationBackend,
  FormDataModes,
  WorkCompanyBackendFromAPI,
} from '../services/BackendFrontendInterfaces';
import LocationFormContainer from '../containers/LocationFormContainer';
import {
  OfferOption,
  LocationSelectionOption,
} from '../containers/PublishWorkContainer';
import GridCard from './GridCard';
import { isWorkUserBackend } from '../services/InterfaceTypeValidator';
import { SessionContext } from '../services/SessionContextProvider';

interface PresentationProps {
  workDataRaw?: WorkUserBackend | WorkCompanyBackendFromAPI;
  id?: string;
  mode?: FormDataModes['value'];
  innerReadOnly?: boolean;
  formValues: WorkUserFrontend;
  showForm: boolean;
  treeCategories: Category;
  setSelectedStartDateTime: (date: string) => void;
  setSelectedEndDateTime: (date: string) => void;
  handleTextAreaChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleChangeLocation: (locationData: LocationFrontend) => void;
  handleCurrencyChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (event: React.FormEvent) => void;
  treeHandler: TreeContainerProps['treeHandler'];
  openPopup: MouseEventHandler<HTMLButtonElement>;
  showPopup: boolean;
  selectOption: (opcion: OfferOption) => void;
  selectedOption: OfferOption | null;
  locationSelectionOption?: LocationSelectionOption;
  handleLocationSelectionOption?: (
    optionSelected: LocationSelectionOption,
  ) => void;
  locationsDataRaw?: LocationBackend[];
  handleButtonCard?: (cardData: LocationBackend) => void;
  workDelete?: (event: React.MouseEvent) => void;
  buttonLabel?: string;
}

const PublishWorkPresentation: React.FC<PresentationProps> = ({
  workDataRaw,
  id = 'PublishWorkForMe',
  mode,
  innerReadOnly = false,
  formValues,
  showForm,
  treeCategories = {},
  setSelectedStartDateTime,
  setSelectedEndDateTime,
  handleTextAreaChange,
  handleChangeLocation,
  handleCurrencyChange,
  handleSubmit,
  treeHandler,
  openPopup,
  showPopup,
  selectOption,
  locationSelectionOption = null,
  handleLocationSelectionOption = null,
  locationsDataRaw = [],
  handleButtonCard,
  workDelete,
  buttonLabel,
}) => {
  const { publishWorkChatbotCity, setPublishWorkChatbotCity } =
    useContext(SessionContext);
  const handleChangeChatbotPostCode = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPublishWorkChatbotCity(event.target.value);
  };

  const createButtons = () => {
    if (
      handleLocationSelectionOption != null &&
      !id.includes('notTakenWorks')
    ) {
      const threeButtonsRender: React.ReactNode = (
        <div>
          <button
            id={`button${id}MyLocation`}
            onClick={(event: React.MouseEvent) => {
              handleLocationSelectionOption('My Location');
            }}
            type={'button'}
          >
            Una De Mis Ubicaciones
          </button>
          <button
            id={`button${id}MyCompanyLocation`}
            onClick={(event: React.MouseEvent) => {
              handleLocationSelectionOption('My Company Location');
            }}
            type={'button'}
          >
            Ubicación De Mi Empresa
          </button>
          {/* <button
            id={`button${id}AnotherWorkLocationForMe`}
            onClick={(event: React.MouseEvent) => {
              handleLocationSelectionOption('Another Work Location For Me');
            }}
            type={'button'}
          >
            Ubicación De Otro Trabajo Para Mi
          </button>
          <button
            id={`button${id}AnotherWorkLocationForMyCompany`}
            onClick={(event: React.MouseEvent) => {
              handleLocationSelectionOption(
                'Another Work Location For My Company',
              );
            }}
            type={'button'}
          >
            Ubicación De Otro Trabajo Para Mi Empresa
          </button>
          <button
            id={`button${id}NewLocation`}
            onClick={(event: React.MouseEvent) => {
              handleLocationSelectionOption('New Location');
            }}
            type={'button'}
          >
            Nueva Ubicación
          </button> */}
        </div>
      );
      return threeButtonsRender;
    } else {
      <LocationFormContainer
        buttonLabel={buttonLabel != null ? buttonLabel : 'Enviar Formulario'}
        id={`Location${id}`}
        onChange={handleChangeLocation}
        readOnly={innerReadOnly}
        locationDataRaw={
          isWorkUserBackend(workDataRaw)
            ? workDataRaw?.location
            : workDataRaw?.work.location
        }
        handleSubmitFather={handleSubmit}
      />;
    }
  };

  return (
    <div>
      {id.includes('notTakenWorks') && (
        <div>
          <button
            onClick={openPopup}
            id={`button${id}MakeOffer`}
            type={'button'}
          >
            Hacer Oferta
          </button>
          {showPopup && (
            <div className="popup">
              <button
                onClick={() => selectOption('Como Empresa')}
                id={`button${id}MakeOfferAsCompany`}
                type={'button'}
              >
                Como Empresa
              </button>
              <button
                onClick={() => selectOption('Como Trabajador')}
                id={`button${id}MakeOfferAsUser`}
                type={'button'}
              >
                Como Trabajador
              </button>
            </div>
          )}
        </div>
      )}
      {showForm && (
        <div>
          <div>
            <p>
              Categoría del trabajo a realizar (Quien está buscando trabajo
              buscará a través de ésta categoría):
            </p>
            <p>
              Para habilitar el chatbot, por favor selecciona el tipo de
              trabajado que buscas, Fecha aproximada del trabajo e ingresa la
              ciudad del trabajo aquí
            </p>
            <input
              id={`input${id}CityChatbot`}
              name="cityChatbot"
              type="text"
              disabled={false}
              value={publishWorkChatbotCity}
              onChange={handleChangeChatbotPostCode}
            />
            <p>{JSON.stringify(formValues.workType)}</p>
            <TreeViewContainer
              readOnly={innerReadOnly}
              id={`treeView${id}Category`}
              categories={treeCategories}
              treeHandler={treeHandler}
            />
          </div>
          <br />
          <br />
          <div style={{ width: '100%' }}>
            <label htmlFor={`textarea${id}Summary`} id={`label${id}Summary`}>
              Trabajo
            </label>
          </div>
          <div style={{ width: '100%' }}>
            <textarea
              id={`textarea${id}Summary`}
              name="summary"
              required
              disabled={innerReadOnly}
              value={formValues.summary}
              onChange={handleTextAreaChange}
              rows={8}
              style={{ width: '60%' }}
            />
          </div>
          <br />
          <div>
            <CurrencyInputContainer
              id={`CurrencyInput${id}Budget`}
              name="work_budget"
              label="Presupuesto"
              required={true}
              readOnly={innerReadOnly}
              value={formValues.workBudget.toString()}
              onChange={handleCurrencyChange}
            />
            <p>Presupuesto: MXN ${formValues.workBudget.toLocaleString()}</p>
          </div>
          <br />
          <div style={{ width: '100%' }}>
            <label htmlFor={`input${id}StartDate`} id={`label${id}StartDate`}>
              Fecha de Inicio
            </label>
            <input
              id={`input${id}StartDate`}
              name="selectedStartDateTime"
              type="datetime-local"
              disabled={innerReadOnly}
              value={
                formValues.selectedStartDateTime ||
                new Date().toISOString().slice(0, -8)
              }
              onChange={(e) =>
                setSelectedStartDateTime(e.target.value.toLocaleString())
              }
              min={new Date().toISOString().slice(0, -8)}
              style={{ width: '60%' }}
            />
            <p>
              Fecha y hora seleccionadas:{' '}
              {formValues.selectedStartDateTime
                ? formValues.selectedStartDateTime
                : 'Seleccione Fecha y hora de inicio del trabajo.'}
            </p>
          </div>
          <br />
          <div style={{ width: '100%' }}>
            <label htmlFor={`input${id}EndDate`} id={`label${id}EndDate`}>
              Fecha de Terminado
            </label>
            <input
              id={`input${id}EndDate`}
              name="selectedEndDateTime"
              type="datetime-local"
              disabled={innerReadOnly}
              value={
                formValues.selectedEndDateTime ||
                new Date().toISOString().slice(0, -8)
              }
              onChange={(e) =>
                setSelectedEndDateTime(e.target.value.toLocaleString())
              }
              min={new Date().toISOString().slice(0, -8)}
              style={{ width: '60%' }}
            />
            <p>
              Fecha y hora seleccionadas:{' '}
              {formValues.selectedEndDateTime
                ? formValues.selectedEndDateTime
                : 'Seleccione Fecha y hora de término del trabajo.'}
            </p>
          </div>
          <div>
            {(() => {
              if (
                mode != null &&
                id != 'PublishWork' &&
                id != 'PublishWorkPublishWorkForMyCompany'
              ) {
                return (
                  <div>
                    <LocationFormContainer
                      buttonLabel={
                        buttonLabel != null ? buttonLabel : 'Borrar Trabajo'
                      }
                      id={`Location${id}`}
                      onChange={handleChangeLocation}
                      readOnly={true}
                      locationDataRaw={
                        isWorkUserBackend(workDataRaw)
                          ? workDataRaw?.location
                          : workDataRaw?.work.location
                      }
                      handleSubmitFather={handleSubmit}
                    />
                    <button
                      id={`buttonDeleteWork${id}`}
                      onClick={workDelete}
                      type={'button'}
                    >
                      Borrar Trabajo
                    </button>
                  </div>
                );
              } else {
                switch (locationSelectionOption) {
                  case 'New Location':
                    return (
                      <LocationFormContainer
                        buttonLabel={
                          buttonLabel != null
                            ? buttonLabel
                            : 'Enviar Formulario'
                        }
                        id={`Location${id}`}
                        onChange={handleChangeLocation}
                        readOnly={innerReadOnly}
                        locationDataRaw={
                          isWorkUserBackend(workDataRaw)
                            ? workDataRaw?.location
                            : workDataRaw?.work.location
                        }
                        handleSubmitFather={handleSubmit}
                      />
                    );
                  case null:
                    return createButtons();
                  default:
                    return (
                      <GridCard
                        cardsData={locationsDataRaw}
                        handleButtonCardOne={handleButtonCard}
                        buttonLabelCardOne={
                          buttonLabel != null
                            ? buttonLabel
                            : 'Seleccionar Ubicación y Publicar Trabajo'
                        }
                        id={`Grid${id}`}
                      />
                    );
                }
              }
            })()}
          </div>
        </div>
      )}
    </div>
  );
};

export default PublishWorkPresentation;
