import React from 'react';
import AutocompleteWMenuContainer from '../containers/AutocompleteWMenuContainer';
import CompanyTypeSpanishCat from '../data/company_type_spanish_cat.json';
import CompanyTypeSpanishSubCat from '../data/company_type_spanish_subcat.json';
import { Category } from '../services/TreeView';
import PhonesRegisterContainer from '../containers/PhonesRgisterContainer';
import { CompanyFrontend } from '../services/BackendFrontendInterfaces';

interface RegistrationCompanyFormProps {
  id?: string;
  company_name: string;
  rfc: string;
  companyType: CompanyFrontend['companyType'];
  phones: CompanyFrontend['companyPhones'];
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onCompanyTypeChange: (selection: { [key: string]: string[] }) => void;
  onSubmit: (event: React.FormEvent) => void;
  onRegisteredPhones: (phones: CompanyFrontend['companyPhones']) => void;
}

const RegistrationCompanyFormPresentation: React.FC<
  RegistrationCompanyFormProps
> = ({
  id = 'RegistrationCompany',
  company_name,
  rfc,
  companyType,
  phones = [],
  onInputChange,
  onCompanyTypeChange,
  onSubmit,
  onRegisteredPhones,
}) => {
  const categoriesData = (() => {
    const result: Category = {};
    for (
      var i = 0;
      i < CompanyTypeSpanishCat.CompanyTypeSpanishCat.length;
      i++
    ) {
      const key = CompanyTypeSpanishCat.CompanyTypeSpanishCat[i];
      const values = CompanyTypeSpanishSubCat.CompanyTypeSpanishSubCat;
      result[key] = [...values];
    }
    return result;
  })();

  return (
    <div>
      <form className="App" onSubmit={onSubmit} id={`Form${id}`}>
        <div>
          <label
            htmlFor={`input${id}constitutiveAct`}
            id={`label${id}constitutiveAct`}
          >
            Acta Constitutiva:
          </label>
          <input
            id={`input${id}constitutiveAct`}
            name="constitutiveAct"
            type="file"
            accept=".pdf"
            required
            onChange={onInputChange}
          />
        </div>
        <div>
          <label htmlFor={`input${id}CompanyName`} id={`label${id}CompanyName`}>
            Razón Social:
          </label>
          <input
            id={`input${id}CompanyName`}
            name="companyName"
            type="text"
            required
            value={company_name}
            onChange={onInputChange}
          />
        </div>
        <br />
        <div>
          <p>Tipo De Empresa:</p>
          <p>{JSON.stringify(companyType)}</p>
          <AutocompleteWMenuContainer
            autocompleteTitle="Seleccione Tipo De Empresa"
            readOnly={false}
            id="AutocompleteWMenuRegisterCompany"
            categories={categoriesData}
            autocompleteWMenuHandler={onCompanyTypeChange}
          />
        </div>
        <br />
        <div>
          <label htmlFor={`input${id}RFC`} id={`label${id}RFC`}>
            RFC:
          </label>
          <input
            id={`input${id}RFC`}
            name="rfc"
            type="text"
            required
            value={rfc}
            onChange={onInputChange}
          />
        </div>
        <br />
        <div>
          <button type="submit" id="buttonRegistrationCompanySubmit">
            Registrar
          </button>
        </div>
        <br />
        <div>
          <PhonesRegisterContainer
            id="PhonesRegisterRegistrationCompanyPhones"
            onRegisteredPhones={onRegisteredPhones}
            readOnly={false}
            initialPhones={phones}
          />
        </div>
      </form>
    </div>
  );
};

export default RegistrationCompanyFormPresentation;
