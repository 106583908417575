import React from 'react';
import { CompanyFrontend } from '../services/BackendFrontendInterfaces';

interface PhonesRegisterProps {
  readOnly?: boolean;
  id: string;
  phonesNumber: number;
  phones: CompanyFrontend['companyPhones'];
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handlePhoneChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    key: string,
  ) => void;
}

function PhonesRegisterPresentation({
  readOnly = false,
  id = 'PhonesRegister',
  phonesNumber,
  phones,
  handleChange,
  handlePhoneChange,
}: PhonesRegisterProps) {
  const renderTextInputs = () => {
    const inputs = [];
    for (let i = 0; i < phonesNumber; i++) {
      inputs.push(
        <div key={i}>
          <label htmlFor={`input${id}Number${i}`} id={`label${id}Number${i}`}>
            Número de teléfono:
          </label>
          <input
            readOnly={readOnly}
            id={`input${id}Number${i}`}
            type="text"
            value={phones[i].companyPhone}
            onChange={(e) => handlePhoneChange(e, i, 'companyPhone')}
          />
          <label
            htmlFor={`input${id}Extension${i}`}
            id={`label${id}Extension${i}`}
          >
            Extensión:
          </label>
          <input
            readOnly={readOnly}
            id={`input${id}Extension${i}`}
            type="text"
            value={phones[i].extension}
            onChange={(e) => handlePhoneChange(e, i, 'extension')}
          />
        </div>,
      );
    }
    return inputs;
  };

  return (
    <div>
      <label htmlFor={`input${id}NumberPhones`} id={`label${id}NumberPhones`}>
        ¿Cuántos teléfonos quieres registrar?
      </label>
      <input
        readOnly={readOnly}
        id={`input${id}NumberPhones`}
        type="number"
        min={1}
        max={10}
        value={phonesNumber}
        onChange={handleChange}
      />
      {renderTextInputs()}
    </div>
  );
}

export default PhonesRegisterPresentation;
