import React, { useEffect, useState } from 'react';
import MaterialsPresentation from '../components/MaterialsPresentation';
import { WorkOfferFrontend } from '../services/BackendFrontendInterfaces';

interface MaterialsRegisterContainerProps {
  id: string;
  onRegisteredMaterials: (materials: WorkOfferFrontend['materials']) => void;
  initialMaterials: WorkOfferFrontend['materials'];
  readOnly: boolean;
}

const MaterialsContainer: React.FC<MaterialsRegisterContainerProps> = ({
  id = 'MaterialsRegister',
  onRegisteredMaterials,
  initialMaterials = [],
  readOnly = false,
}) => {
  const [materialsNumber, setMaterialsNumbers] = useState<number>(
    initialMaterials.length ? initialMaterials.length : 1,
  );
  const [materials, setMaterials] = useState<WorkOfferFrontend['materials']>(
    initialMaterials.length
      ? initialMaterials
      : [{ material: '', materialBudget: '0' }],
  );
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value);
    if (newValue < 1) {
      setMaterialsNumbers(1);
      setMaterials([{ material: '', materialBudget: '0' }]);
    } else if (newValue > 10) {
      setMaterialsNumbers(10);
      setMaterials(materials.slice(0, 10));
    } else {
      setMaterialsNumbers(newValue);

      const newMaterialsData =
        newValue > materials.length
          ? [
              ...materials,
              ...Array(newValue - materials.length).fill({
                material: '',
                materialBudget: '0',
              }),
            ]
          : materials.slice(0, newValue);

      setMaterials(newMaterialsData);
    }
  };

  const handleMaterialChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    key: string,
  ) => {
    const newMaterials = [...materials];
    newMaterials[index] = {
      ...newMaterials[index],
      [key]: event.target.value,
    };
    setMaterials(newMaterials);
  };

  useEffect(() => {
    onRegisteredMaterials(materials);
  }, [materials, materialsNumber]);

  return (
    <MaterialsPresentation
      id={id}
      readOnly={readOnly}
      materialsNumber={materialsNumber}
      materials={materials}
      handleChange={handleChange}
      handleMaterialChange={handleMaterialChange}
    />
  );
};

export default MaterialsContainer;
