import React, { useEffect, useState } from 'react';
import AutocompleteWMenuPresentation from '../components/AutocompleteWMenuPresentation';

type GenericInterface<T> = {
  [key: string]: string[];
};

interface AutocompleteWMenuContainerProps<T> {
  id: string;
  name?: string | null;
  autocompleteTitle: string;
  readOnly?: boolean;
  multipleOptions?: boolean;
  acceptNew?: boolean;
  required?: boolean;
  categories: { [key: string]: string[] };
  autocompleteWMenuHandler: (selection: GenericInterface<T>) => void;
}

function AutocompleteWMenuContainer<T>({
  id = 'AutocompleteWMenu',
  name = null,
  autocompleteWMenuHandler,
  readOnly = false,
  multipleOptions = true,
  acceptNew = false,
  required = false,
  autocompleteTitle = '',
  categories,
}: AutocompleteWMenuContainerProps<T>) {
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState<string | null>(
    null,
  );
  const [menuOptions, setMenuOptions] = useState<string[]>([]);
  const [menuAutocomplete, setMenuAutocomplete] = useState<string[]>([]);

  const handleSubcategoryClick = (value: {
    name: string | null;
    value: string[];
  }) => {
    setSelectedSubcategory(value.value[0]);
  };

  useEffect(() => {
    if (selectedCategory !== null && selectedSubcategory !== null) {
      const optionSelected: GenericInterface<T> = {
        [selectedCategory]: [selectedSubcategory],
      };
      setSelectedSubcategory(null);
      autocompleteWMenuHandler(optionSelected);
    }
  }, [selectedSubcategory]);

  useEffect(() => {
    if (selectedCategory != null) {
      setMenuAutocomplete(categories[selectedCategory]);
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (selectedCategory == null) {
      setSelectedCategory(menuOptions[0]);
    }
  }, [menuOptions]);

  useEffect(() => {
    setMenuOptions(Object.keys(categories));
  }, []);

  return (
    <AutocompleteWMenuPresentation
      id={`AutocompleteMenu${id}`}
      acceptNew={acceptNew}
      readOnly={readOnly}
      autocompleteTitle={autocompleteTitle}
      menuOptions={menuOptions}
      menuAutocomplete={menuAutocomplete}
      name={name}
      required={required}
      multipleOptions={multipleOptions}
      handleSelectMenu={(
        selectedOption: React.ChangeEvent<HTMLSelectElement>,
      ) => {
        if (!readOnly) {
          setSelectedCategory(selectedOption.target.value);
          setSelectedSubcategory(null);
        }
      }}
      handleSelectAutocomplete={handleSubcategoryClick}
    />
  );
}

export default AutocompleteWMenuContainer;
