import {
  WorkUserBackend,
  WorkCompanyBackendFromAPI,
  EvaluationFrontend,
  EvaluationBackend,
} from '../services/BackendFrontendInterfaces';

interface EvaluateWorkPresentationProps {
  id?: string;
  loaded: boolean;
  evaluation?: EvaluationBackend;
  workDataRaw: WorkUserBackend | WorkCompanyBackendFromAPI;
  handleSubmit: (event: React.FormEvent) => void;
  formValues: EvaluationFrontend;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleTextAreaChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}
function EvaluateWorkPresentation({
  id = 'EvaluateWorkCompleted',
  loaded,
  evaluation,
  handleSubmit,
  formValues,
  handleChange,
  handleTextAreaChange,
}: EvaluateWorkPresentationProps) {
  return (
    <div>
      {loaded ? (
        <form onSubmit={handleSubmit} id={`Form${id}`}>
          <label htmlFor={`input${id}Rate`} id={`label${id}Rate`}>
            Calificación (1 a 5):
          </label>
          <br />
          <input
            id={`input${id}Rate`}
            name="rate"
            type="text"
            disabled={evaluation != null ? true : false}
            value={formValues.rate}
            onChange={handleChange}
          />
          <br />
          <label htmlFor={`input${id}Summary`} id={`label${id}Summary`}>
            Resumen:
          </label>
          <br />
          <input
            id={`input${id}Summary`}
            name="summary"
            type="text"
            disabled={evaluation != null ? true : false}
            value={formValues.summary}
            onChange={handleChange}
          />
          <br />
          <br />
          <label htmlFor={`input${id}Details`} id={`label${id}Details`}>
            Evaluación Detallada:
          </label>
          <br />
          <textarea
            id={`input${id}Details`}
            name="details"
            disabled={evaluation != null ? true : false}
            required
            value={formValues.details}
            onChange={handleTextAreaChange}
            rows={8}
            style={{ width: '60%' }}
          />
          <br />
          <button
            id={`button${id}SubmitEvaluation`}
            type="submit"
            disabled={evaluation != null ? true : false}
          >
            {'Enviar Evaluación'}
          </button>
        </form>
      ) : (
        <p>Cargando...</p>
      )}
    </div>
  );
}

export default EvaluateWorkPresentation;
