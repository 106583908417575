import React, { useState } from 'react';
import CustomizedMenuListContainer from '../containers/CustomizedMenuListContainer';
import AutocompleteContainer from '../containers/AutocompleteContainer';

interface AutocompleteWMenuPresentationProps {
  id: string;
  readOnly: boolean;
  autocompleteTitle: string;
  menuOptions: string[];
  menuAutocomplete: string[];
  name?: string | null;
  required?: boolean;
  multipleOptions?: boolean;
  acceptNew?: boolean;
  handleSelectMenu: (
    selectedOption: React.ChangeEvent<HTMLSelectElement>,
  ) => void;
  handleSelectAutocomplete: (value: {
    name: string | null;
    value: string[];
  }) => void;
}

function AutocompleteWMenuPresentation({
  id = 'AutocompleteWMenu',
  readOnly = false,
  autocompleteTitle = '',
  name = '',
  menuOptions = [],
  menuAutocomplete = [],
  required = false,
  multipleOptions = true,
  acceptNew = true,
  handleSelectMenu,
  handleSelectAutocomplete,
}: AutocompleteWMenuPresentationProps) {
  return (
    <div>
      <CustomizedMenuListContainer
        id={`CustomizedMenuList${id}`}
        name={`CustomizedMenu${name}`}
        options={menuOptions}
        readOnly={readOnly}
        onItemClick={handleSelectMenu}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <AutocompleteContainer
          autocompleteTitle={autocompleteTitle}
          id={`Autocomplete${id}autocompleteTitle`}
          name={`Autocomplete${name}`}
          required={required}
          options={menuAutocomplete}
          onChange={handleSelectAutocomplete}
          initialValues={[]}
          readOnly={readOnly}
          multipleOptions={multipleOptions}
          acceptNew={acceptNew}
        />
      </div>
    </div>
  );
}

export default AutocompleteWMenuPresentation;
