import {
  DisapproveUserCompanyFrontend,
  DeadWorkOfferBackend,
  DisapproveTortoiseUserCompanyBackend,
} from '../services/BackendFrontendInterfaces';

interface DisapproveUserCompanyPresentationProps {
  id?: string;
  loaded: boolean;
  disapproval?: DisapproveTortoiseUserCompanyBackend;
  deadWorkOfferDataRaw: DeadWorkOfferBackend;
  handleSubmit: (event: React.FormEvent) => void;
  formValues: DisapproveUserCompanyFrontend;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleTextAreaChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
function DisapproveUserCompanyPresentation({
  id = 'DisapproveUserCompanyCompleted',
  loaded,
  disapproval,
  handleSubmit,
  formValues,
  handleChange,
  handleTextAreaChange,
  handleCheckboxChange,
}: DisapproveUserCompanyPresentationProps) {
  return (
    <div>
      {loaded ? (
        <form onSubmit={handleSubmit} id={`Form${id}`}>
          <label
            htmlFor={`checkbox${id}Scammer`}
            id={`label${id}Scammer`}
            style={{ color: 'red' }}
          >
            SI NO ES UN TRABAJADOR SINO UN ESTAFADOR, POR FAVOR SELECCIONA AQUÍ
            :{' '}
          </label>
          <input
            id={`checkbox${id}Scammer`}
            name="scammer"
            type="checkbox"
            disabled={false}
            checked={formValues.scammer}
            onChange={handleCheckboxChange}
          />
          <br />
          <br />
          <label htmlFor={`input${id}Rate`} id={`label${id}Rate`}>
            Calificación (1 a 5):
          </label>
          <br />
          <input
            id={`input${id}Rate`}
            name="rate"
            type="text"
            disabled={false}
            value={formValues.rate}
            onChange={handleChange}
          />
          <br />
          <label htmlFor={`input${id}Summary`} id={`label${id}Summary`}>
            Resumen:
          </label>
          <br />
          <input
            id={`input${id}Summary`}
            name="summary"
            type="text"
            disabled={false}
            value={formValues.summary}
            onChange={handleChange}
          />
          <br />
          <br />
          <label htmlFor={`input${id}Details`} id={`label${id}Details`}>
            Evaluación Detallada:
          </label>
          <br />
          <textarea
            id={`input${id}Details`}
            name="details"
            disabled={false}
            required
            value={formValues.details}
            onChange={handleTextAreaChange}
            rows={8}
            style={{ width: '60%' }}
          />
          <br />
          <button
            id={`button${id}SubmitDisapproval`}
            type="submit"
            disabled={false}
          >
            {'Enviar Evaluación'}
          </button>
        </form>
      ) : (
        <p>Cargando...</p>
      )}
    </div>
  );
}

export default DisapproveUserCompanyPresentation;
