import React from 'react';
import { WorkOfferFrontend } from '../services/BackendFrontendInterfaces';
import CurrencyInputContainer from '../containers/CurrencyInputContainer';

interface ToolsRegisterProps {
  readOnly?: boolean;
  id: string;
  toolsNumber: number;
  tools: WorkOfferFrontend['tools'];
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleToolChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    key: string,
  ) => void;
}

function ToolsPresentation({
  readOnly = false,
  id = 'toolsRegister',
  toolsNumber,
  tools,
  handleChange,
  handleToolChange,
}: ToolsRegisterProps) {
  const renderTextInputs = () => {
    const inputs = [];
    for (let i = 0; i < toolsNumber; i++) {
      inputs.push(
        <div key={i}>
          <label htmlFor={`input${id}Number${i}`} id={`label${id}Number${i}`}>
            Herramienta:
          </label>
          <input
            readOnly={readOnly}
            id={`input${id}Number${i}`}
            type="text"
            value={tools[i].tool}
            onChange={(e) => handleToolChange(e, i, 'tool')}
          />
          <CurrencyInputContainer
            id={`CurrencyInput${id}Budget${i}`}
            name="offer_budget"
            label="Presupuesto"
            required={true}
            readOnly={readOnly}
            value={tools[i].toolBudget}
            onChange={(e) => handleToolChange(e, i, 'toolBudget')}
          />
        </div>,
      );
    }
    return inputs;
  };

  return (
    <div>
      <label htmlFor={`input${id}NumberTools`} id={`label${id}NumberTools`}>
        Herramientas Requeridas, compra y desgaste
      </label>
      <input
        readOnly={readOnly}
        id={`input${id}NumberTools`}
        type="number"
        min={1}
        max={20}
        value={toolsNumber}
        onChange={handleChange}
      />
      {renderTextInputs()}
    </div>
  );
}

export default ToolsPresentation;
