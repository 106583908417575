import { useState, useEffect } from 'react';
import Menu from './components/Menu';
import Footer from './components/Footer';
import Section from './containers/SectionContainer';
import './styles/App.css';
import { makeRequest, getCSRFCookieValue } from './services/requests';
import { SessionContextProvider } from './services/SessionContextProvider';
import logo from './only_logo.png';
import PrivacyNotice from './components/PrivacyNotice';

function App() {
  const [selectedOption, setSelectedOption] = useState<string>('inicio');

  useEffect(() => {
    const csrfToken = getCSRFCookieValue();
    if (csrfToken === null) {
      makeRequest({ path: 'csrf', method: 'GET' });
    }
  }, []);

  function selectOption(option: string) {
    setSelectedOption(option);
  }

  return (
    <div className="App">
      <PrivacyNotice />
      <SessionContextProvider>
        <nav className="nav-bar">
          <Menu selectOption={selectOption} />
          <img src={logo} alt="Logo" className="App-logo" />
        </nav>
        <Section selectedOption={selectedOption} />
        <Footer />
      </SessionContextProvider>
    </div>
  );
}

export default App;
