import React from 'react';
import CustomizedMenuListContainer from '../containers/CustomizedMenuListContainer';
import LocationFormContainer from '../containers/LocationFormContainer';
import {
  LocationFrontend,
  LocationBackend,
} from '../services/BackendFrontendInterfaces';

interface LocationCompanyFormPresentationProps {
  id?: string;
  loaded?: boolean;
  showForm?: boolean;
  handleSubmit: (event: React.FormEvent) => void;
  innerReadOnly: boolean;
  companiesList: string[];
  handleSelectChangeCompany: (
    selectedOption: React.ChangeEvent<HTMLSelectElement>,
  ) => void;
  handleChangeLocation: (locationData: LocationFrontend) => void;
  locationDataRaw: LocationBackend | null;
  buttonLabel?: string;
}

const LocationCompanyFormPresentation: React.FC<
  LocationCompanyFormPresentationProps
> = ({
  id = 'LocationCompany',
  locationDataRaw = null,
  loaded = false,
  showForm = true,
  innerReadOnly = false,
  handleSubmit,
  companiesList = [],
  handleSelectChangeCompany,
  handleChangeLocation,
  buttonLabel,
}) => {
  return (
    <div>
      {loaded ? (
        <div>
          {showForm && (
            <div>
              <p>Compañía:</p>
              {companiesList.length > 0 ? (
                <div>
                  <CustomizedMenuListContainer
                    id={`CustomizedMenuList${id}`}
                    options={companiesList}
                    readOnly={innerReadOnly}
                    onItemClick={handleSelectChangeCompany}
                  />
                </div>
              ) : (
                <div>Cargando...</div>
              )}
            </div>
          )}
          <div style={{ marginTop: '20px', marginBottom: '20px' }}>
            <LocationFormContainer
              id={`Location${id}`}
              onChange={handleChangeLocation}
              readOnly={innerReadOnly}
              locationDataRaw={locationDataRaw}
              handleSubmitFather={handleSubmit}
              buttonLabel={buttonLabel}
            />
          </div>
        </div>
      ) : (
        <p>Cargando...</p>
      )}
    </div>
  );
};

export default LocationCompanyFormPresentation;
