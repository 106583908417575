import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import '../styles/PrivacyNotice.css'; // Asegúrate de agregar el archivo CSS correspondiente

interface PrivacyNoticeProps {
  id?: string;
}
const PrivacyNotice: React.FC<PrivacyNoticeProps> = ({
  id = 'PrivacyNotice',
}) => {
  const [showNotice, setShowNotice] = useState<boolean>(false);
  const cookies = new Cookies();

  useEffect(() => {
    const privacyAccepted = cookies.get('privacyAccepted');
    if (!privacyAccepted) {
      setShowNotice(true);
    }
  }, []);

  const acceptPrivacy = () => {
    cookies.set('privacyAccepted', 'true', {
      path: '/',
      maxAge: 365 * 24 * 60 * 60,
    });
    setShowNotice(false);
  };

  if (!showNotice) return null;

  return (
    <div className="privacy-overlay">
      <div className="privacy-notice">
        <p>
          Usamos Cookies Para Poder Manejar La Sesión, Al Hacer Uso Del Sitio
          Estás Aceptando Nuestras{' '}
          <a
            href="/static/confidentiality_agreement"
            target="_blank"
            rel="noopener noreferrer"
          >
            Políticas De Privacidad
          </a>
        </p>
        <button
          onClick={acceptPrivacy}
          id={`button${id}SubmitApproval`}
          type={'button'}
          disabled={false}
        >
          Aceptar
        </button>
      </div>
    </div>
  );
};

export default PrivacyNotice;
