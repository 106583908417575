import React, { useState, useEffect } from 'react';
import { makeRequest } from '../services/requests';
import {
  SupportBackend,
  SupportFrontend,
} from '../services/BackendFrontendInterfaces';
import SupportPresentation from '../components/SupportPresentation';

interface SupportWorkProps {
  id?: string;
  supportDataRaw?: SupportBackend;
}

function SupportWorkContainer({
  id = 'Support',
  supportDataRaw,
}: SupportWorkProps) {
  const [support, setSupport] = useState<SupportBackend | null>(null);
  const [formValues, setFormValues] = useState<SupportFrontend>({
    request: supportDataRaw != null ? supportDataRaw.request : '',
  });

  const getSupportOpened = async () => {
    try {
      const path: string = 'support/get_my_open_support_request';
      const method = 'GET';

      const response = await makeRequest({ path: path, method: method });
      if (!response.ok) {
        const response_json = await response.json();
        throw new Error(JSON.stringify(response_json.detail));
      }
      const data = await response.json();
      setSupport(data);
    } catch (error: unknown) {
      if (error instanceof Error) {
        alert(error.message);
      } else if (
        typeof error === 'object' &&
        error !== null &&
        'message' in error &&
        'componentStack' in error
      ) {
        const reactError = error as React.ErrorInfo;
        alert(reactError);
      } else {
        alert(JSON.stringify(error));
      }
    }
  };

  useEffect(() => {
    getSupportOpened();
  }, []);

  useEffect(() => {
    if (support?.request != null) {
      setFormValues((prevValues) => ({
        ...prevValues,
        request: support.request,
      }));
    }
  }, [support]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const objToSend: SupportBackend = {
        request: formValues.request,
      };
      let path: string = 'support/request_for_support';
      const method = 'POST';
      const content_type = 'application/json';
      const response = await makeRequest({
        path: path,
        method: method,
        body: JSON.stringify(objToSend),
        contentType: content_type,
      });
      if (!response.ok) {
        const response_json = await response.json();
        throw new Error(JSON.stringify(response_json.detail));
      }
      const output_message = 'Solicitud Enviada Correctamente';
      alert(output_message);
      window.location.reload();
    } catch (error: unknown) {
      if (error instanceof Error) {
        alert(error.message);
      } else if (
        typeof error === 'object' &&
        error !== null &&
        'message' in error &&
        'componentStack' in error
      ) {
        const reactError = error as React.ErrorInfo;
        alert(reactError);
      } else {
        alert(JSON.stringify(error));
      }
    }
  };

  return (
    <div>
      <SupportPresentation
        id={id}
        handleSubmit={handleSubmit}
        formValues={formValues}
        handleTextAreaChange={(event) => {
          const { name, value } = event.target;
          setFormValues((prevValues) => ({
            ...prevValues,
            request: value,
          }));
        }}
      />
    </div>
  );
}

export default SupportWorkContainer;
