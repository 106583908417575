import React, { useEffect, useState } from 'react';
import ToolsPresentation from '../components/ToolsPresentation';
import { WorkOfferFrontend } from '../services/BackendFrontendInterfaces';

interface ToolsRegisterContainerProps {
  id: string;
  onRegisteredTools: (tools: WorkOfferFrontend['tools']) => void;
  initialTools: WorkOfferFrontend['tools'];
  readOnly: boolean;
}

const ToolsContainer: React.FC<ToolsRegisterContainerProps> = ({
  id = 'ToolsRegister',
  onRegisteredTools,
  initialTools = [],
  readOnly = false,
}) => {
  const [toolsNumber, setToolsNumbers] = useState<number>(
    initialTools.length ? initialTools.length : 1,
  );
  const [tools, setTools] = useState<WorkOfferFrontend['tools']>(
    initialTools.length ? initialTools : [{ tool: '', toolBudget: '0' }],
  );
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value);
    if (newValue < 1) {
      setToolsNumbers(1);
      setTools([{ tool: '', toolBudget: '0' }]);
    } else if (newValue > 10) {
      setToolsNumbers(10);
      setTools(tools.slice(0, 10));
    } else {
      setToolsNumbers(newValue);

      const newToolsData =
        newValue > tools.length
          ? [
              ...tools,
              ...Array(newValue - tools.length).fill({
                tool: '',
                toolBudget: '0',
              }),
            ]
          : tools.slice(0, newValue);

      setTools(newToolsData);
    }
  };

  const handleToolChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    key: string,
  ) => {
    const newTools = [...tools];
    newTools[index] = {
      ...newTools[index],
      [key]: event.target.value,
    };
    setTools(newTools);
  };

  useEffect(() => {
    onRegisteredTools(tools);
  }, [tools, toolsNumber]);

  return (
    <ToolsPresentation
      id={id}
      readOnly={readOnly}
      toolsNumber={toolsNumber}
      tools={tools}
      handleChange={handleChange}
      handleToolChange={handleToolChange}
    />
  );
};

export default ToolsContainer;
