import React, { useState, useEffect } from 'react';
import { makeRequest } from '../services/requests';
import MyCompletedOffersPresentation from '../components/MyCompletedOffersPresentation';
import { DeadWorkOfferBackend } from '../services/BackendFrontendInterfaces';
import { CardsGridCaller } from '../components/GridCard';

interface MyCompletedOffersProps {
  id?: CardsGridCaller;
}

function MyCompletedOffersContainer({
  id = 'myDeadOffers',
}: MyCompletedOffersProps) {
  const [offersDataRaw, setOffersDataRaw] = useState<
    DeadWorkOfferBackend[] | null
  >(null);

  useEffect(() => {
    async function fetchData() {
      const path = 'offers/get_my_completed_offers/';
      const method = 'GET';
      try {
        const response = await makeRequest({ path: path, method: method });
        const data: DeadWorkOfferBackend[] = await response.json();
        setOffersDataRaw(data);
      } catch (error: unknown) {
        if (error instanceof Error) {
          alert(error.message);
        } else if (
          typeof error === 'object' &&
          error !== null &&
          'message' in error &&
          'componentStack' in error
        ) {
          const reactError = error as React.ErrorInfo;
          alert(reactError);
        } else {
          alert(JSON.stringify(error));
        }
      }
    }
    fetchData();
  }, []);

  if (offersDataRaw === null) {
    return <h1>Cargando...</h1>;
  } else {
    return (
      <MyCompletedOffersPresentation id={id} offersDataRaw={offersDataRaw} />
    );
  }
}

export default MyCompletedOffersContainer;
