import GridCard from './GridCard';
import { DeadWorkOfferBackend } from '../services/BackendFrontendInterfaces';
import { CardsGridCaller } from '../components/GridCard';

interface MyCompletedWorksPresentationProps {
  id: CardsGridCaller;
  worksDataRaw: DeadWorkOfferBackend[];
  buttonLabelCardOne?: string;
  buttonLabelCardTwo?: string;
  buttonLabelCardThree?: string;
  handleButtonCardOne: (cardData: DeadWorkOfferBackend) => void;
  handleButtonCardTwo: (cardData: DeadWorkOfferBackend) => void;
  handleButtonCardThree: (cardData: DeadWorkOfferBackend) => void;
}

function MyCompletedWorksPresentation({
  id = 'myDeadWorks',
  worksDataRaw = [],
  buttonLabelCardOne = `Evaluar Trabajo`,
  buttonLabelCardTwo = `Evaluar Positivamente A Trabajador`,
  buttonLabelCardThree = `Puntos De Mejora Para El Trabajador`,
  handleButtonCardOne,
  handleButtonCardTwo,
  handleButtonCardThree,
}: MyCompletedWorksPresentationProps) {
  return worksDataRaw?.length > 0 ? (
    <GridCard
      id={id}
      handleButtonCardOne={handleButtonCardOne}
      handleButtonCardTwo={handleButtonCardTwo}
      handleButtonCardThree={handleButtonCardThree}
      buttonLabelCardOne={buttonLabelCardOne}
      buttonLabelCardTwo={buttonLabelCardTwo}
      buttonLabelCardThree={buttonLabelCardThree}
      cardsData={worksDataRaw}
    />
  ) : (
    <h2>No Se encontraron trabajos completados disponibles</h2>
  );
}

export default MyCompletedWorksPresentation;
