import React, { useState, useEffect } from 'react';
import { makeRequest } from '../services/requests';
import {
  WorkUserBackend,
  EvaluationFrontend,
  WorkCompanyBackendFromAPI,
  EvaluationBackend,
} from '../services/BackendFrontendInterfaces';
import {
  isWorkUserBackend,
  isWorkCompanyBackendFromAPI,
} from '../services/InterfaceTypeValidator';
import EvaluateWorkPresentation from '../components/EvaluateWorkPresentation';

interface EvaluateWorkProps {
  id?: string;
  workDataRaw: WorkUserBackend | WorkCompanyBackendFromAPI;
  evaluation?: EvaluationBackend;
}

function EvaluateWorkContainer({
  id = 'EvaluateWorkCompleted',
  workDataRaw,
  evaluation,
}: EvaluateWorkProps) {
  const [loaded, setLoaded] = useState(false);
  const [rate, setRate] = useState<number>(0);
  const [formValues, setFormValues] = useState<EvaluationFrontend>({
    summary: '',
    details: '',
    rate: '0',
  });

  useEffect(() => {
    setFormValues((prevValues) => ({ ...prevValues, rate: rate.toString() }));
  }, [rate]);

  useEffect(() => {
    if (evaluation != null) {
      setFormValues((prevValues) => ({
        ...prevValues,
        summary: evaluation.summary,
        details: evaluation.details,
        rate: evaluation.rate.toString(),
      }));
    }
    setLoaded(true);
  }, []);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const objToSend: EvaluationBackend = {
        summary: formValues.summary,
        details: formValues.details,
        rate: parseInt(formValues.rate),
      };
      let path: string = '';
      if (isWorkUserBackend(workDataRaw)) {
        path = `works/close_work/work_id/${workDataRaw.id}`;
      } else if (isWorkCompanyBackendFromAPI(workDataRaw)) {
        path = `works/close_work/work_id/${workDataRaw.work.id}`;
      }
      const method = 'POST';

      const content_type = 'application/json';
      const response = await makeRequest({
        path: path,
        method: method,
        body: JSON.stringify(objToSend),
        contentType: content_type,
      });

      if (!response.ok) {
        const response_json = await response.json();
        throw new Error(JSON.stringify(response_json.detail));
      }

      const output_message = 'Trabajo Completado Correctamente';
      alert(output_message);
      window.location.reload();
    } catch (error: unknown) {
      if (error instanceof Error) {
        alert(error.message);
      } else if (
        typeof error === 'object' &&
        error !== null &&
        'message' in error &&
        'componentStack' in error
      ) {
        const reactError = error as React.ErrorInfo;
        alert(reactError);
      } else {
        alert(JSON.stringify(error));
      }
    }
  };

  return (
    <EvaluateWorkPresentation
      id={id}
      loaded={loaded}
      evaluation={evaluation}
      workDataRaw={workDataRaw}
      handleSubmit={handleSubmit}
      formValues={formValues}
      handleTextAreaChange={(event) => {
        const { name, value } = event.target;
        setFormValues((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
      }}
      handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        if (name == 'rate') {
          const newValue = value.substring(value.length - 1, value.length);
          if (parseInt(newValue) >= 0 && parseInt(newValue) <= 5) {
            setRate(parseInt(newValue));
          }
        } else {
          setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
        }
      }}
    />
  );
}

export default EvaluateWorkContainer;
