import React, { useState, useEffect, useContext } from 'react';
import { makeRequest } from '../services/requests';
import MyCompaniesLocationsShowPresentation from '../components/MyCompaniesLocationsShowPresentation';
import { LocationCompanyBackend } from '../services/BackendFrontendInterfaces';
import { SessionContext } from '../services/SessionContextProvider';

interface MyCompaniesLocationsShowProps {
  id?: string;
}

const MyCompaniesLocationsShowContainer: React.FC<
  MyCompaniesLocationsShowProps
> = ({ id = 'MyCompaniesLocationsShow' }) => {
  const [locationsDataRaw, setLocationsDataRaw] = useState<
    LocationCompanyBackend[] | null
  >(null);
  const [selectedLocationFromMap, setSelectedLocationFromMap] = useState<
    LocationCompanyBackend[] | null
  >(null);

  const {
    myLocationsCompaniesBackendCache,
    setMyLocationsCompaniesBackendCache,
  } = useContext(SessionContext);

  useEffect(() => {
    async function fetchData() {
      const path = `location/get_my_company_locations`;
      const method = 'GET';
      try {
        let data = myLocationsCompaniesBackendCache;
        if (myLocationsCompaniesBackendCache == null) {
          const response = await makeRequest({ path: path, method: method });
          data = await response.json();
          setMyLocationsCompaniesBackendCache(data);
        }
        setLocationsDataRaw(data);
      } catch (error: unknown) {
        if (error instanceof Error) {
          alert(error.message);
        } else if (
          typeof error === 'object' &&
          error !== null &&
          'message' in error &&
          'componentStack' in error
        ) {
          const reactError = error as React.ErrorInfo;
          alert(reactError);
        } else {
          alert(JSON.stringify(error));
        }
      }
    }
    fetchData();
  }, []);

  if (locationsDataRaw === null) {
    return <h1>Cargando...</h1>;
  } else {
    return (
      <MyCompaniesLocationsShowPresentation
        id={`${id}`}
        locationsDataRaw={locationsDataRaw}
        setSelectedLocationFromMap={setSelectedLocationFromMap}
        selectedLocationFromMap={selectedLocationFromMap}
      />
    );
  }
};

export default MyCompaniesLocationsShowContainer;
