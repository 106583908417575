import React, { SetStateAction, useEffect, Dispatch, useState } from 'react';
import WrapperForMap from './WrapperForMap';
import CurrencyInputContainer from '../containers/CurrencyInputContainer';
import {
  WorkFilterFrontend,
  WorkUserBackend,
} from '../services/BackendFrontendInterfaces';
import CheckboxListContainer from '../containers/CheckBoxListContainer';
import PublishWorkContainer from '../containers/PublishWorkContainer';

interface FilterFormProps {
  id: string;
  formValues: WorkFilterFrontend;
  setSelectedStartDateTime: (date: string) => void;
  setSelectedEndDateTime: (date: string) => void;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checkBoxOptions: string[];
  handleCheckboxSelectedChange: (selectedValues: string[]) => void;
  handleSubmit: (event: React.FormEvent) => void;
}

function FilterForm({
  id,
  formValues,
  setSelectedStartDateTime,
  setSelectedEndDateTime,
  handleInputChange,
  checkBoxOptions,
  handleCheckboxSelectedChange,
  handleSubmit,
}: FilterFormProps) {
  return (
    <>
      <form onSubmit={handleSubmit} id={`Form${id}`}>
        <br />
        <CheckboxListContainer
          checkboxListTitle={'Tipo De Trabajador Requerido:'}
          options={checkBoxOptions}
          handleCheckboxSelectedChange={handleCheckboxSelectedChange}
        />
        <br />
        <label htmlFor={`input${id}StartDate`} id={`label${id}StartDate`}>
          Fecha de Inicio
        </label>
        <input
          id={`input${id}StartDate`}
          name="selectedStartDateTime"
          type="datetime-local"
          disabled={false}
          value={
            formValues.selectedStartDateTime ||
            new Date().toISOString().slice(0, -8)
          }
          onChange={(e) =>
            setSelectedStartDateTime(e.target.value.toLocaleString())
          }
          min={new Date().toISOString().slice(0, -8)}
          style={{ width: '60%' }}
        />
        <p>
          Fecha y hora seleccionadas:{' '}
          {formValues.selectedStartDateTime
            ? formValues.selectedStartDateTime
            : 'Seleccione Fecha y hora de inicio del trabajo.'}
        </p>
        <label htmlFor={`input${id}EndDate`} id={`label${id}EndDate`}>
          Fecha de Terminado
        </label>
        <input
          id={`input${id}EndDate`}
          name="selectedEndDateTime"
          type="datetime-local"
          disabled={false}
          value={
            formValues.selectedEndDateTime ||
            new Date().toISOString().slice(0, -8)
          }
          onChange={(e) =>
            setSelectedEndDateTime(e.target.value.toLocaleString())
          }
          min={new Date().toISOString().slice(0, -8)}
          style={{ width: '60%' }}
        />
        <p>
          Fecha y hora seleccionadas:{' '}
          {formValues.selectedEndDateTime
            ? formValues.selectedEndDateTime
            : 'Seleccione Fecha y hora de término del trabajo.'}
        </p>
        <br />
        <p>Presupuesto Mínimo:</p>
        <br />
        <CurrencyInputContainer
          id={`CurrencyInput${id}minimumWorkBudget`}
          name="minimumWorkBudget"
          label="Presupuesto Mínimo"
          required={false}
          readOnly={false}
          value={formValues.minimumWorkBudget}
          onChange={(e) => {
            const simulatedEvent = {
              target: {
                name: 'minimumWorkBudget',
                value: parseFloat(e.target.value) > 0 ? e.target.value : '',
              },
            } as React.ChangeEvent<HTMLInputElement>;
            handleInputChange(simulatedEvent);
          }}
        />
        <br />
        <p>Presupuesto Máximo:</p>
        <br />
        <CurrencyInputContainer
          id={`CurrencyInput${id}maximumWorkBudget`}
          name="maximumWorkBudget"
          label="Presupuesto Máximo"
          required={false}
          readOnly={false}
          value={formValues.maximumWorkBudget}
          onChange={(e) => {
            const simulatedEvent = {
              target: {
                name: 'maximumWorkBudget',
                value: parseFloat(e.target.value) > 0 ? e.target.value : '',
              },
            } as React.ChangeEvent<HTMLInputElement>;
            handleInputChange(simulatedEvent);
          }}
        />
        <br />
        <button id={`button${id}submitFilter`} type="submit" disabled={false}>
          {'Filtrar'}
        </button>
      </form>
    </>
  );
}

interface NotTakenWorksPresentationProps {
  id: string;
  worksDataRaw: WorkUserBackend[];
  worksDataRawFiltered: number[];
  formValues: WorkFilterFrontend;
  setSelectedStartDateTime: (date: string) => void;
  setSelectedEndDateTime: (date: string) => void;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checkBoxOptions: string[];
  handleCheckboxSelectedChange: (selectedValues: string[]) => void;
  handleSubmit: (event: React.FormEvent) => void;
  setSelectedWorkFromMap: Dispatch<SetStateAction<WorkUserBackend | null>>;
  selectedWorkFromMap: WorkUserBackend | null;
}

function NotTakenWorksPresentation({
  id = 'notTakenWorks',
  worksDataRaw = [],
  worksDataRawFiltered,
  formValues,
  setSelectedStartDateTime,
  setSelectedEndDateTime,
  handleInputChange,
  checkBoxOptions,
  handleCheckboxSelectedChange,
  handleSubmit,
  setSelectedWorkFromMap,
  selectedWorkFromMap,
}: NotTakenWorksPresentationProps) {
  const [filteredWorksData, setFilteredWorksData] = useState<WorkUserBackend[]>(
    [],
  );
  const [reload, setReload] = useState<boolean>(false);

  useEffect(() => {
    if (reload) {
      setReload(false);
    }
  }, [reload]);

  useEffect(() => {
    if (worksDataRawFiltered.length > 0) {
      setFilteredWorksData(
        worksDataRaw.filter((work) => worksDataRawFiltered.includes(work.id!)),
      );
    } else {
      setFilteredWorksData(worksDataRaw);
    }
    setReload(true);
  }, [worksDataRawFiltered]);

  return selectedWorkFromMap != null ? (
    <PublishWorkContainer
      id={id}
      readOnly={true}
      workDataRaw={selectedWorkFromMap}
    />
  ) : reload ? (
    <h1>Reloading...</h1>
  ) : filteredWorksData?.length > 0 ? (
    <div style={{ width: '100%' }}>
      <FilterForm
        id={id}
        handleSubmit={handleSubmit}
        formValues={formValues}
        setSelectedStartDateTime={setSelectedStartDateTime}
        setSelectedEndDateTime={setSelectedEndDateTime}
        handleInputChange={handleInputChange}
        checkBoxOptions={checkBoxOptions}
        handleCheckboxSelectedChange={handleCheckboxSelectedChange}
      />
      <WrapperForMap
        id={`wrapperForMap${id}`}
        locationsDataRawList={[...filteredWorksData]}
        setReturnedData={setSelectedWorkFromMap}
      />
    </div>
  ) : (
    <div>
      <FilterForm
        id={id}
        handleSubmit={handleSubmit}
        formValues={formValues}
        setSelectedStartDateTime={setSelectedStartDateTime}
        setSelectedEndDateTime={setSelectedEndDateTime}
        handleInputChange={handleInputChange}
        checkBoxOptions={checkBoxOptions}
        handleCheckboxSelectedChange={handleCheckboxSelectedChange}
      />
      <h2>No Se encontraron trabajos disponibles</h2>
    </div>
  );
}

export default NotTakenWorksPresentation;
