import React from 'react';
import Card from '../components/Card';
import {
  WorkUserBackend,
  WorkOfferBackend,
  DeadWorkOfferBackend,
  ApproveUserCompanyBackend,
  DisapproveUserCompanyBackend,
  LocationBackend,
  WorkCompanyBackendFromAPI,
} from '../services/BackendFrontendInterfaces';

export type CardsGridCaller =
  | `${string}publishWork${string}`
  | `${string}Grid${string}`
  | `${string}ReviewMyPublishedWorks${string}`
  | `${string}ReviewMyCompaniesPublishedWorks${string}`
  | `${string}ReviewMyOffers${string}`
  | `${string}myDeadOffers${string}`
  | `${string}myDeadWorks${string}`
  | `${string}myCompaniesDeadWorks${string}`
  | `${string}myCompaniesDeadOffers${string}`
  | `${string}myApprovals${string}`
  | `${string}myCompaniesApprovals${string}`
  | `${string}myCompaniesDisapprovals${string}`
  | `${string}myDisapprovals${string}`
  | `${string}ReviewMyCompaniesOffers${string}`;

interface GridProps {
  cardsData:
    | LocationBackend[]
    | WorkUserBackend[]
    | WorkOfferBackend[]
    | DeadWorkOfferBackend[]
    | ApproveUserCompanyBackend[]
    | DisapproveUserCompanyBackend[]
    | WorkCompanyBackendFromAPI[];
  id: CardsGridCaller;
  handleButtonCardOne?:
    | ((cardData: LocationBackend) => void)
    | ((cardData: WorkUserBackend) => void)
    | ((cardData: WorkOfferBackend) => void)
    | ((cardData: DeadWorkOfferBackend) => void)
    | ((cardData: ApproveUserCompanyBackend) => void)
    | ((cardData: DisapproveUserCompanyBackend) => void)
    | ((cardData: WorkCompanyBackendFromAPI) => void);
  handleButtonCardTwo?:
    | ((cardData: LocationBackend) => void)
    | ((cardData: WorkUserBackend) => void)
    | ((cardData: WorkOfferBackend) => void)
    | ((cardData: DeadWorkOfferBackend) => void)
    | ((cardData: ApproveUserCompanyBackend) => void)
    | ((cardData: DisapproveUserCompanyBackend) => void)
    | ((cardData: WorkCompanyBackendFromAPI) => void);
  handleButtonCardThree?:
    | ((cardData: LocationBackend) => void)
    | ((cardData: WorkUserBackend) => void)
    | ((cardData: WorkOfferBackend) => void)
    | ((cardData: DeadWorkOfferBackend) => void)
    | ((cardData: ApproveUserCompanyBackend) => void)
    | ((cardData: DisapproveUserCompanyBackend) => void)
    | ((cardData: WorkCompanyBackendFromAPI) => void);
  buttonLabelCardOne?:
    | string
    | ((cardData: LocationBackend) => string)
    | ((cardData: WorkUserBackend) => string)
    | ((cardData: WorkOfferBackend) => string)
    | ((cardData: DeadWorkOfferBackend) => string)
    | ((cardData: ApproveUserCompanyBackend) => string)
    | ((cardData: DisapproveUserCompanyBackend) => string)
    | ((cardData: WorkCompanyBackendFromAPI) => string);
  buttonLabelCardTwo?:
    | string
    | ((cardData: LocationBackend) => string)
    | ((cardData: WorkUserBackend) => string)
    | ((cardData: WorkOfferBackend) => string)
    | ((cardData: DeadWorkOfferBackend) => string)
    | ((cardData: ApproveUserCompanyBackend) => string)
    | ((cardData: DisapproveUserCompanyBackend) => string)
    | ((cardData: WorkCompanyBackendFromAPI) => string);
  buttonLabelCardThree?:
    | string
    | ((cardData: LocationBackend) => string)
    | ((cardData: WorkUserBackend) => string)
    | ((cardData: WorkOfferBackend) => string)
    | ((cardData: DeadWorkOfferBackend) => string)
    | ((cardData: ApproveUserCompanyBackend) => string)
    | ((cardData: DisapproveUserCompanyBackend) => string)
    | ((cardData: WorkCompanyBackendFromAPI) => string);
}

const Grid: React.FC<GridProps> = ({
  cardsData,
  handleButtonCardOne = undefined,
  handleButtonCardTwo = undefined,
  handleButtonCardThree = undefined,
  buttonLabelCardOne,
  buttonLabelCardTwo,
  buttonLabelCardThree,
  id = 'Grid',
}) => {
  return (
    <div className="grid">
      {cardsData.map((cardData, index) => (
        <Card
          cardData={cardData}
          handleButtonCardOne={handleButtonCardOne}
          buttonLabelCardOne={
            typeof buttonLabelCardOne === 'function'
              ? // @ts-ignore
                buttonLabelCardOne(cardData)
              : buttonLabelCardOne
          }
          handleButtonCardTwo={handleButtonCardTwo}
          buttonLabelCardTwo={
            typeof buttonLabelCardTwo === 'function'
              ? // @ts-ignore
                buttonLabelCardTwo(cardData)
              : buttonLabelCardTwo
          }
          handleButtonCardThree={handleButtonCardThree}
          buttonLabelCardThree={
            typeof buttonLabelCardThree === 'function'
              ? // @ts-ignore
                buttonLabelCardThree(cardData)
              : buttonLabelCardThree
          }
          id={`${id}${index}`}
          key={index}
        />
      ))}
    </div>
  );
};

export default Grid;
