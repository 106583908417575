import React, { useState } from 'react';

interface AutocompletePresentationProps {
  id: string;
  autocompleteTitle: string;
  selectedOptions: string[];
  searchTerm: string;
  suggestedOptions: string[];
  error: string;
  readOnly?: boolean;
  acceptNew?: boolean;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleOptionClick: (option: string) => void;
  handleSelectedOptionRemove: (option: string) => void;
  handleNewOptionSubmit: (newOption: string) => void;
}

function AutocompletePresentation({
  id = 'Autocomplete',
  selectedOptions,
  searchTerm,
  suggestedOptions,
  error,
  readOnly = false,
  acceptNew = false,
  autocompleteTitle,
  handleInputChange,
  handleOptionClick,
  handleSelectedOptionRemove,
  handleNewOptionSubmit,
}: AutocompletePresentationProps) {
  const [newOptionInput, setNewOptionInput] = useState('');

  const handleSubmitNewOption = () => {
    if (newOptionInput.trim() !== '') {
      handleNewOptionSubmit(newOptionInput.toUpperCase());
      setNewOptionInput('');
    }
  };

  return (
    <div>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <div>
        {selectedOptions.map((option) => (
          <span key={option}>
            {option}
            <button
              id={`buttonRemove${id}${option}`}
              onClick={() => handleSelectedOptionRemove(option)}
              disabled={readOnly}
              type={'button'}
            >
              X
            </button>
          </span>
        ))}
      </div>
      <label htmlFor={`input${id}`} id={`label${id}`}>
        {autocompleteTitle}
      </label>
      <input
        id={`input${id}`}
        type="text"
        value={searchTerm}
        onChange={handleInputChange}
        disabled={readOnly}
      />
      {suggestedOptions.length > 0 && (
        <ul>
          {suggestedOptions.map((option) => (
            <li
              key={option}
              onClick={() => handleOptionClick(option)}
              id={`li${id}${option}`}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
      {acceptNew && !readOnly && (
        <div>
          <label htmlFor={`inputNewOption${id}`} id={`labelNewOption${id}`}>
            {autocompleteTitle}
          </label>
          <input
            id={`inputNewOption${id}`}
            type="text"
            placeholder="Nueva opción"
            value={newOptionInput}
            onChange={(e) => setNewOptionInput(e.target.value)}
            disabled={readOnly}
          />
          <button
            id={`buttonNewOption${id}`}
            onClick={handleSubmitNewOption}
            type={'button'}
          >
            Agregar Nueva Opcion
          </button>
        </div>
      )}
    </div>
  );
}

export default AutocompletePresentation;
