import React, { useState, useContext } from 'react';
import RegistrationCompanyFormPresentation from '../components/RegistrationCompanyFormPresentation';
import { makeRequest } from '../services/requests';
import {
  CompanyFrontend,
  CompanyBackend,
  FormDataModes,
  CompanyBackendToSend,
} from '../services/BackendFrontendInterfaces';
import { SessionContext } from '../services/SessionContextProvider';
import constants from '../data/constants.json';

const WorkTypeSpanishEnum = constants.WorkTypeSpanishEnum;
const FormWithDataModes = constants.FormWithDataModes;

interface RegistrationCompanyFormContainerProps {
  readOnly: boolean;
  mode?: FormDataModes['value'] | null;
}

const RegistrationCompanyFormContainer: React.FC<
  RegistrationCompanyFormContainerProps
> = ({ readOnly = false, mode = FormWithDataModes.Edit }) => {
  const [formValues, setFormValues] = useState<CompanyFrontend>({
    companyName: '',
    rfc: '',
    companyType: {},
    companyPhones: [],
    constitutiveAct: null,
    rate_approved: 0,
    approved_by: 0,
    rate_disapproved: 0,
    disapproved_by: 0,
    rate_works_evaluated: 0,
    works_evaluated: 0,
  });

  const handleCheckboxcompanyTypeChange = (selection: {
    [key: string]: string[];
  }) => {
    const selectionKey = Object.keys(selection)[0];
    const selectionValue = selection[selectionKey][0];

    setFormValues((prevValues) => {
      const updatedCompanyType = {
        ...prevValues.companyType,
        [selectionKey]: prevValues.companyType[selectionKey]?.includes(
          selectionValue,
        )
          ? prevValues.companyType[selectionKey].filter(
              (element) => element !== selectionValue,
            )
          : [...(prevValues.companyType[selectionKey] || []), selectionValue],
      };

      if (updatedCompanyType[selectionKey].length === 0) {
        delete updatedCompanyType[selectionKey];
      }

      return {
        ...prevValues,
        companyType: updatedCompanyType,
      };
    });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
    if (name.includes('constitutiveAct')) {
      if (event.target.files != null) {
        setFormValues((prevValues) => ({
          ...prevValues,
          [name]: event.target.files![0],
        }));
      }
    }
  };

  const {
    setMyWorksBackendCache,
    setMyCompaniesBackendCache,
    setMyWorksCompaniesBackendCache,
  } = useContext(SessionContext);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const fixCompanyPhones = (
      companyPhones: CompanyFrontend['companyPhones'],
    ) => {
      const toReturn: CompanyBackend['company_phones'] = [];

      companyPhones.forEach((phone) => {
        const ph: CompanyBackend['company_phones'][0] = {
          company_phone: parseInt(phone.companyPhone),
          ...(phone.extension && { extension: parseInt(phone.extension) }),
        };

        toReturn.push(ph);
      });
      return toReturn;
    };

    const fixCompanyTypes = (companyType: CompanyFrontend['companyType']) => {
      const toReturn: CompanyBackend['company_types'] = [];

      Object.keys(companyType).forEach((companyTypeKey) => {
        companyType[companyTypeKey].forEach((productType) => {
          const companyTypeBackend: CompanyBackend['company_types'][0] = {
            service_type: WorkTypeSpanishEnum.indexOf(companyTypeKey),
            product_type: productType,
          };
          toReturn.push(companyTypeBackend);
        });
      });
      return toReturn;
    };

    try {
      const obj_to_send: CompanyBackendToSend = {
        company_name: formValues.companyName,
        rfc: formValues.rfc,
        company_phones: fixCompanyPhones(formValues.companyPhones),
        company_types: fixCompanyTypes(formValues.companyType),
      };
      const path = readOnly
        ? `location/delete_work_for_me`
        : mode === FormWithDataModes.Edit
        ? `company/register_my_company`
        : `works/register_my_company`;
      const method = 'POST';

      const content_type = 'application/json';
      switch (path) {
        case 'location/delete_work_for_me':
          setMyWorksBackendCache(null);
          break;
        case 'company/register_my_company':
          setMyCompaniesBackendCache(null);
          break;
        case 'works/register_my_company':
          setMyWorksCompaniesBackendCache(null);
          break;
      }
      const response = await makeRequest({
        path: path,
        method: method,
        body: JSON.stringify(obj_to_send),
        contentType: content_type,
      });

      if (!response.ok) {
        const response_json = await response.json();
        throw new Error(JSON.stringify(response_json.detail));
      }

      if (
        path === 'company/register_my_company' &&
        formValues.constitutiveAct
      ) {
        const header = {
          Accept: 'application/json',
        };
        const myCompaniesResponse = await makeRequest({
          path: 'company/get_my_companies',
          method: 'GET',
          header: header,
        });
        let pathToConstitutiveAct: string = '';
        const myCompanies: CompanyBackend[] = await myCompaniesResponse.json();
        for (let i = myCompanies.length - 1; i >= 0; i--) {
          if (
            myCompanies[i].rfc.toUpperCase() == obj_to_send.rfc.toUpperCase()
          ) {
            pathToConstitutiveAct = `company/constitutive_act/${myCompanies[
              i
            ].id!.toString()}`;
            break;
          }
        }

        if (pathToConstitutiveAct === '') {
          throw new Error('No matching company found');
        }

        const formData = new FormData();
        formData.append('constitutive_act', formValues.constitutiveAct);

        const response = await makeRequest({
          path: pathToConstitutiveAct,
          method: 'POST',
          body: formData,
          header: header,
        });
      }
      const output_message = readOnly ? `Borrado exitoso` : `Registro exitoso`;
      alert(output_message);
      window.location.reload();
    } catch (error: unknown) {
      if (error instanceof Error) {
        alert(error.message);
      } else if (
        typeof error === 'object' &&
        error !== null &&
        'message' in error &&
        'componentStack' in error
      ) {
        const reactError = error as React.ErrorInfo;
        alert(reactError);
      } else {
        alert(JSON.stringify(error));
      }
    }
  };

  const onRegisteredPhones = (phones: CompanyFrontend['companyPhones']) => {
    setFormValues((prevValues) => ({ ...prevValues, companyPhones: phones }));
  };

  return (
    <RegistrationCompanyFormPresentation
      company_name={formValues.companyName}
      rfc={formValues.rfc}
      companyType={formValues.companyType}
      phones={formValues.companyPhones}
      onInputChange={handleInputChange}
      onCompanyTypeChange={handleCheckboxcompanyTypeChange}
      onSubmit={handleSubmit}
      onRegisteredPhones={onRegisteredPhones}
    />
  );
};

export default RegistrationCompanyFormContainer;
