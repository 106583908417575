import GridCard from './GridCard';
import { ApproveUserCompanyBackend } from '../services/BackendFrontendInterfaces';
import { CardsGridCaller } from './GridCard';

interface MyApprovalsPresentationProps {
  id: CardsGridCaller;
  approvalsDataRaw: ApproveUserCompanyBackend[];
}

function MyApprovalsPresentation({
  id = 'myApprovals',
  approvalsDataRaw = [],
}: MyApprovalsPresentationProps) {
  return approvalsDataRaw?.length > 0 ? (
    <div>
      {approvalsDataRaw.length > 0 ? (
        <GridCard cardsData={approvalsDataRaw} id={`Grid${id}`} />
      ) : (
        <div>No se encontraron Calificaciones Positivas</div>
      )}
    </div>
  ) : (
    <h2>Cargando...</h2>
  );
}

export default MyApprovalsPresentation;
