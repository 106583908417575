import GridCard from './GridCard';
import { DeadWorkOfferBackend } from '../services/BackendFrontendInterfaces';
import { CardsGridCaller } from '../components/GridCard';

interface MyCompletedOffersPresentationProps {
  id: CardsGridCaller;
  offersDataRaw: DeadWorkOfferBackend[];
}

function MyCompletedOffersPresentation({
  id = 'myDeadOffers',
  offersDataRaw = [],
}: MyCompletedOffersPresentationProps) {
  return offersDataRaw?.length > 0 ? (
    <GridCard cardsData={offersDataRaw} id={id} />
  ) : (
    <h2>No Se encontraron trabajos completados disponibles</h2>
  );
}

export default MyCompletedOffersPresentation;
