import React, { useEffect, useState, useContext } from 'react';
import { makeRequest } from '../services/requests';
import { Category } from '../services/TreeView';
import EditCompanyFormPresentation from '../components/EditCompanyFormPresentation';
import CompanyTypeSpanishCat from '../data/company_type_spanish_cat.json';
import CompanyTypeSpanishSubCat from '../data/company_type_spanish_subcat.json';
import {
  CompanyFrontend,
  CompanyBackend,
  CompanyBackendUpdate,
} from '../services/BackendFrontendInterfaces';
import { SessionContext } from '../services/SessionContextProvider';

const EditCompanyFormContainer: React.FC = () => {
  const [companiesDataBackend, setCompaniesDataBackend] = useState<
    CompanyBackend[] | null
  >(null);
  const [readOnly, setReadOnly] = useState<boolean>(true);
  const [selectedCompanyName, setSelectedCompanyName] = useState<string>('');
  const [selectedCompanyIndex, setSelectedCompanyIndex] = useState<
    number | null
  >(null);

  const { myCompaniesBackendCache, setMyCompaniesBackendCache } =
    useContext(SessionContext);

  const [formValues, setFormValues] = useState<
    Omit<CompanyFrontend, 'constitutiveAct'>
  >({
    companyName: '',
    rfc: '',
    companyType: {},
    identityValidated: false,
    companyPhones: [],
    rate_approved: 0,
    approved_by: 0,
    rate_disapproved: 0,
    disapproved_by: 0,
    rate_works_evaluated: 0,
    works_evaluated: 0,
  });

  const fetchCompaniesData = async () => {
    try {
      const path = `company/get_my_companies`;
      const method = 'GET';
      let data = myCompaniesBackendCache;
      if (data == null) {
        const response = await makeRequest({ path: path, method: method });
        data = await response.json();
        setMyCompaniesBackendCache(data);
      }
      setCompaniesDataBackend(data);
      setSelectedCompanyIndex(0);
    } catch (error: unknown) {
      if (error instanceof Error) {
        alert(error.message);
      } else if (
        typeof error === 'object' &&
        error !== null &&
        'message' in error &&
        'componentStack' in error
      ) {
        const reactError = error as React.ErrorInfo;
        alert(reactError);
      } else {
        alert(JSON.stringify(error));
      }
    }
  };

  useEffect(() => {
    fetchCompaniesData();
  }, []);

  const companyPhonesBackendtoFrontend = () => {
    const toReturn: CompanyFrontend['companyPhones'] = [];
    if (companiesDataBackend !== null) {
      if (selectedCompanyIndex !== null) {
        if (companiesDataBackend[selectedCompanyIndex] !== null) {
          if (
            companiesDataBackend[selectedCompanyIndex].company_phones !== null
          ) {
            companiesDataBackend[selectedCompanyIndex].company_phones.forEach(
              (phone) => {
                const ph: CompanyFrontend['companyPhones'][0] = {
                  companyPhone: phone.company_phone.toString(),
                  extension:
                    phone.extension != null ? phone.extension.toString() : '',
                };
                toReturn.push(ph);
              },
            );
          }
        }
      }
    }
    return toReturn;
  };

  const companyTypesBackendToFrontend = () => {
    const toReturn: CompanyFrontend['companyType'] = {};

    if (companiesDataBackend !== null) {
      if (selectedCompanyIndex !== null) {
        if (companiesDataBackend[selectedCompanyIndex] !== null) {
          if (
            companiesDataBackend[selectedCompanyIndex].company_types !== null
          ) {
            companiesDataBackend[selectedCompanyIndex].company_types.forEach(
              (company_type, index) => {
                if (
                  !Object.keys(toReturn).includes(
                    CompanyTypeSpanishCat.CompanyTypeSpanishCat[
                      company_type['service_type']
                    ],
                  )
                ) {
                  toReturn[
                    CompanyTypeSpanishCat.CompanyTypeSpanishCat[
                      company_type['service_type']
                    ]
                  ] = [company_type['product_type']];
                } else {
                  toReturn[
                    CompanyTypeSpanishCat.CompanyTypeSpanishCat[
                      company_type['service_type']
                    ]
                  ] = [
                    ...toReturn[
                      CompanyTypeSpanishCat.CompanyTypeSpanishCat[
                        company_type['service_type']
                      ]
                    ],
                    company_type['product_type'],
                  ];
                }
              },
            );
          }
        }
      }
    }
    return toReturn;
  };

  const populateFrontend = () => {
    if (companiesDataBackend !== null) {
      if (selectedCompanyIndex !== null) {
        setFormValues({
          companyName: companiesDataBackend[selectedCompanyIndex].company_name,
          rfc: companiesDataBackend[selectedCompanyIndex].rfc,
          rate_approved:
            companiesDataBackend[selectedCompanyIndex].rate_approved,
          approved_by: companiesDataBackend[selectedCompanyIndex].approved_by,
          works_evaluated:
            companiesDataBackend[selectedCompanyIndex].works_evaluated,
          rate_works_evaluated:
            companiesDataBackend[selectedCompanyIndex].rate_works_evaluated,
          rate_disapproved:
            companiesDataBackend[selectedCompanyIndex].rate_disapproved,
          disapproved_by:
            companiesDataBackend[selectedCompanyIndex].disapproved_by,
          companyType: companyTypesBackendToFrontend(),
          companyPhones: companyPhonesBackendtoFrontend(),
        });
        setFormValues((prevValues) => ({
          ...prevValues,
          companyPhones: companyPhonesBackendtoFrontend(),
        }));
      }
    }
  };

  useEffect(() => {
    populateFrontend();
  }, [selectedCompanyIndex]);

  const categoriesData = (() => {
    const result: Category = {};
    for (
      var i = 0;
      i < CompanyTypeSpanishCat.CompanyTypeSpanishCat.length;
      i++
    ) {
      const key = CompanyTypeSpanishCat.CompanyTypeSpanishCat[i];
      const values = CompanyTypeSpanishSubCat.CompanyTypeSpanishSubCat;
      result[key] = [...values];
    }
    return result;
  })();

  const treeHandler = (selection: { [key: string]: string[] }) => {
    const selectionKey = Object.keys(selection)[0];
    const selectionValue = selection[selectionKey][0];

    setFormValues((prevValues) => {
      const updatedCompanyType = {
        ...prevValues.companyType,
        [selectionKey]: prevValues.companyType[selectionKey]?.includes(
          selectionValue,
        )
          ? prevValues.companyType[selectionKey].filter(
              (element) => element !== selectionValue,
            )
          : [...(prevValues.companyType[selectionKey] || []), selectionValue],
      };

      if (updatedCompanyType[selectionKey].length === 0) {
        delete updatedCompanyType[selectionKey];
      }

      return {
        ...prevValues,
        companyType: updatedCompanyType,
      };
    });
  };

  const handlePhonesRegistered = (phonesList: any[]) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      companyPhones: phonesList,
    }));
  };

  const handleCheckboxCompanyTypeChange = (
    options: Record<string, string[]>,
  ) => {
    setFormValues((prevValues) => ({ ...prevValues, companyType: options }));
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const companyPhonesFrontendToBackend = (
    companyPhones: CompanyFrontend['companyPhones'],
  ) => {
    const toReturn: CompanyBackend['company_phones'] = [];

    companyPhones.forEach((phone) => {
      const ph: CompanyBackend['company_phones'][0] = {
        company_phone: parseInt(phone.companyPhone),
        ...(phone.extension && { extension: parseInt(phone.extension) }),
      };

      toReturn.push(ph);
    });
    return toReturn;
  };

  const companyTypesFrontendToBackend = (
    companyType: CompanyFrontend['companyType'],
  ) => {
    const toReturn: CompanyBackend['company_types'] = [];

    Object.keys(companyType).forEach((companyTypeKey) => {
      companyType[companyTypeKey].forEach((productType) => {
        const companyTypeBackend: CompanyBackend['company_types'][0] = {
          service_type:
            CompanyTypeSpanishCat.CompanyTypeSpanishCat.indexOf(companyTypeKey),
          product_type: productType,
        };
        toReturn.push(companyTypeBackend);
      });
    });
    return toReturn;
  };

  function arraysAreEqualIgnoreCaseAndUnordered(
    arr1: string[],
    arr2: string[],
  ): boolean {
    if (arr1.length !== arr2.length) {
      return false;
    }
    const arr1Lower = arr1.map((item) => item.toLowerCase());
    for (const item of arr1Lower) {
      if (!arr2.includes(item.toLowerCase())) {
        return false;
      }
    }
    return true;
  }

  function areCompanyTypesEqual(
    oldCompanyType: CompanyFrontend['companyType'],
    newCompanyType: CompanyFrontend['companyType'],
  ): boolean {
    const oldKeys = Object.keys(oldCompanyType);
    const newKeys = Object.keys(newCompanyType);
    if (!arraysAreEqualIgnoreCaseAndUnordered(oldKeys, newKeys)) {
      return false;
    }
    for (const key of oldKeys) {
      if (
        !arraysAreEqualIgnoreCaseAndUnordered(
          oldCompanyType[key],
          newCompanyType[key],
        )
      ) {
        return false;
      }
    }
    return true;
  }

  function areCompanyPhonesEqual(
    oldCompanyPhones: CompanyFrontend['companyPhones'],
    newCompanyPhones: CompanyFrontend['companyPhones'],
  ): boolean {
    if (oldCompanyPhones.length !== newCompanyPhones.length) {
      return false;
    }
    for (const oldPhone of oldCompanyPhones) {
      const matchingNewPhone = newCompanyPhones.find(
        (newPhone) =>
          newPhone.companyPhone === oldPhone.companyPhone &&
          (newPhone.extension || '') === (oldPhone.extension || ''),
      );
      if (!matchingNewPhone) {
        return false;
      }
    }
    return true;
  }

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (readOnly) {
      setReadOnly(false);
    } else {
      try {
        const obj_to_send: CompanyBackendUpdate = {
          company_name: formValues.companyName,
          company_types: areCompanyTypesEqual(
            formValues.companyType,
            companyTypesBackendToFrontend(),
          )
            ? undefined
            : companyTypesFrontendToBackend(formValues.companyType),
          company_phones: areCompanyPhonesEqual(
            formValues.companyPhones,
            companyPhonesBackendtoFrontend(),
          )
            ? undefined
            : companyPhonesFrontendToBackend(formValues.companyPhones),
        };
        const path = `company/update_my_company/company_id/${
          companiesDataBackend![selectedCompanyIndex!]['id']
        }`; // TODO Or delete my company
        const method = 'PATCH';

        const content_type = 'application/json';
        setMyCompaniesBackendCache(null);
        const response = await makeRequest({
          path: path,
          method: method,
          body: JSON.stringify(obj_to_send),
          contentType: content_type,
        });

        if (!response.ok) {
          const response_json = await response.json();
          throw new Error(JSON.stringify(response_json.detail));
        }

        // Mostrar una notificación de éxito
        alert('Cambio exitoso');
        // Recargar el componente
        window.location.reload();
      } catch (error: unknown) {
        if (error instanceof Error) {
          alert(error.message);
        } else if (
          typeof error === 'object' &&
          error !== null &&
          'message' in error &&
          'componentStack' in error
        ) {
          const reactError = error as React.ErrorInfo;
          alert(reactError);
        } else {
          alert(JSON.stringify(error));
        }
      }
    }
  };

  return (
    <EditCompanyFormPresentation
      treeCategories={categoriesData}
      treeHandler={treeHandler}
      companyData={companiesDataBackend}
      readOnly={readOnly}
      phones={formValues.companyPhones}
      selectedOptionsCompanyType={formValues.companyType}
      selectedCompanyIndex={selectedCompanyIndex}
      selectedCompanyName={selectedCompanyName}
      formValues={formValues}
      setSelectedCompanyName={(event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedCompanyName(event.target.value);
      }}
      handleCheckboxCompanyTypeChange={handleCheckboxCompanyTypeChange}
      handlePhonesRegistered={handlePhonesRegistered}
      handleInputChange={handleInputChange}
      handleSubmit={handleSubmit}
    />
  );
};

export default EditCompanyFormContainer;
