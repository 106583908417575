import React, { useEffect, useContext } from 'react';
import SectionPresentation from '../components/SectionPresentation';
import { makeRequest, isLogged } from '../services/requests';
import { SessionContext } from '../services/SessionContextProvider';

interface SectionProps {
  selectedOption: string;
}

function SectionContainer({ selectedOption }: SectionProps) {
  const { setLoggedUser, setComponentSelected } = useContext(SessionContext);

  async function logout() {
    try {
      const path = `user/logout`;
      const method = 'GET';
      const response = await makeRequest({ path: path, method: method });

      if (!response.ok) {
        const response_json = await response.json();
        throw new Error(JSON.stringify(response_json.detail));
      } else {
        setLoggedUser(isLogged());
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        alert(error.message);
      } else if (
        typeof error === 'object' &&
        error !== null &&
        'message' in error &&
        'componentStack' in error
      ) {
        const reactError = error as React.ErrorInfo;
        alert(reactError);
      } else {
        alert(JSON.stringify(error));
      }
    }
  }

  useEffect(() => {
    if (selectedOption === 'logout') {
      logout();
    }
  }, [selectedOption]);

  return (
    <SectionPresentation
      selectedOption={selectedOption}
      setLoggedUser={setLoggedUser}
      setComponentSelected={setComponentSelected}
    />
  );
}

export default SectionContainer;
