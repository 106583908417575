import React from 'react';
import { CurrencyInputProps } from '../services/CurrencyInputServices';

const CurrencyInputPresentation: React.FC<CurrencyInputProps> = ({
  id = 'CurrencyInput',
  label = 'MXN $',
  required,
  readOnly = false,
  value = '',
  onChange,
}) => {
  return (
    <div>
      <label htmlFor={`input${id}`} id={`label${id}`}>
        {label}
      </label>
      <input
        id={`input${id}`}
        type="text"
        value={value}
        required={required}
        disabled={readOnly}
        onChange={onChange}
        onFocus={(e) => {
          const input = e.currentTarget;
          const length = input.value.length;
          input.setSelectionRange(length, length);
        }}
      />
    </div>
  );
};

export default CurrencyInputPresentation;
