import React, { useState, useEffect } from 'react';
import CustomizedMenuListPresentation from '../components/CustomizedMenuListPresentation';

/**
 * This component is the container part of a customized menu list
 * @options: the lis of options to select, string options
 * @onItemClick: a function to spread the option selected to its caller
 * @readOnly: a boolean that defines if the component is to show the option seleted or to select one option
 */

interface CustomizedMenuListContainerProps {
  id?: string;
  name?: string;
  options: string[];
  onItemClick: (selectedOption: React.ChangeEvent<HTMLSelectElement>) => void;
  readOnly?: boolean;
}

const CustomizedMenuListContainer: React.FC<
  CustomizedMenuListContainerProps
> = ({
  id = 'CustomizedMenuList',
  options,
  onItemClick,
  name = '',
  readOnly = false,
}) => {
  const [selectedOption, setSelectedOption] = useState<string>(options[0]);

  useEffect(() => {
    const selectedOptionEvent = {
      target: {
        value: selectedOption,
      },
    } as React.ChangeEvent<HTMLSelectElement>;
    onItemClick(selectedOptionEvent);
  }, [selectedOption]);

  const handleItemClick = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };

  return (
    <CustomizedMenuListPresentation
      id={id}
      name={name}
      options={options}
      readOnly={readOnly}
      selectedOption={selectedOption}
      onItemClick={handleItemClick}
    />
  );
};

export default CustomizedMenuListContainer;
