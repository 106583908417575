import React from 'react';

interface CheckboxListPresentationProps {
  options: string[];
  selectedValues: string[];
  checkboxListTitle: string;
  onCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CheckboxListPresentation: React.FC<CheckboxListPresentationProps> = ({
  options,
  checkboxListTitle,
  selectedValues,
  onCheckboxChange,
}) => {
  return (
    <div>
      <p>{checkboxListTitle}</p>
      {options.map((option, index) => (
        <div key={index}>
          <input
            type="checkbox"
            id={`checkbox-${index}`}
            value={option}
            checked={selectedValues.includes(option)}
            onChange={onCheckboxChange}
          />
          <label htmlFor={`checkbox-${index}`} id={`label-${index}`}>
            {option}
          </label>
        </div>
      ))}
    </div>
  );
};

export default CheckboxListPresentation;
