import React, { useState, useEffect, useContext } from 'react';
import { makeRequest } from '../services/requests';
import {
  LocationBackend,
  LocationFrontend,
} from '../services/BackendFrontendInterfaces';
import LocationFormPresentation from '../components/LocationFormPresentation';
import { SessionContext } from '../services/SessionContextProvider';
import constants from '../data/constants.json';
import '../styles/FromLogo.css';

interface LocationFormContainerProps {
  handleSubmitFather?: (event: React.FormEvent) => void;
  id?: string;
  onChange?: null | ((locationData: LocationFrontend) => void);
  readOnly?: boolean;
  locationDataRaw?: LocationBackend | null;
  buttonLabel?: string;
}

const LocationFormContainer: React.FC<LocationFormContainerProps> = ({
  handleSubmitFather,
  id = 'Location',
  onChange = null,
  readOnly = false,
  locationDataRaw = null,
  buttonLabel = 'Registrar Ubicación',
}) => {
  const [innerReadOnly, setInnerReadOnly] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const localCurrenciesList = constants.currencies_spanish;

  const { setMyLocationsBackendCache } = useContext(SessionContext);
  const [formValues, setFormValues] = useState<LocationFrontend>({
    country: '',
    state: '',
    city: '',
    neighborhood: '',
    district: '',
    postCode: '',
    street: '',
    building: '',
    outerNumber: '',
    outerLetter: '',
    innerNumber: '',
    innerLetter: '',
    localCurrency: Object.keys(localCurrenciesList).indexOf('MXN'),
    gpsCenterMapsLink: '',
    longitude: 99999,
    latitude: -99999,
    proofOfAddress: null,
  });

  useEffect(() => {
    setInnerReadOnly(readOnly);
    if (locationDataRaw !== null) {
      setFormValues((prevValues) => ({
        ...prevValues,
        country: locationDataRaw.country.country_name,
        state: locationDataRaw.state.state_name,
        city: locationDataRaw.city.city_name,
        neighborhood: locationDataRaw.neighborhood.neighborhood_name,
        district:
          locationDataRaw.district != null
            ? locationDataRaw.district.district_name
            : '',
        postCode: locationDataRaw.post_code.value,
        street: locationDataRaw.street.street_name,
        building:
          locationDataRaw.building != null
            ? locationDataRaw.building.building_name
            : '',
        outerNumber: locationDataRaw.outer_number.toString(),
        outerLetter:
          locationDataRaw.outer_letter != null
            ? locationDataRaw.outer_letter
            : '',
        innerNumber:
          locationDataRaw.inner_number != null
            ? locationDataRaw.inner_number.toString()
            : '',
        innerLetter:
          locationDataRaw.inner_letter != null
            ? locationDataRaw.inner_letter
            : '',
        localCurrency: locationDataRaw.local_currency,
        gpsCenterMapsLink: locationDataRaw.gps_center_maps_link,
        longitude: locationDataRaw.longitude,
        latitude: locationDataRaw.latitude,
      }));
    }
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (onChange != null) {
      onChange(formValues);
    }
  }, [formValues]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      const obj_to_send: LocationBackend =
        innerReadOnly && locationDataRaw != null
          ? locationDataRaw
          : {
              outer_number: parseInt(formValues.outerNumber),
              outer_letter:
                formValues.outerLetter !== ''
                  ? formValues.outerLetter
                  : undefined,
              inner_number:
                formValues.innerNumber !== ''
                  ? parseInt(formValues.innerNumber)
                  : undefined,
              inner_letter:
                formValues.innerLetter !== ''
                  ? formValues.innerLetter
                  : undefined,
              local_currency: formValues.localCurrency,
              gps_center_maps_link: formValues.gpsCenterMapsLink,
              longitude: formValues.longitude,
              latitude: formValues.latitude,
              country: {
                country_name: formValues.country,
              },
              state: {
                state_name: formValues.state,
              },
              city: {
                city_name: formValues.city,
              },
              neighborhood: {
                neighborhood_name: formValues.neighborhood,
              },
              district:
                formValues.district !== ''
                  ? {
                      district_name: formValues.district,
                    }
                  : { district_name: '' },
              post_code: {
                value: formValues.postCode,
              },
              street: {
                street_name: formValues.street,
              },
              building:
                formValues.building !== ''
                  ? {
                      building_name: formValues.building,
                    }
                  : { building_name: '' },
            };
      const path = innerReadOnly
        ? `location/delete_my_location`
        : `location/register_my_location`;
      const method = innerReadOnly ? 'DELETE' : 'POST';

      const content_type = 'application/json';
      setMyLocationsBackendCache(null);
      const response = await makeRequest({
        path: path,
        method: method,
        body: JSON.stringify(obj_to_send),
        contentType: content_type,
      });

      if (!response.ok) {
        const response_json = await response.json();
        throw new Error(JSON.stringify(response_json.detail));
      }
      if (
        path === 'location/register_my_location' &&
        formValues.proofOfAddress
      ) {
        const header = {
          Accept: 'application/json',
        };
        const my_location: LocationBackend = await response.json();

        const pathToProofOfAddress = `location/proof_of_address/${my_location.id!.toString()}`;
        const formData = new FormData();
        formData.append('proof_of_address', formValues.proofOfAddress);

        const result = await makeRequest({
          path: pathToProofOfAddress,
          method: 'POST',
          body: formData,
          header: header,
        });
      }
      const output_message = innerReadOnly
        ? `Borrado exitoso`
        : `Registro exitoso`;
      alert(output_message);
      window.location.reload();
    } catch (error: unknown) {
      if (error instanceof Error) {
        alert(error.message);
      } else if (
        typeof error === 'object' &&
        error !== null &&
        'message' in error &&
        'componentStack' in error
      ) {
        const reactError = error as React.ErrorInfo;
        alert(reactError);
      } else {
        alert(JSON.stringify(error));
      }
    }
  };

  return (
    <LocationFormPresentation
      id={id}
      loaded={loaded}
      handleAutocompleteContainerChange={(value: {
        name: string | null;
        value: string[];
      }) => {
        let name: string;
        if (value.name !== null) {
          name = value.name;
        }
        setFormValues((prevValues) => ({
          ...prevValues,
          [name]: value.value[0],
        }));
      }}
      handleSubmit={
        handleSubmitFather != null ? handleSubmitFather : handleSubmit
      }
      handleSelectChangeCurrencyType={(
        event: React.ChangeEvent<HTMLSelectElement>,
      ) => {
        setFormValues((prevValues) => ({
          ...prevValues,
          localCurrency: Object.keys(localCurrenciesList).indexOf(
            event.target.value,
          ),
        }));
      }}
      handleInputChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
        if (name.includes('proofOfAddress')) {
          if (event.target.files != null) {
            setFormValues((prevValues) => ({
              ...prevValues,
              [name]: event.target.files![0],
            }));
          }
        }
      }}
      handleSelectedPosition={(posicion: { lng: number; lat: number }) => {
        setFormValues({
          ...formValues,
          longitude: posicion.lng,
          latitude: posicion.lat,
        });
        setShowMap(false);
      }}
      formValues={formValues}
      innerReadOnly={innerReadOnly}
      showMap={showMap}
      handleNextClick={() => {
        setShowMap(true);
      }}
      buttonLabel={buttonLabel}
    />
  );
};

export default LocationFormContainer;
