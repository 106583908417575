import React from 'react';
import TreeViewContainer from '../containers/TreeViewContainer';
import { Category, TreeContainerProps } from '../services/TreeView';
import CustomizedMenuListContainer from '../containers/CustomizedMenuListContainer';
import PhonesRgisterContainer from '../containers/PhonesRgisterContainer';
import {
  CompanyBackend,
  CompanyFrontend,
} from '../services/BackendFrontendInterfaces';

interface EditCompanyFormPresentationProps {
  id?: string;
  treeCategories: Category;
  treeHandler: TreeContainerProps['treeHandler'];
  companyData: CompanyBackend[] | null;
  readOnly: boolean;
  phones: CompanyFrontend['companyPhones'];
  selectedOptionsCompanyType: Record<string, string[]>;
  selectedCompanyIndex: number | null;
  selectedCompanyName: string;
  formValues: any; // Replace with the actual type
  setSelectedCompanyName: (
    selectedOption: React.ChangeEvent<HTMLSelectElement>,
  ) => void;
  handleCheckboxCompanyTypeChange: (options: Record<string, string[]>) => void;
  handlePhonesRegistered: (
    phonesList: CompanyFrontend['companyPhones'],
  ) => void;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (event: React.FormEvent) => void;
}

const EditCompanyFormPresentation: React.FC<
  EditCompanyFormPresentationProps
> = ({
  id = 'EditCompany',
  companyData = null,
  readOnly,
  phones,
  treeCategories = {},
  formValues,
  setSelectedCompanyName,
  handlePhonesRegistered,
  handleInputChange,
  handleSubmit,
  treeHandler,
}) => {
  return (
    <div>
      {companyData !== null ? (
        <div>
          <form className="App" onSubmit={handleSubmit} id={`Form${id}`}>
            <div>
              <CustomizedMenuListContainer
                id={`CustomizedMenuList${id}`}
                options={companyData.map((company) => company.company_name)}
                onItemClick={setSelectedCompanyName}
                readOnly={false}
              />
            </div>

            <br />
            <div>
              <p>Tipo De Empresa:</p>
              <p>{JSON.stringify(formValues.companyType)}</p>
              <TreeViewContainer
                readOnly={readOnly}
                id={`treeView${id}`}
                categories={treeCategories}
                treeHandler={treeHandler}
              />
            </div>
            <br />
            <div>
              <label htmlFor={`input${id}RFC`} id={`label${id}RFC`}>
                RFC
              </label>
              <input
                id={`input${id}RFC`}
                name="rfc"
                required
                disabled={readOnly}
                value={formValues.rfc}
                onInput={handleInputChange}
              />
            </div>
            <br />
            <div>
              <h2 id={`h2${id}ApprovedBy`} style={{ color: '#004d26' }}>
                Número De Evaluaciones Positivas: {formValues.approved_by}
              </h2>
              <h2 id={`h2${id}RateApproved`} style={{ color: '#004d26' }}>
                Promedio Evaluaciones Positivas: {formValues.rate_approved}
              </h2>
            </div>
            <br />
            <div>
              <h2 id={`h2${id}DisapprovedBy`} style={{ color: '#5c1a0f' }}>
                Número De Evaluaciones Negativas: -{formValues.disapproved_by}
              </h2>
              <h2 id={`h2${id}RateDisapproved`} style={{ color: '#5c1a0f' }}>
                Promedio Evaluaciones Negativas: {formValues.rate_disapproved}
              </h2>
            </div>
            <br />
            <div>
              {formValues.works_evaluated != null ? (
                <div>
                  <h2 id={`h2${id}WorksEvaluated`} style={{ color: '#330033' }}>
                    Número De Trabajos Evaluados: {formValues.works_evaluated}
                  </h2>
                  <h2
                    id={`h2${id}RateWorksEvaluated`}
                    style={{ color: '#330033' }}
                  >
                    Promedio Evaluación De Trabajos Evaluados:
                    {formValues.rate_works_evaluated}
                  </h2>
                </div>
              ) : (
                <h2></h2>
              )}
            </div>
            <div>
              <button id={`button${id}EditSubmit`} type="submit">
                {readOnly ? 'EDITAR' : 'Enviar Formulario'}
              </button>
            </div>
            <br />
            <div>
              {phones.length > 0 ? (
                <PhonesRgisterContainer
                  id={`PhonesRgister${id}`}
                  onRegisteredPhones={handlePhonesRegistered}
                  initialPhones={phones}
                  readOnly={readOnly}
                />
              ) : (
                <p>Cargando...</p>
              )}
            </div>
          </form>
        </div>
      ) : (
        <div>Cargando...</div>
      )}
    </div>
  );
};

export default EditCompanyFormPresentation;
