import GridCard from '../components/GridCard';
import { CardsGridCaller } from '../components/GridCard';
import React, { useState, useEffect, useContext } from 'react';
import { makeRequest } from '../services/requests';
import ReviewMyCompaniesPublishedWorksPresentation from '../components/ReviewMyCompaniesPublishedWorksPresentation';
import {
  WorkOfferBackend,
  LocationBackend,
  WorkCompanyBackendFromAPI,
  WorkUserBackend,
  WorkUserFrontend,
  WorkOfferWMercadolibreBackend,
} from '../services/BackendFrontendInterfaces';
import {
  isWorkCompanyBackendFromAPI,
  isWorkCompanyBackend,
} from '../services/InterfaceTypeValidator';
import PublishWorkContainer from './PublishWorkContainer';
import { prepareWorkTypeData } from '../services/PublishWorkService';
import PublishWorkForMyCompanyContainer from './PublishWorkForMyCompanyContainer';
import constants from '../data/constants.json';
import { SessionContext } from '../services/SessionContextProvider';
import EvaluateWorkContainer from './EvaluateWorkContainer';
import ArbitrationWorkContainer from './ArbitrationContainer';
import Payment from '../components/Payment';

interface ReviewMyCompaniesPublishedWorksProps {
  id?: CardsGridCaller;
  buttonLabel?: string;
}

function ReviewMyCompaniesPublishedWorksContainer({
  id = 'ReviewMyCompaniesPublishedWorks',
  buttonLabel,
}: ReviewMyCompaniesPublishedWorksProps) {
  const [offerToAccept, setOfferToAccept] =
    useState<WorkOfferWMercadolibreBackend | null>(null);
  const localCurrenciesList = constants.currencies_spanish;
  const [worksDataRaw, setWorksDataRaw] = useState<
    WorkCompanyBackendFromAPI[] | null
  >(null);
  const [worksToEditDelete, setWorksToEditDelete] =
    useState<WorkCompanyBackendFromAPI | null>(null);
  const [workToComplete, setWorkToComplete] =
    useState<WorkCompanyBackendFromAPI | null>(null);
  const [workToArbitrate, setWorkToArbitrate] =
    useState<WorkCompanyBackendFromAPI | null>(null);
  const [locationSelected, setLocationSelected] = useState<
    LocationBackend | undefined
  >(undefined);
  const { offersForMyCompaniesWorksCache, setOffersForMyCompaniesWorksCache } =
    useContext(SessionContext);
  const [offersForThisJob, setOffersForThisJob] = useState<
    WorkOfferBackend[] | null
  >(null);

  const [formValues, setFormValues] = useState<WorkUserFrontend>({
    summary: '',
    workType: {},
    workBudget: 0,
    // @ts-ignore
    location: {
      country: '',
      state: '',
      city: '',
      neighborhood: '',
      district: '',
      postCode: '',
      street: '',
      building: '',
      outerNumber: '',
      outerLetter: '',
      innerNumber: '',
      innerLetter: '',
      localCurrency: Object.keys(localCurrenciesList).indexOf('MXN'),
      gpsCenterMapsLink: '',
      longitude: 99999,
      latitude: -99999,
    },
    selectedStartDateTime: '',
    selectedEndDateTime: '',
  });

  const workEditDeleteOrCloseWork = (cardData: WorkCompanyBackendFromAPI) => {
    if (cardData.work.taken_by == null) {
      setWorksToEditDelete(cardData);
    } else {
      setWorkToComplete(cardData);
    }
  };

  const acceptOffer = async (cardData: WorkOfferBackend) => {
    try {
      const path = `offers/accept_offer_to_work`;
      const method = 'POST';

      const content_type = 'application/json';
      const response = await makeRequest({
        path: path,
        method: method,
        body: JSON.stringify(cardData),
        contentType: content_type,
      });

      if (!response.ok) {
        const response_json = await response.json();
        throw new Error(JSON.stringify(response_json.detail));
      }

      const data = await response.json();
      setOfferToAccept(data);
    } catch (error: unknown) {
      if (error instanceof Error) {
        alert(error.message);
      } else if (
        typeof error === 'object' &&
        error !== null &&
        'message' in error &&
        'componentStack' in error
      ) {
        const reactError = error as React.ErrorInfo;
        alert(reactError);
      } else {
        alert(JSON.stringify(error));
      }
    }
  };

  const viewOffersOrArbitrationRequest = async (
    cardData: WorkCompanyBackendFromAPI,
  ) => {
    if (cardData.work.taken_by != null) {
      setWorkToArbitrate(cardData);
    } else {
      let offersData: WorkOfferBackend[] | null =
        offersForMyCompaniesWorksCache;
      if (offersForMyCompaniesWorksCache == null) {
        const path = `offers/get_offers_for_my_companies_works`;
        const method = 'GET';
        try {
          const response = await makeRequest({ path: path, method: method });
          const data: WorkOfferBackend[] = await response.json();
          setOffersForMyCompaniesWorksCache(data);
          offersData = data;
        } catch (error: unknown) {
          if (error instanceof Error) {
            alert(error.message);
          } else if (
            typeof error === 'object' &&
            error !== null &&
            'message' in error &&
            'componentStack' in error
          ) {
            const reactError = error as React.ErrorInfo;
            alert(reactError);
          } else {
            alert(JSON.stringify(error));
          }
        }
      }
      const offersForThisJob = offersData?.filter(
        (offer) => cardData.work.id == offer.work.id,
      );
      setOffersForThisJob(offersForThisJob != null ? offersForThisJob : null);
    }
  };

  const workDelete = async () => {
    const path = `works/delete_my_company_work/work_id/${
      worksToEditDelete!.work.id
    }`;
    const method = 'DELETE';
    try {
      const response = await makeRequest({ path: path, method: method });
      const data: WorkUserBackend[] = await response.json();
      // Mostrar una notificación de éxito
      alert('Trabajo Borrado Correctamente');
      // Recargar el componente
      window.location.reload();
    } catch (error: unknown) {
      if (error instanceof Error) {
        alert(error.message);
      } else if (
        typeof error === 'object' &&
        error !== null &&
        'message' in error &&
        'componentStack' in error
      ) {
        const reactError = error as React.ErrorInfo;
        alert(reactError);
      } else {
        alert(JSON.stringify(error));
      }
    }
  };

  useEffect(() => {
    async function fetchData() {
      const path = `works/get_my_companies_published_works/`;
      const method = 'GET';
      try {
        const response = await makeRequest({ path: path, method: method });
        const data: WorkCompanyBackendFromAPI[] = await response.json();
        setWorksDataRaw(data);
      } catch (error: unknown) {
        if (error instanceof Error) {
          alert(error.message);
        } else if (
          typeof error === 'object' &&
          error !== null &&
          'message' in error &&
          'componentStack' in error
        ) {
          const reactError = error as React.ErrorInfo;
          alert(reactError);
        } else {
          alert(JSON.stringify(error));
        }
      }
    }
    fetchData();
  }, [id]);

  const fixDate = (date: string): string => {
    const originalDate: Date = new Date(date);
    const formattedDate: string = originalDate.toISOString().slice(0, -1);
    return formattedDate;
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    let obj_to_send;
    if (locationSelected != null) {
      obj_to_send = {
        summary: formValues.summary,
        work_budget: formValues.workBudget,
        start_date_time: fixDate(formValues.selectedStartDateTime),
        end_date_time: fixDate(formValues.selectedEndDateTime),
        location: locationSelected,
        work_type: prepareWorkTypeData(formValues.workType)[0],
      };
    } else {
      obj_to_send = {
        summary: formValues.summary,
        work_budget: formValues.workBudget,
        start_date_time: fixDate(formValues.selectedStartDateTime),
        end_date_time: fixDate(formValues.selectedEndDateTime),
        location: {
          outer_number: parseInt(formValues.location.outerNumber),
          outer_letter:
            formValues.location.outerLetter !== ''
              ? formValues.location.outerLetter
              : undefined,
          inner_number:
            formValues.location.innerNumber !== ''
              ? parseInt(formValues.location.innerNumber)
              : undefined,
          inner_letter:
            formValues.location.innerLetter !== ''
              ? formValues.location.innerLetter
              : undefined,
          local_currency: formValues.location.localCurrency,
          gps_center_maps_link: formValues.location.gpsCenterMapsLink,
          longitude: formValues.location.longitude,
          latitude: formValues.location.latitude,
          country: {
            country_name: formValues.location.country,
          },
          state: {
            state_name: formValues.location.state,
          },
          city: {
            city_name: formValues.location.city,
          },
          neighborhood: {
            neighborhood_name: formValues.location.neighborhood,
          },
          district:
            formValues.location.district !== ''
              ? {
                  district_name: formValues.location.district,
                }
              : { district_name: '' },
          post_code: {
            value: formValues.location.postCode,
          },
          street: {
            street_name: formValues.location.street,
          },
          building:
            formValues.location.building !== ''
              ? {
                  building_name: formValues.location.building,
                }
              : { building_name: '' },
        },
        work_type: prepareWorkTypeData(formValues.workType)[0],
      };
    }
    try {
      const path = `works/update_work_for_my_company/company_id/${
        worksToEditDelete!.company_id
      }/work_id/${worksToEditDelete!.work.id}`;
      const method = `PATCH`;

      const content_type = 'application/json';
      const response = await makeRequest({
        path: path,
        method: method,
        body: JSON.stringify(obj_to_send),
        contentType: content_type,
      });

      if (!response.ok) {
        const response_json = await response.json();
        throw new Error(JSON.stringify(response_json.detail));
      }

      const output_message = `Trabajo Actualizado Correctamente`;
      alert(output_message);
      window.location.reload();
    } catch (error: unknown) {
      if (error instanceof Error) {
        alert(error.message);
      } else if (
        typeof error === 'object' &&
        error !== null &&
        'message' in error &&
        'componentStack' in error
      ) {
        const reactError = error as React.ErrorInfo;
        alert(reactError);
      } else {
        alert(JSON.stringify(error));
      }
    }
  };

  useEffect(() => {
    if (locationSelected != null) {
      const dummyEvent = {
        preventDefault: () => {},
        target: {},
      } as React.FormEvent;
      handleSubmit(dummyEvent);
    }
  }, [locationSelected]);

  if (offerToAccept != null) {
    return <Payment paymentData={offerToAccept} />;
  } else if (workToArbitrate != null) {
    return <ArbitrationWorkContainer workOrOfferDataRaw={workToArbitrate} />;
  } else if (workToComplete != null) {
    return <EvaluateWorkContainer workDataRaw={workToComplete} />;
  } else if (offersForThisJob != null) {
    return (
      <GridCard
        cardsData={offersForThisJob}
        handleButtonCardOne={acceptOffer}
        buttonLabelCardOne={'Aceptar Oferta'}
        id={id}
      />
    );
  } else if (worksDataRaw === null) {
    return <h1>Cargando...</h1>;
  } else if (worksToEditDelete == null) {
    return (
      <ReviewMyCompaniesPublishedWorksPresentation
        id={id}
        worksDataRaw={worksDataRaw!}
        handleButtonCardOne={workEditDeleteOrCloseWork}
        handleButtonCardTwo={viewOffersOrArbitrationRequest}
        buttonLabelCardOne={(cardData: WorkCompanyBackendFromAPI) => {
          if (cardData.work.taken_by == null) {
            return 'Borrar/Editar Trabajo';
          } else {
            return 'Trabajo Completado';
          }
        }}
        buttonLabelCardTwo={(cardData: WorkCompanyBackendFromAPI) => {
          if (cardData.work.taken_by == null) {
            return 'Revisar Ofertas';
          } else {
            return 'Solicitar Arbitraje';
          }
        }}
      />
    );
  } else if (isWorkCompanyBackendFromAPI(worksToEditDelete)) {
    return (
      <PublishWorkContainer
        id={id}
        workDataRaw={worksToEditDelete}
        mode={'Edit'}
        buttonLabel={buttonLabel != null ? buttonLabel : 'Guardar Cambios'}
        workDelete={workDelete}
        handleSubmitFather={handleSubmit}
        onChange={setFormValues}
        setFatherLocationSelected={setLocationSelected}
      />
    );
  } else if (isWorkCompanyBackend(worksToEditDelete)) {
    return (
      <PublishWorkForMyCompanyContainer
        id={id}
        workDataRaw={[worksToEditDelete]}
        mode={'Edit'}
        buttonLabel={buttonLabel != null ? buttonLabel : 'Guardar Cambios'}
      />
    );
  } else {
    return <h1>No se encontró información...</h1>;
  }
}

export default ReviewMyCompaniesPublishedWorksContainer;
