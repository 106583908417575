import React, { useEffect, useState } from 'react';
import CurrencyInputContainer from '../containers/CurrencyInputContainer';
import { WorkOfferFrontend } from '../services/BackendFrontendInterfaces';
import ToolsContainer from '../containers/ToolsContainer';
import MaterialsContainer from '../containers/MaterialsContainer';
import ExternalWorksContainer from '../containers/ExtenalWorksContainer';

interface PresentationProps {
  fullBudgetOffer?: number;
  id?: string;
  innerReadOnly?: boolean;
  formValues: Omit<WorkOfferFrontend, 'user' | 'company'>;
  showForm: boolean;
  setstartDateTime: (date: string) => void;
  setendDateTime: (date: string) => void;
  handleBudgetChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (event: React.FormEvent) => void;
  onRegisteredTools: (tools: WorkOfferFrontend['tools']) => void;
  onRegisteredMaterials: (tools: WorkOfferFrontend['materials']) => void;
  onRegisteredExternalWorks: (
    tools: WorkOfferFrontend['externalWorks'],
  ) => void;
}

const MakeOfferPresentation: React.FC<PresentationProps> = ({
  fullBudgetOffer = 0.0,
  id = 'MakeOffer',
  innerReadOnly = false,
  formValues,
  showForm,
  setstartDateTime,
  setendDateTime,
  handleBudgetChange,
  handleSubmit,
  onRegisteredTools,
  onRegisteredMaterials,
  onRegisteredExternalWorks,
}) => {
  const defaultErrorMessage = 'Favor de no repetir conceptos, Revisa: ';
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    let errorM: string = '';
    let repeated: string[] = [];
    if (formValues.tools.length > 0) {
      const toolsList = formValues.tools.map((tool) => tool.tool);
      const toolSet: Set<string> = new Set(toolsList);
      if (toolsList.length != toolSet.size) {
        const counter: { [tool: string]: number } = {};
        toolsList.forEach((tool) => {
          if (counter[tool]) {
            counter[tool]++;
          } else {
            counter[tool] = 1;
          }
        });
        Object.keys(counter).forEach((tool) => {
          if (counter[tool] > 1 && tool != '') {
            repeated.push(tool);
          }
        });
      }
    }
    if (formValues.externalWorks.length > 0) {
      const externalWorksList = formValues.externalWorks.map(
        (externalWork) => externalWork.externalWork,
      );
      const externalWorkSet: Set<string> = new Set(externalWorksList);
      if (externalWorksList.length != externalWorkSet.size) {
        const counter: { [externalWork: string]: number } = {};
        externalWorksList.forEach((externalWork) => {
          if (counter[externalWork]) {
            counter[externalWork]++;
          } else {
            counter[externalWork] = 1;
          }
        });
        Object.keys(counter).forEach((externalWork) => {
          if (counter[externalWork] > 1 && externalWork != '') {
            repeated.push(externalWork);
          }
        });
      }
    }
    if (formValues.materials.length > 0) {
      const matarialsList = formValues.materials.map(
        (material) => material.material,
      );
      const materialSet: Set<string> = new Set(matarialsList);
      if (matarialsList.length != materialSet.size) {
        const counter: { [material: string]: number } = {};
        matarialsList.forEach((material) => {
          if (counter[material]) {
            counter[material]++;
          } else {
            counter[material] = 1;
          }
        });
        Object.keys(counter).forEach((material) => {
          if (counter[material] > 1 && material != '') {
            repeated.push(material);
          }
        });
      }
    }
    if (repeated.length > 0) {
      errorM = defaultErrorMessage;
      setErrorMessage(defaultErrorMessage);
      repeated.forEach((repeated) => {
        errorM = errorM + `${repeated}, `;
      });
    }
    setErrorMessage(errorM);
  }, [formValues]);

  return (
    <div>
      {(id.includes('notTakenWorks') ||
        id.includes('ReviewMyOffers') ||
        id.includes('ReviewMyCompaniesOffers')) && (
        <div>
          <div>
            <button
              onClick={handleSubmit}
              id={`button${id}MakeOffer`}
              type={'button'}
              disabled={errorMessage === '' ? false : true}
            >
              {id.includes('notTakenWorks')
                ? 'Hacer Oferta'
                : 'Modificar Oferta'}
            </button>
            <p
              style={{
                color: 'red',
                display: errorMessage === '' ? 'none' : 'flex',
              }}
              id={'makeOfferPresentationError'}
            >
              {errorMessage}
            </p>
          </div>
        </div>
      )}
      {showForm && (
        <div>
          <div style={{ width: '100%' }}>
            <h2>
              Presupuesto total: MXN ${fullBudgetOffer.toFixed(2).toString()}
            </h2>
            <label
              htmlFor={`CurrencyInput${id}LabourBudget`}
              id={`labelCurrencyInput${id}LabourBudget`}
            >
              Presupuesto De Mano De Obra:
            </label>
            <CurrencyInputContainer
              id={`CurrencyInput${id}LabourBudget`}
              name="laborBudget"
              label="Presupuesto De Mano De Obra"
              required={true}
              readOnly={innerReadOnly}
              value={formValues.laborBudget}
              onChange={handleBudgetChange}
            />
            <p>
              Presupuesto Mano De Obra: MXN $
              {formValues.laborBudget.toLocaleString()}
            </p>
          </div>
          <br />
          <div style={{ width: '100%' }}>
            <label htmlFor={`input${id}StartDate`} id={`label${id}StartDate`}>
              Fecha de Inicio
            </label>
            <input
              id={`input${id}StartDate`}
              name="startDateTime"
              type="datetime-local"
              disabled={innerReadOnly}
              value={
                formValues.startDateTime ||
                new Date().toISOString().slice(0, -8)
              }
              onChange={(e) =>
                setstartDateTime(e.target.value.toLocaleString())
              }
              min={new Date().toISOString().slice(0, -8)}
              style={{ width: '60%' }}
            />
            <p>
              Fecha De Inicio Propuesta:{' '}
              {formValues.startDateTime
                ? formValues.startDateTime
                : 'Seleccione Fecha y hora de inicio del trabajo.'}
            </p>
          </div>
          <br />
          <div style={{ width: '100%' }}>
            <label htmlFor={`input${id}EndDate`} id={`label${id}EndDate`}>
              Fecha de Terminado
            </label>
            <input
              id={`input${id}EndDate`}
              name="endDateTime"
              type="datetime-local"
              disabled={innerReadOnly}
              value={
                formValues.endDateTime || new Date().toISOString().slice(0, -8)
              }
              onChange={(e) => setendDateTime(e.target.value.toLocaleString())}
              min={new Date().toISOString().slice(0, -8)}
              style={{ width: '60%' }}
            />
            <p>
              Fecha De Terminado Propuesta:{' '}
              {formValues.endDateTime
                ? formValues.endDateTime
                : 'Seleccione Fecha y hora de término del trabajo.'}
            </p>
          </div>
          <ToolsContainer
            id="ToolsRegister"
            onRegisteredTools={onRegisteredTools}
            readOnly={innerReadOnly}
            initialTools={formValues.tools}
          />
          <MaterialsContainer
            id="MaterialRegister"
            onRegisteredMaterials={onRegisteredMaterials}
            readOnly={innerReadOnly}
            initialMaterials={formValues.materials}
          />
          <ExternalWorksContainer
            id="ExternalWorksRegister"
            onRegisteredExternalWorks={onRegisteredExternalWorks}
            readOnly={innerReadOnly}
            initialExternalWorks={formValues.externalWorks}
          />
        </div>
      )}
    </div>
  );
};

export default MakeOfferPresentation;
