import React, { useState, useEffect, useContext } from 'react';
import PublishWorkForMyCompanyPresentation from '../components/PublishWorkForMyCompanyPresentation';
import constants from '../data/constants.json';
import { makeRequest } from '../services/requests';
import CompanyTypeSpanishCat from '../data/company_type_spanish_cat.json';
import {
  WorkCompanyFrontend,
  WorkCompanyBackend,
  CompanyBackend,
  WorkUserFrontend,
  FormDataModes,
  LocationBackend,
} from '../services/BackendFrontendInterfaces';
import { prepareWorkTypeData } from '../services/PublishWorkService';
import { SessionContext } from '../services/SessionContextProvider';

const FormWithDataModes = constants.FormWithDataModes;

interface ContainerProps {
  id?: string;
  readOnly?: boolean;
  workDataRaw?: WorkCompanyBackend[];
  mode?: FormDataModes['value'];
  buttonLabel?: string;
}

const PublishWorkForMyCompanyContainer: React.FC<ContainerProps> = ({
  id = 'PublishWorkForMyCompany',
  readOnly = false,
  workDataRaw = [],
  mode = FormWithDataModes.Edit,
  buttonLabel,
}) => {
  const localCurrenciesList = constants.currencies_spanish;
  const [innerReadOnly, setInnerReadOnly] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [showForm, setshowForm] = useState(true);
  const [myCompaniesBackendList, setMyCompaniesBackendList] = useState<
    CompanyBackend[]
  >([]);
  const [companiesRFCList, setCompaniesRFCList] = useState<string[]>([]);
  const [locationSelected, setLocationSelected] = useState<
    LocationBackend | undefined
  >(undefined);

  const {
    myCompaniesBackendCache,
    setMyCompaniesBackendCache,
    setMyWorksCompaniesBackendCache,
    setMyWorksBackendCache,
  } = useContext(SessionContext);

  const [formValues, setFormValues] = useState<WorkCompanyFrontend>({
    company: '',
    work: {
      summary: '',
      workType: {},
      workBudget: 0,
      // @ts-ignore
      location: {
        country: '',
        state: '',
        city: '',
        neighborhood: '',
        district: '',
        postCode: '',
        street: '',
        building: '',
        outerNumber: '',
        outerLetter: '',
        innerNumber: '',
        innerLetter: '',
        localCurrency: Object.keys(localCurrenciesList).indexOf('MXN'),
        gpsCenterMapsLink: '',
        longitude: 99999,
        latitude: -99999,
      },
      selectedStartDateTime: '',
      selectedEndDateTime: '',
    },
  });

  useEffect(() => {
    if (locationSelected != null) {
      const dummyEvent = {
        preventDefault: () => {},
        target: {},
      } as React.FormEvent;
      handleSubmit(dummyEvent);
    }
  }, [locationSelected]);

  useEffect(() => {
    setInnerReadOnly(readOnly);
    if (workDataRaw.length > 0) {
      const category =
        CompanyTypeSpanishCat.CompanyTypeSpanishCat[
          workDataRaw[0].work.work_type.service_type
        ];
      const worker_type = workDataRaw[0].work.work_type.worker_type.type;
      const work_type = {
        category: '',
      };
      work_type.category = worker_type;
      setFormValues((prevValues) => ({
        ...prevValues,
        summary: workDataRaw[0].work.summary,
        work_type: [work_type],
        selectedStartDateTime: workDataRaw[0].work.start_date_time,
        SelectedEndDateTime: workDataRaw[0].work.end_date_time,
        workBudget:
          workDataRaw[0].work.work_budget != null
            ? workDataRaw[0].work.work_budget
            : '',
        country: workDataRaw[0].work.location.country.country_name,
        state: workDataRaw[0].work.location.state.state_name,
        city: workDataRaw[0].work.location.city.city_name,
        neighborhood:
          workDataRaw[0].work.location.neighborhood.neighborhood_name,
        district:
          workDataRaw[0].work.location.district != null
            ? workDataRaw[0].work.location.district.district_name
            : '',
        postCode: workDataRaw[0].work.location.post_code.value,
        street: workDataRaw[0].work.location.street.street_name,
        building:
          workDataRaw[0].work.location.building != null
            ? workDataRaw[0].work.location.building.building_name
            : '',
        outerNumber: workDataRaw[0].work.location.outer_number.toString(),
        outerLetter:
          workDataRaw[0].work.location.outer_letter != null
            ? workDataRaw[0].work.location.outer_letter
            : '',
        innerNumber:
          workDataRaw[0].work.location.inner_number != null
            ? workDataRaw[0].work.location.inner_number.toString()
            : '',
        innerLetter:
          workDataRaw[0].work.location.inner_letter != null
            ? workDataRaw[0].work.location.inner_letter
            : '',
        localCurrency: workDataRaw[0].work.location.local_currency,
        gpsCenterMapsLink: workDataRaw[0].work.location.gps_center_maps_link,
        longitude: workDataRaw[0].work.location.longitude,
        latitude: workDataRaw[0].work.location.latitude,
      }));
      const company = [];
      company.push(workDataRaw[0].company);
      setMyCompaniesBackendList(company);
    } else {
      const fetchData = async () => {
        try {
          let data =
            myCompaniesBackendCache === null ? [] : myCompaniesBackendCache;
          if (myCompaniesBackendCache == null) {
            const response = await makeRequest({
              path: 'company/get_my_companies',
              method: 'GET',
            });
            if (!response.ok) {
              const response_json = await response.json();
              throw new Error(JSON.stringify(response_json.detail));
            }
            data = await response.json();
            setMyCompaniesBackendCache(data);
          }
          setMyCompaniesBackendList(data);
        } catch (error: unknown) {
          if (error instanceof Error) {
            alert(error.message);
          } else if (
            typeof error === 'object' &&
            error !== null &&
            'message' in error &&
            'componentStack' in error
          ) {
            const reactError = error as React.ErrorInfo;
            alert(reactError);
          } else {
            alert(JSON.stringify(error));
          }
        }
      };
      fetchData();
    }
    setLoaded(true);
  }, []);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const fixDate = (date: string): string => {
      const originalDate: Date = new Date(date);
      const formattedDate: string = originalDate.toISOString().slice(0, -1);
      return formattedDate;
    };

    try {
      let obj_to_send: WorkCompanyBackend;
      if (locationSelected != null) {
        obj_to_send = {
          work: {
            summary: formValues.work.summary,
            work_budget: formValues.work.workBudget,
            start_date_time: fixDate(formValues.work.selectedStartDateTime),
            end_date_time: fixDate(formValues.work.selectedEndDateTime),
            location: locationSelected,
            work_type: prepareWorkTypeData(formValues.work.workType)[0],
          },
          company: findCompanyByRFC(formValues.company),
        };
      } else {
        obj_to_send = {
          work: {
            summary: formValues.work.summary,
            work_budget: formValues.work.workBudget,
            start_date_time: fixDate(formValues.work.selectedStartDateTime),
            end_date_time: fixDate(formValues.work.selectedEndDateTime),
            location: {
              outer_number: parseInt(formValues.work.location.outerNumber),
              outer_letter:
                formValues.work.location.outerLetter !== ''
                  ? formValues.work.location.outerLetter
                  : undefined,
              inner_number:
                formValues.work.location.innerNumber !== ''
                  ? parseInt(formValues.work.location.innerNumber)
                  : undefined,
              inner_letter:
                formValues.work.location.innerLetter !== ''
                  ? formValues.work.location.innerLetter
                  : undefined,
              local_currency: formValues.work.location.localCurrency,
              gps_center_maps_link: formValues.work.location.gpsCenterMapsLink,
              longitude: formValues.work.location.longitude,
              latitude: formValues.work.location.latitude,
              country: {
                country_name: formValues.work.location.country,
              },
              state: {
                state_name: formValues.work.location.state,
              },
              city: {
                city_name: formValues.work.location.city,
              },
              neighborhood: {
                neighborhood_name: formValues.work.location.neighborhood,
              },
              district:
                formValues.work.location.district !== ''
                  ? {
                      district_name: formValues.work.location.district,
                    }
                  : { district_name: '' },
              post_code: {
                value: formValues.work.location.postCode,
              },
              street: {
                street_name: formValues.work.location.street,
              },
              building:
                formValues.work.location.building !== ''
                  ? {
                      building_name: formValues.work.location.building,
                    }
                  : { building_name: '' },
            },
            work_type: prepareWorkTypeData(formValues.work.workType)[0],
          },
          company: findCompanyByRFC(formValues.company),
        };
      }
      const path = innerReadOnly
        ? `location/delete_work_for_me`
        : mode === FormWithDataModes.Edit
        ? `works/register_work_for_my_company`
        : `works/register_my_work`;
      const method = 'POST';

      const content_type = 'application/json';
      switch (path) {
        case 'works/register_my_work':
        case 'location/delete_work_for_me':
          setMyWorksBackendCache(null);
          break;
        case 'works/register_work_for_my_company':
          setMyWorksCompaniesBackendCache(null);
          break;
      }
      const response = await makeRequest({
        path: path,
        method: method,
        body: JSON.stringify(obj_to_send),
        contentType: content_type,
      });

      if (!response.ok) {
        const response_json = await response.json();
        throw new Error(JSON.stringify(response_json.detail));
      }

      const output_message = innerReadOnly
        ? `Borrado exitoso`
        : `Registro exitoso`;
      alert(output_message);
      window.location.reload();
    } catch (error: unknown) {
      if (error instanceof Error) {
        alert(error.message);
      } else if (
        typeof error === 'object' &&
        error !== null &&
        'message' in error &&
        'componentStack' in error
      ) {
        const reactError = error as React.ErrorInfo;
        alert(reactError);
      } else {
        alert(JSON.stringify(error));
      }
    }
  };

  const findCompanyByRFC = (rfc: string) => {
    return myCompaniesBackendList.find((company) => company.rfc === rfc)!;
  };

  const handleSelectChangeCompany = (
    selectedOption: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      company: selectedOption.target.value,
    }));
  };

  useEffect(() => {
    if (myCompaniesBackendList.length > 0) {
      setCompaniesRFCList(myCompaniesBackendList.map((obj) => obj.rfc));
    }
  }, [myCompaniesBackendList]);

  return (
    <div>
      {loaded ? (
        <PublishWorkForMyCompanyPresentation
          handleChangeWork={(workData: WorkUserFrontend) => {
            setFormValues((prevValues) => ({
              ...prevValues,
              work: workData,
            }));
          }}
          id={id}
          companiesList={companiesRFCList}
          handleSelectChangeCompany={handleSelectChangeCompany}
          innerReadOnly={readOnly}
          showForm={showForm}
          handleSubmit={handleSubmit}
          setLocationSelected={setLocationSelected}
          buttonLabel={
            buttonLabel != null
              ? buttonLabel
              : id.includes('ReviewMyCompaniesPublishedWorks')
              ? 'Editar Trabajo'
              : 'Publicar Trabajo'
          }
        />
      ) : (
        <p>Cargando...</p>
      )}
    </div>
  );
};

export default PublishWorkForMyCompanyContainer;
