import React, { useState } from 'react';
import registeredUsers from '../data/RegisteredUsers.json';

interface RegisteredUsers {
  active_users?: number;
  active_workers?: {
    [type: string]: number;
  };
  users_per_city?: {
    [type: string]: number;
  };
  active_companies?: number;
  active_company_types?: {
    [type: string]: number;
  };
  companies_per_city?: {
    [type: string]: number;
  };
}

const RegisteredUsersComponent: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  let timeoutId: NodeJS.Timeout | null = null;

  const userTypes: RegisteredUsers = registeredUsers as RegisteredUsers;

  const hasData = (obj: any) => obj && Object.keys(obj).length > 0;

  const handleMouseEnter = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      timeoutId = null;
    }
    setIsVisible(true);
  };

  const handleMouseLeave = () => {
    timeoutId = setTimeout(() => {
      setIsVisible(false);
    }, 5000);
  };

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <p>Usuarios Registrados:</p>
      {isVisible && (
        <>
          {userTypes.active_users !== undefined && (
            <p>Usuarios activos: {userTypes.active_users}</p>
          )}
          {userTypes.active_companies !== undefined && (
            <p>Compañías activas: {userTypes.active_companies}</p>
          )}

          {hasData(userTypes.active_workers) && (
            <div>
              <h3>Trabajadores Activos por Tipo:</h3>
              {Object.entries(userTypes.active_workers!).map(
                ([type, count], index) => (
                  <div key={index}>
                    <p>{type}</p>
                    <p>{count}</p>
                  </div>
                ),
              )}
            </div>
          )}

          {hasData(userTypes.active_company_types) && (
            <div>
              <h3>Tipos de Compañías Activas:</h3>
              {Object.entries(userTypes.active_company_types!).map(
                ([type, count], index) => (
                  <div key={index}>
                    <p>{type}</p>
                    <p>{count}</p>
                  </div>
                ),
              )}
            </div>
          )}

          {hasData(userTypes.users_per_city) && (
            <div>
              <h3>Usuarios por Ciudad:</h3>
              {Object.entries(userTypes.users_per_city!).map(
                ([city, count], index) => (
                  <div key={index}>
                    <p>{city}</p>
                    <p>{count}</p>
                  </div>
                ),
              )}
            </div>
          )}

          {hasData(userTypes.companies_per_city) && (
            <div>
              <h3>Compañías por Ciudad:</h3>
              {Object.entries(userTypes.companies_per_city!).map(
                ([city, count], index) => (
                  <div key={index}>
                    <p>{city}</p>
                    <p>{count}</p>
                  </div>
                ),
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default RegisteredUsersComponent;
