import React from 'react';
import CustomizedMenuListContainer from '../containers/CustomizedMenuListContainer';
import MakeOfferContainer from '../containers/MakeOfferContainer';
import {
  WorkCompanyBackendFromAPI,
  WorkUserBackend,
  WorkOfferFrontend,
} from '../services/BackendFrontendInterfaces';
import { OfferOption } from '../containers/PublishWorkContainer';

interface PresentationProps {
  formValues: Omit<WorkOfferFrontend, 'user' | 'company'>;
  companiesList: string[];
  workDataRaw?: WorkUserBackend | WorkCompanyBackendFromAPI;
  handleSelectChangeCompany: (
    selectedOption: React.ChangeEvent<HTMLSelectElement>,
  ) => void;
  loaded?: boolean;
  id?: string;
  innerReadOnly?: boolean;
  showForm: boolean;
  handleSubmit: (event: React.FormEvent) => void;
  onChange?:
    | null
    | ((offerData: Omit<WorkOfferFrontend, 'user' | 'company'>) => void);
}

const MakeCompanyOfferPresentation: React.FC<PresentationProps> = ({
  formValues,
  workDataRaw,
  companiesList = [],
  handleSelectChangeCompany,
  loaded = false,
  id = 'MakeCompanyOffer',
  innerReadOnly = false,
  showForm,
  handleSubmit,
  onChange,
}) => {
  return (
    <div>
      {loaded ? (
        <div>
          {showForm && (
            <div>
              <p>Compañía:</p>
              {companiesList.length > 0 ? (
                <div>
                  <CustomizedMenuListContainer
                    id={`CustomizedMenuList${id}`}
                    options={companiesList}
                    readOnly={innerReadOnly}
                    onItemClick={handleSelectChangeCompany}
                  />
                </div>
              ) : (
                <div>Cargando...</div>
              )}
            </div>
          )}
          <MakeOfferContainer
            formValuesFather={formValues}
            id={id}
            work={workDataRaw!}
            handleSubmitFather={handleSubmit}
            onChange={onChange}
          />
        </div>
      ) : (
        <p>Cargando...</p>
      )}
    </div>
  );
};

export default MakeCompanyOfferPresentation;
