import React, { useState, useEffect } from 'react';
import CheckboxListPresentation from '../components/CheckBoxListPresentation';

interface CheckboxListContainerProps {
  options: string[];
  checkboxListTitle: string;
  handleCheckboxSelectedChange: (selectedValues: string[]) => void;
}

const CheckboxListContainer: React.FC<CheckboxListContainerProps> = ({
  options,
  checkboxListTitle,
  handleCheckboxSelectedChange,
}) => {
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSelectedValues((prevSelectedValues) => {
      if (prevSelectedValues.includes(value)) {
        return prevSelectedValues.filter((item) => item !== value);
      } else {
        return [...prevSelectedValues, value];
      }
    });
  };

  useEffect(() => {
    handleCheckboxSelectedChange(selectedValues);
  }, [selectedValues]);

  return (
    <CheckboxListPresentation
      checkboxListTitle={checkboxListTitle}
      options={options}
      selectedValues={selectedValues}
      onCheckboxChange={handleCheckboxChange}
    />
  );
};

export default CheckboxListContainer;
