import React, { useState } from 'react';
import { makeRequest, isLogged } from '../services/requests';
import LoginPresentation from '../components/LoginPresentation';
import Cover from '../components/Cover';

interface LoginContainerProps {
  setLoggedUser: (isLogged: boolean) => void;
}

const LoginContainer: React.FC<LoginContainerProps> = ({ setLoggedUser }) => {
  const [curp, setCurp] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      const path = `user/login`;
      const method = 'POST';
      const content_type = 'application/x-www-form-urlencoded';
      const body = new URLSearchParams({ curp, password });
      const response = await makeRequest({
        path: path,
        method: method,
        body: body,
        contentType: content_type,
      });

      if (!response.ok) {
        const response_json = await response.json();
        throw new Error(JSON.stringify(response_json.detail));
      }

      setLoggedUser(isLogged());
    } catch (error: unknown) {
      if (error instanceof Error) {
        alert(error.message);
      } else if (
        typeof error === 'object' &&
        error !== null &&
        'message' in error &&
        'componentStack' in error
      ) {
        const reactError = error as React.ErrorInfo;
        alert(reactError);
      } else {
        alert(JSON.stringify(error));
      }
    }
  };

  if (isLogged()) {
    return <Cover />;
  } else {
    return (
      <LoginPresentation
        curp={curp}
        password={password}
        onCurpChange={setCurp}
        onPasswordChange={setPassword}
        onSubmit={handleSubmit}
      />
    );
  }
};

export default LoginContainer;
