import React, { useState, useEffect } from 'react';
import { makeRequest } from '../services/requests';

const InviteSomeone = () => {
  const [invitationLink, setInvitationLink] = useState<string | null>(null);

  const getInvitationToken = async () => {
    try {
      let path: string = 'user/invite';
      const method = 'GET';

      const response = await makeRequest({ path: path, method: method });
      if (!response.ok) {
        const response_json = await response.json();
        throw new Error(JSON.stringify(response_json.detail));
      }
      const data = await response.json();
      setInvitationLink(`${data.invitation_token}`);
    } catch (error: unknown) {
      if (error instanceof Error) {
        alert(error.message);
      } else if (
        typeof error === 'object' &&
        error !== null &&
        'message' in error &&
        'componentStack' in error
      ) {
        const reactError = error as React.ErrorInfo;
        alert(reactError);
      } else {
        alert(JSON.stringify(error));
      }
    }
  };

  useEffect(() => {
    getInvitationToken();
  }, []);

  return (
    <div>
      {invitationLink == null ? (
        <p>Cargando...</p>
      ) : (
        <div>
          <p>Por Favor Envía A Tu Invitado Este Token:</p>
          <br />
          <p id="invitationToken">{`${invitationLink}`}</p>
        </div>
      )}
    </div>
  );
};

export default InviteSomeone;
