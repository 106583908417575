import Cover from '../components/Cover';
import IconList from '../components/IconList';
import SupportContainer from '../containers/SupportContainer';
import EditCompanyFormContainer from '../containers/EditCompanyFormContainer';
import EditUserFormContainer from '../containers/EditUserFormContainer';
import LocationCompanyFormContainer from '../containers/LocationCompanyFormContainer';
import LocationFormContainer from '../containers/LocationFormContainer';
import Login from '../containers/LoginContainer';
import MyLocationsShowContainer from '../containers/MyLocationsShowContainer';
import PublishWorkContainer from '../containers/PublishWorkContainer';
import RegistrationCompanyFormContainer from '../containers/RegistrationCompanyFormContainer';
import RegistrationUserFormContainer from '../containers/RegistrationUserFormContainer';
import MyCompaniesLocationsShowContainer from '../containers/MyCompaniesLocationsShowContainer';
import PublishWorkForMyCompanyContainer from '../containers/PublishWorkForMyCompanyContainer';
import NotTakenWorksContainer from '../containers/NotTakenWorksContainer';
import ReviewMyPublishedWorksContainer from '../containers/ReviewMyPublishedWorksContainer';
import ReviewMyCompaniesPublishedWorksContainer from '../containers/ReviewMyCompaniesPublishedWorksContainer';
import ReviewMyOffersContainer from '../containers/ReviewMyOffersContainer';
import ReviewMyCompaniesOffersContainer from '../containers/ReviewMyCompaniesOffersContainer';
import MyCompletedOffersContainer from '../containers/MyCompletedOffersContainer';
import MyCompaniesCompletedOffersContainer from '../containers/MyCompaniesCompletedOffersContainer';
import MyCompletedWorksContainer from '../containers/MyCompletedWorksContainer';
import MyCompaniesCompletedWorksContainer from '../containers/MyCompaniesCompletedWorksContainer';
import InviteSomeone from '../components/InviteSomeone';
import MyApprovalsContainer from '../containers/MyApprovalsContainer';
import MyCompaniesApprovalsContainer from '../containers/MyCompaniesApprovalsContainer';
import MyDisapprovalsContainer from '../containers/MyDisapprovalsContainer';
import MyCompaniesDisapprovalsContainer from '../containers/MyCompaniesDisapprovalsConatiner';

interface SectionPresentationProps {
  selectedOption: string;
  setLoggedUser: (logged: boolean) => void;
  setComponentSelected: (selected: string) => void;
}

function SectionPresentation({
  selectedOption,
  setLoggedUser,
  setComponentSelected,
}: SectionPresentationProps) {
  setComponentSelected(selectedOption);
  switch (selectedOption) {
    case 'index':
      return <Cover />;
    case 'registerUser':
      return <RegistrationUserFormContainer />;
    case 'login':
      return <Login setLoggedUser={setLoggedUser} />;
    case 'editProfile':
      return <EditUserFormContainer />;
    case 'MyCompletedWorks':
      return <MyCompletedWorksContainer />;
    case 'MyCompanyCompletedWorks':
      return <MyCompaniesCompletedWorksContainer />;
    case 'MyApprovals':
      return <MyApprovalsContainer />;
    case 'MyDisapprovals':
      return <MyDisapprovalsContainer />;
    case 'MyCompaniesApprovals':
      return <MyCompaniesApprovalsContainer />;
    case 'MyCompaniesDisapprovals':
      return <MyCompaniesDisapprovalsContainer />;
    case 'MyCompletedOffers':
      return <MyCompletedOffersContainer />;
    case 'MyCompanyCompletedOffers':
      return <MyCompaniesCompletedOffersContainer />;
    case 'locationsShow':
      return <MyLocationsShowContainer />;
    case 'locationsRegister':
      return <LocationFormContainer readOnly={false} />;
    case 'registerCompany':
      return <RegistrationCompanyFormContainer readOnly={false} />;
    case 'editMyCompaniesInfo':
      return <EditCompanyFormContainer />;
    case 'locationsCompanyShow':
      return <MyCompaniesLocationsShowContainer />;
    case 'registerCompanyLocation':
      return <LocationCompanyFormContainer readOnly={false} />;
    case 'inviteSomeone':
      return <InviteSomeone />;
    case 'publishWorkForMe':
      return <PublishWorkContainer />;
    case 'publishWorkForMyCompany':
      return <PublishWorkForMyCompanyContainer />;
    case 'viewOffersForMyWorks':
      return <ReviewMyPublishedWorksContainer />;
    case 'viewOffersForMyCompanyWorks':
      return <ReviewMyCompaniesPublishedWorksContainer />;
    case 'checkAvailableWorks':
      return <NotTakenWorksContainer />;
    case 'reviewMyApplications':
      return <ReviewMyOffersContainer />;
    case 'reviewMyCompaniesApplications':
      return <ReviewMyCompaniesOffersContainer />;
    case 'support':
      return <SupportContainer />;
    case 'logout':
      return <Cover />;
    default:
      return <Cover />;
  }
}

export default SectionPresentation;
