import React from 'react';
import { WorkOfferFrontend } from '../services/BackendFrontendInterfaces';
import CurrencyInputContainer from '../containers/CurrencyInputContainer';

interface ExternalWorksRegisterProps {
  readOnly?: boolean;
  id: string;
  externalWorksNumber: number;
  externalWorks: WorkOfferFrontend['externalWorks'];
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleExternalWorkChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    key: string,
  ) => void;
}

function ExternalWorksPresentation({
  readOnly = false,
  id = 'externalWorksRegister',
  externalWorksNumber,
  externalWorks,
  handleChange,
  handleExternalWorkChange,
}: ExternalWorksRegisterProps) {
  const renderTextInputs = () => {
    const inputs = [];
    for (let i = 0; i < externalWorksNumber; i++) {
      inputs.push(
        <div key={i}>
          <label htmlFor={`input${id}Number${i}`} id={`label${id}Number${i}`}>
            Trabajo:
          </label>
          <input
            readOnly={readOnly}
            id={`input${id}Number${i}`}
            type="text"
            value={externalWorks[i].externalWork}
            onChange={(e) => handleExternalWorkChange(e, i, 'externalWork')}
          />
          <CurrencyInputContainer
            id={`CurrencyInput${id}Budget${i}`}
            name="offer_budget"
            label="Presupuesto"
            required={true}
            readOnly={readOnly}
            value={externalWorks[i].externalWorkBudget}
            onChange={(e) =>
              handleExternalWorkChange(e, i, 'externalWorkBudget')
            }
          />
        </div>,
      );
    }
    return inputs;
  };

  return (
    <div>
      <p>
        <label
          htmlFor={`input${id}NumberExternalWorks`}
          id={`label${id}NumberExternalWorks`}
        >
          Trabajos Externos Requeridos (Maquinaria Pesada, Carretón De Escombro,
          Etc.)
        </label>
        <input
          readOnly={readOnly}
          id={`input${id}NumberExternalWorks`}
          type="number"
          min={1}
          max={20}
          value={externalWorksNumber}
          onChange={handleChange}
        />
      </p>
      {renderTextInputs()}
    </div>
  );
}

export default ExternalWorksPresentation;
