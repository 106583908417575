import React, { useState, useEffect, useContext } from 'react';
import { makeRequest } from '../services/requests';
import {
  CompanyBackend,
  LocationBackend,
  LocationCompanyBackend,
  LocationFrontend,
} from '../services/BackendFrontendInterfaces';
import LocationCompanyFormPresentation from '../components/LocationCompanyFormPresentation';
import constants from '../data/constants.json';
import { SessionContext } from '../services/SessionContextProvider';
import '../styles/FromLogo.css';

interface LocationCompanyFormContainerProps {
  id?: string;
  readOnly?: boolean;
  locationDataRaw?: LocationCompanyBackend[];
  buttonLabel?: string;
}

const LocationCompanyFormContainer: React.FC<
  LocationCompanyFormContainerProps
> = ({
  id = 'LocationCompany',
  readOnly = false,
  locationDataRaw,
  buttonLabel = 'Registrar Ubicación',
}) => {
  const [innerReadOnly, setInnerReadOnly] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const localCurrenciesList = constants.currencies_spanish;

  const {
    myCompaniesBackendCache,
    setMyCompaniesBackendCache,
    setMyLocationsCompaniesBackendCache,
  } = useContext(SessionContext);

  const [myCompaniesBackend, setMyCompaniesBackend] = useState<
    CompanyBackend[]
  >([]);
  const [companiesList, setCompaniesList] = useState<string[]>([]);
  const [selectedCompanyRFC, setSelectedCompanyRFC] = useState<string>('');
  const [formValues, setFormValues] = useState<LocationFrontend>({
    country: '',
    state: '',
    city: '',
    neighborhood: '',
    district: '',
    postCode: '',
    street: '',
    building: '',
    outerNumber: '',
    outerLetter: '',
    innerNumber: '',
    innerLetter: '',
    localCurrency: Object.keys(localCurrenciesList).indexOf('MXN'),
    gpsCenterMapsLink: '',
    longitude: 99999,
    latitude: -99999,
    proofOfAddress: null,
  });

  useEffect(() => {
    setInnerReadOnly(readOnly);
    if (locationDataRaw != null) {
      setFormValues((prevValues) => ({
        ...prevValues,
        country: locationDataRaw[0].locations[0].country.country_name,
        state: locationDataRaw[0].locations[0].state.state_name,
        city: locationDataRaw[0].locations[0].city.city_name,
        neighborhood:
          locationDataRaw[0].locations[0].neighborhood.neighborhood_name,
        district:
          locationDataRaw[0].locations[0].district != null
            ? locationDataRaw[0].locations[0].district.district_name
            : '',
        postCode: locationDataRaw[0].locations[0].post_code.value,
        street: locationDataRaw[0].locations[0].street.street_name,
        building:
          locationDataRaw[0].locations[0].building != null
            ? locationDataRaw[0].locations[0].building.building_name
            : '',
        outerNumber: locationDataRaw[0].locations[0].outer_number.toString(),
        outerLetter:
          locationDataRaw[0].locations[0].outer_letter != null
            ? locationDataRaw[0].locations[0].outer_letter
            : '',
        innerNumber:
          locationDataRaw[0].locations[0].inner_number != null
            ? locationDataRaw[0].locations[0].inner_number.toString()
            : '',
        innerLetter:
          locationDataRaw[0].locations[0].inner_letter != null
            ? locationDataRaw[0].locations[0].inner_letter
            : '',
        localCurrency: locationDataRaw[0].locations[0].local_currency,
        gpsCenterMapsLink: locationDataRaw[0].locations[0].gps_center_maps_link,
        longitude: locationDataRaw[0].locations[0].longitude,
        latitude: locationDataRaw[0].locations[0].latitude,
      }));
      const company: CompanyBackend[] = locationDataRaw.map(
        (loc) => loc.company,
      );
      setMyCompaniesBackend(company);
    } else {
      const fetchData = async () => {
        try {
          let data =
            myCompaniesBackendCache === null ? [] : myCompaniesBackendCache;
          if (myCompaniesBackendCache == null) {
            const response = await makeRequest({
              path: 'company/get_my_companies',
              method: 'GET',
            });
            if (!response.ok) {
              const response_json = await response.json();
              throw new Error(JSON.stringify(response_json.detail));
            }
            data = await response.json();
            setMyCompaniesBackendCache(data);
          }
          setMyCompaniesBackend(data);
        } catch (error: unknown) {
          if (error instanceof Error) {
            alert(error.message);
          } else if (
            typeof error === 'object' &&
            error !== null &&
            'message' in error &&
            'componentStack' in error
          ) {
            const reactError = error as React.ErrorInfo;
            alert(reactError);
          } else {
            alert(JSON.stringify(error));
          }
        }
      };
      fetchData();
    }
    setLoaded(true);
  }, []);

  const handleSelectChangeCompany = (
    selectedOption: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setSelectedCompanyRFC(selectedOption.target.value);
  };

  useEffect(() => {
    if (myCompaniesBackend.length > 0) {
      setCompaniesList(myCompaniesBackend.map((obj) => obj.rfc));
    }
  }, [myCompaniesBackend]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const companyLocationToDelete: LocationCompanyBackend =
      locationDataRaw?.find((cpy) => cpy.company.rfc === selectedCompanyRFC)!;

    try {
      const obj_to_send: {
        company: CompanyBackend;
        location: LocationBackend;
      } =
        innerReadOnly && locationDataRaw != null
          ? {
              company: companyLocationToDelete.company,
              location: locationDataRaw[0].locations[0],
            }
          : {
              location: {
                outer_number: parseInt(formValues.outerNumber),
                outer_letter:
                  formValues.outerLetter !== ''
                    ? formValues.outerLetter
                    : undefined,
                inner_number:
                  formValues.innerNumber !== ''
                    ? parseInt(formValues.innerNumber)
                    : undefined,
                inner_letter:
                  formValues.innerLetter !== ''
                    ? formValues.innerLetter
                    : undefined,
                local_currency: formValues.localCurrency,
                gps_center_maps_link: formValues.gpsCenterMapsLink,
                longitude: formValues.longitude,
                latitude: formValues.latitude,
                country: {
                  country_name: formValues.country,
                },
                state: {
                  state_name: formValues.state,
                },
                city: {
                  city_name: formValues.city,
                },
                neighborhood: {
                  neighborhood_name: formValues.neighborhood,
                },
                district:
                  formValues.district !== ''
                    ? {
                        district_name: formValues.district,
                      }
                    : { district_name: '' },
                post_code: {
                  value: formValues.postCode,
                },
                street: {
                  street_name: formValues.street,
                },
                building:
                  formValues.building !== ''
                    ? {
                        building_name: formValues.building,
                      }
                    : { building_name: '' },
              },
              company:
                myCompaniesBackend[companiesList.indexOf(selectedCompanyRFC)],
            };

      const path = innerReadOnly
        ? `location/delete_my_company_location`
        : `location/register_my_company_location`;
      const method = innerReadOnly ? 'DELETE' : 'POST';

      const content_type = 'application/json';
      setMyLocationsCompaniesBackendCache(null);
      const response = await makeRequest({
        path: path,
        method: method,
        body: JSON.stringify(obj_to_send),
        contentType: content_type,
      });

      if (!response.ok) {
        const response_json = await response.json();
        throw new Error(JSON.stringify(response_json.detail));
      }

      if (
        path === 'location/register_my_company_location' &&
        formValues.proofOfAddress
      ) {
        const header = {
          Accept: 'application/json',
        };
        const my_location: LocationBackend = await response.json();

        const pathToProofOfAddress = `location/proof_of_address/${my_location.id!.toString()}`;
        const formData = new FormData();
        formData.append('proof_of_address', formValues.proofOfAddress);
        formData.append('company', JSON.stringify(obj_to_send.company));

        const result = await makeRequest({
          path: pathToProofOfAddress,
          method: 'POST',
          body: formData,
          header: header,
        });
      }
      const output_message = innerReadOnly
        ? `Borrado exitoso`
        : `Registro exitoso`;
      alert(output_message);
      window.location.reload();
    } catch (error: unknown) {
      if (error instanceof Error) {
        alert(error.message);
      } else if (
        typeof error === 'object' &&
        error !== null &&
        'message' in error &&
        'componentStack' in error
      ) {
        const reactError = error as React.ErrorInfo;
        alert(reactError);
      } else {
        alert(JSON.stringify(error));
      }
    }
  };

  return (
    <LocationCompanyFormPresentation
      id={`locationCompanyForm${id}`}
      handleChangeLocation={(locationData: LocationFrontend) => {
        setFormValues(locationData);
      }}
      companiesList={companiesList}
      handleSelectChangeCompany={handleSelectChangeCompany}
      loaded={loaded}
      handleSubmit={handleSubmit}
      innerReadOnly={innerReadOnly}
      locationDataRaw={
        locationDataRaw != null ? locationDataRaw[0].locations[0] : null
      }
      buttonLabel={
        locationDataRaw === undefined ? buttonLabel : 'Borrar Ubicación'
      }
    />
  );
};

export default LocationCompanyFormContainer;
