import React, { useEffect, useState } from 'react';
import CustomizedMenusPresentation from '../components/CustomizedMenusPresentation';
import constants from '../data/constants.json';

/**
 * This menu is not the main menu in the header, it's a component that displays values for the user to select one.
 *
 * @prop options An object containing options for the user to choose from. It is an object because the key might be a key and the value can be in any language
 * @prop onItemClick A function that gets called when an option is selected. It should take three arguments: the change event, the selected value, and a boolean indicator (in this case, assuming false for "isMale," but you should provide the correct value).
 * @prop keys An optional boolean value indicating whether to use the keys from the "options" object as initial values.
 * @prop initialize An optional number indicating the index of the initially selected option when "keys" is true.
 * @prop readOnly An optional boolean value indicating whether the component should be displayed in read-only mode.
 */

const selectorCriterio = constants.keyOrValue;

interface CustomizedMenusContainerProps {
  id: string;
  keyOrValue: boolean;
  options: { [key: string]: string | boolean };
  onItemClick: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  initialize?: number;
  readOnly?: boolean;
}

function CustomizedMenusContainer({
  id = 'CustomizedMenus',
  keyOrValue = selectorCriterio.Key,
  options,
  onItemClick,
  initialize = 0,
  readOnly = false,
}: CustomizedMenusContainerProps) {
  const [selectedKey, setSelectedKey] = useState<string>(
    Object.keys(options)[initialize],
  );

  useEffect(() => {
    const simulatedEvent = {
      target: {
        value: selectedKey,
      },
    } as React.ChangeEvent<HTMLSelectElement>;
    onItemClick(simulatedEvent);
  }, [selectedKey]);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedKey(event.target.value);
  };

  return (
    <CustomizedMenusPresentation
      id={id}
      keyOrValue={keyOrValue}
      options={options}
      selectedValue={selectedKey}
      onSelectChange={handleSelectChange}
      readOnly={readOnly}
    />
  );
}

export default CustomizedMenusContainer;
