import React, { useEffect, useState } from 'react';
import ExternalWorksPresentation from '../components/ExternalWorksPresentation';
import { WorkOfferFrontend } from '../services/BackendFrontendInterfaces';

interface ExternalWorksRegisterContainerProps {
  id: string;
  onRegisteredExternalWorks: (
    externalWorks: WorkOfferFrontend['externalWorks'],
  ) => void;
  initialExternalWorks: WorkOfferFrontend['externalWorks'];
  readOnly: boolean;
}

const ExternalWorksContainer: React.FC<ExternalWorksRegisterContainerProps> = ({
  id = 'ExternalWorksRegister',
  onRegisteredExternalWorks,
  initialExternalWorks = [],
  readOnly = false,
}) => {
  const [externalWorksNumber, setExternalWorksNumbers] = useState<number>(
    initialExternalWorks.length ? initialExternalWorks.length : 1,
  );
  const [externalWorks, setExternalWorks] = useState<
    WorkOfferFrontend['externalWorks']
  >(
    initialExternalWorks.length
      ? initialExternalWorks
      : [{ externalWork: '', externalWorkBudget: '0' }],
  );
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value);
    if (newValue < 1) {
      setExternalWorksNumbers(1);
      setExternalWorks([{ externalWork: '', externalWorkBudget: '0' }]);
    } else if (newValue > 10) {
      setExternalWorksNumbers(10);
      setExternalWorks(externalWorks.slice(0, 10));
    } else {
      setExternalWorksNumbers(newValue);

      const newExternalWorksData =
        newValue > externalWorks.length
          ? [
              ...externalWorks,
              ...Array(newValue - externalWorks.length).fill({
                externalWork: '',
                externalWorkBudget: '0',
              }),
            ]
          : externalWorks.slice(0, newValue);

      setExternalWorks(newExternalWorksData);
    }
  };

  const handleExternalWorkChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    key: string,
  ) => {
    const newExternalWorks = [...externalWorks];
    newExternalWorks[index] = {
      ...newExternalWorks[index],
      [key]: event.target.value,
    };
    setExternalWorks(newExternalWorks);
  };

  useEffect(() => {
    onRegisteredExternalWorks(externalWorks);
  }, [externalWorks, externalWorksNumber]);

  return (
    <ExternalWorksPresentation
      id={id}
      readOnly={readOnly}
      externalWorksNumber={externalWorksNumber}
      externalWorks={externalWorks}
      handleChange={handleChange}
      handleExternalWorkChange={handleExternalWorkChange}
    />
  );
};

export default ExternalWorksContainer;
