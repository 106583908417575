import React from 'react';

/**
 * This component is the presentation of a customized menu list
 * @options: a list of options in string for the user to select
 * @readOnly: a boolean that defines if the component is to show the option selected or to select option
 * @selectedOption: the option selected, is used to be shown in the <option>
 * @onItenClick: function that spread the event to the container part of this component
 */

interface CustomizedMenuListPresentationProps {
  id: string;
  name?: string;
  options: string[];
  readOnly?: boolean;
  selectedOption: string;
  onItemClick: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const CustomizedMenuListPresentation: React.FC<
  CustomizedMenuListPresentationProps
> = ({
  id = 'CustomizedMenuList',
  name = '',
  options,
  readOnly = false,
  selectedOption,
  onItemClick,
}) => {
  return (
    <select
      value={selectedOption}
      onChange={(event) => onItemClick(event)}
      id={`select${id}`}
      name={`select${name}`}
    >
      {options.map((option, index) => (
        <option
          key={index}
          value={option}
          disabled={readOnly}
          id={`option${id}${option}`}
        >
          {option}
        </option>
      ))}
    </select>
  );
};

export default CustomizedMenuListPresentation;
