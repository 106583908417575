import constants from '../data/constants.json';

const getCSRFCookieValue = (): string | null => {
  const matchingCookie = document.cookie
    .split(';')
    .find((cookie) => cookie.trim().startsWith('csrftoken='));
  if (matchingCookie) {
    return matchingCookie.trim().split('=')[1];
  } else {
    return null;
  }
};

const isLogged = (): boolean => {
  const matchingCookie = document.cookie
    .split(';')
    .find((cookie) =>
      cookie.trim().startsWith(`${constants['LOGGED_COOKIE_NAME']}=`),
    );
  if (matchingCookie) {
    return true;
  } else {
    return false;
  }
};

interface MakeRequestOptions {
  path: string;
  method: string;
  body?: BodyInit | string | object;
  contentType?: string;
  header?: { [key: string]: string };
}

const makeRequest = ({
  path,
  method,
  body = undefined,
  contentType = undefined,
  header,
}: MakeRequestOptions): Promise<Response> => {
  const host = document.URL.includes('7pro')
    ? `${process.env.REACT_APP_API_ENDPOINT_PRODUCTION}`
    : `${process.env.REACT_APP_API_ENDPOINT_DEVELOPMENT}`;
  const headers = new Headers();

  if (contentType) {
    headers.append('content-type', contentType);
  }

  if (header) {
    Object.keys(header).forEach((key) => {
      headers.append(key, header['key']);
    });
  }

  const csrfToken = getCSRFCookieValue();

  if (csrfToken) {
    headers.append('x-csrftoken', csrfToken);
  }

  if (typeof body === 'object') {
    if (body instanceof URLSearchParams) {
      body = body.toString();
    } else if (body instanceof FormData) {
    } else {
      body = JSON.stringify(body);
    }
  }

  return fetch(`${host}${path}`, {
    method,
    headers,
    body,
    credentials: 'include',
  })
    .then(async (response) => {
      if (!response.ok) {
        const errorData = await response.json();
        throw { status: response.status, detail: errorData.detail };
      }
      return response;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export { makeRequest, getCSRFCookieValue, isLogged };
