import React, { ChangeEvent } from 'react';

interface CellPhonesInputProps {
  id: string;
  cellNum: number;
  cellPhones: number[];
  readOnly?: boolean;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleCelularChange: (
    event: ChangeEvent<HTMLInputElement>,
    index: number,
  ) => void;
}

const CellPhonesInput: React.FC<CellPhonesInputProps> = ({
  id = 'CellPhonesInput',
  cellNum,
  cellPhones,
  readOnly = false,
  handleChange,
  handleCelularChange,
}) => {
  const renderInputsDeTexto = () => {
    const inputs = [];
    for (let i = 0; i < cellNum; i++) {
      inputs.push(
        <div key={i}>
          <label htmlFor={`input${id}${i}`} id={`label${id}${i}`}>
            Teléfono celular {i + 1}:
          </label>
          <input
            id={`input${id}${i}`}
            type="text"
            disabled={readOnly}
            onChange={(e) => handleCelularChange(e, i)}
            value={cellPhones[i] || ''}
          />
        </div>,
      );
    }
    return inputs;
  };

  return (
    <div>
      <p>
        <label
          htmlFor={`input${id}CellPhonesNumber`}
          id={`label${id}CellPhonesNumber`}
        >
          ¿Cuántos teléfonos celulares quieres registrar?
        </label>
        <input
          id={`input${id}CellPhonesNumber`}
          type="number"
          min={1}
          max={10}
          disabled={readOnly}
          value={cellNum}
          onChange={handleChange}
        />
      </p>
      {renderInputsDeTexto()}
    </div>
  );
};

export default CellPhonesInput;
