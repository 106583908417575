import { Wrapper, Status } from '@googlemaps/react-wrapper';
import MapWWrapperContainer from '../containers/MapWWrapperContainer';
import React, { SetStateAction, useEffect, Dispatch, useState } from 'react';
import MarkerWWrapper from './MarkerWWrapper';
import {
  LocationBackend,
  LocationCompanyBackend,
  WorkUserBackend,
} from '../services/BackendFrontendInterfaces';
const render = (status: Status) => {
  return <h1>{status}</h1>;
};

interface MapContainerProps {
  id: string;
  locationsDataRawList:
    | LocationBackend[]
    | LocationCompanyBackend[]
    | WorkUserBackend[];
  handleSelectedPosition?: (coords: { lat: number; lng: number }) => void;
  setReturnedData?:
    | Dispatch<SetStateAction<LocationBackend | null>>
    | Dispatch<SetStateAction<LocationCompanyBackend[] | null>>
    | Dispatch<SetStateAction<WorkUserBackend | null>>;
}

export interface LatLngCustomized extends google.maps.LatLng {
  id?: number;
  dataRaw?: LocationBackend | LocationCompanyBackend[] | WorkUserBackend;
  infoWindow?: google.maps.InfoWindow;
}

const WrapperForMap: React.VFC<MapContainerProps> = ({
  id = 'wrapperForMap',
  locationsDataRawList,
  handleSelectedPosition,
  setReturnedData,
}) => {
  const [markers, setMarkers] = useState<LatLngCustomized[]>([]);
  const [zoom, setZoom] = useState(3);
  const [center, setCenter] = useState<google.maps.LatLngLiteral>({
    lat: 0,
    lng: 0,
  });
  const [loaded, setLoaded] = useState<boolean>(false);
  const [map, setMap] = useState<google.maps.Map | null>();

  const onClick = (e: google.maps.MapMouseEvent) => {
    if (handleSelectedPosition != null) {
      // avoid directly mutating state
      // @ts-ignore
      handleSelectedPosition(e.latLng.toJSON());
    }
  };

  const onIdle = (m: google.maps.Map) => {
    if (markers.length > 0) {
      setZoom(10);
      setCenter(markers[0].toJSON());
    } else {
      setZoom(m.getZoom()!);
      setCenter(m.getCenter()!.toJSON());
    }
  };

  return (
    <div style={{ display: 'flex', height: '80vh', width: '100%' }}>
      <Wrapper apiKey={process.env.REACT_APP_GMAPS_KEY || ''} render={render}>
        <MapWWrapperContainer
          setMap={setMap}
          map={map != null ? map : undefined}
          center={center}
          onClick={onClick}
          onIdle={onIdle}
          zoom={zoom}
          id={id}
          locationsDataRawList={locationsDataRawList}
          setMarkers={setMarkers}
          setLoaded={setLoaded}
          style={{ flexGrow: '1', height: '100%' }}
          setReturnedData={setReturnedData}
        >
          {markers.map((latLng, i) => (
            // @ts-ignore
            <MarkerWWrapper
              key={i}
              position={latLng}
              id={`markerWWrapper${id}${latLng.id}`}
              rawData={latLng.dataRaw!}
              infoWindow={latLng.infoWindow}
              onClick={(event: google.maps.MapMouseEvent) => {
                markers[i].infoWindow?.open(map!);
              }}
              onMouseover={(event: google.maps.MapMouseEvent) => {
                // markers[i].infoWindow?.open(map!)
                // event: {"latLng":{"lat":20.34788713981582,"lng":-103.069157421875},"domEvent":{"isTrusted":true},"pixel":{"x":-183,"y":-41}}
              }}
            />
          ))}
        </MapWWrapperContainer>
      </Wrapper>
    </div>
  );
};

export default WrapperForMap;
