import React, { useState, useEffect, useContext } from 'react';
import { makeRequest } from '../services/requests';
import MyCompaniesApprovalsPresentation from '../components/MyCompaniesApprovalsPresentation';
import { CardsGridCaller } from '../components/GridCard';
import { ApproveUserCompanyBackend } from '../services/BackendFrontendInterfaces';
import { SessionContext } from '../services/SessionContextProvider';

interface MyCompaniesApprovalsProps {
  id?: CardsGridCaller;
}

function MyCompaniesApprovalsContainer({
  id = 'myCompaniesApprovals',
}: MyCompaniesApprovalsProps) {
  const {
    myCompaniesApprovalsBackendCache,
    setMyCompaniesApprovalsBackendCache,
  } = useContext(SessionContext);

  async function fetchData() {
    const path = `networking/get_all_my_companies_approvals`;
    const method = 'GET';
    try {
      const response = await makeRequest({ path: path, method: method });
      const data: ApproveUserCompanyBackend[] = await response.json();
      setMyCompaniesApprovalsBackendCache(data);
    } catch (error: unknown) {
      if (error instanceof Error) {
        alert(error.message);
      } else if (
        typeof error === 'object' &&
        error !== null &&
        'message' in error &&
        'componentStack' in error
      ) {
        const reactError = error as React.ErrorInfo;
        alert(reactError);
      } else {
        alert(JSON.stringify(error));
      }
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  if (
    myCompaniesApprovalsBackendCache == null ||
    myCompaniesApprovalsBackendCache?.length == 0
  ) {
    return <h1>No se encontraron Calificaciones Negativas</h1>;
  } else {
    return (
      <MyCompaniesApprovalsPresentation
        id={id}
        approvalsDataRaw={myCompaniesApprovalsBackendCache}
      />
    );
  }
}

export default MyCompaniesApprovalsContainer;
