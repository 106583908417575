import {
  ApproveTortoiseUserCompanyBackend,
  ApproveUserCompanyFrontend,
  DeadWorkOfferBackend,
} from '../services/BackendFrontendInterfaces';

interface ApproveUserCompanyPresentationProps {
  id?: string;
  loaded: boolean;
  approval?: ApproveTortoiseUserCompanyBackend;
  deadWorkOfferDataRaw: DeadWorkOfferBackend;
  handleSubmit: (event: React.FormEvent) => void;
  formValues: ApproveUserCompanyFrontend;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleTextAreaChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}
function ApproveUserCompanyPresentation({
  id = 'ApproveUserCompanyCompleted',
  loaded,
  approval,
  handleSubmit,
  formValues,
  handleChange,
  handleTextAreaChange,
}: ApproveUserCompanyPresentationProps) {
  return (
    <div>
      {loaded ? (
        <form onSubmit={handleSubmit} id={`Form${id}`}>
          <label htmlFor={`input${id}Rate`} id={`label${id}Rate`}>
            Calificación (1 a 5):
          </label>
          <br />
          <input
            id={`input${id}Rate`}
            name="rate"
            type="text"
            disabled={false}
            value={formValues.rate}
            onChange={handleChange}
          />
          <br />
          <label htmlFor={`input${id}Summary`} id={`label${id}Summary`}>
            Resumen:
          </label>
          <br />
          <input
            id={`input${id}Summary`}
            name="summary"
            type="text"
            disabled={false}
            value={formValues.summary}
            onChange={handleChange}
          />
          <br />
          <br />
          <label htmlFor={`input${id}Details`} id={`label${id}Details`}>
            Evaluación Detallada:
          </label>
          <br />
          <textarea
            id={`input${id}Details`}
            name="details"
            disabled={false}
            required
            value={formValues.details}
            onChange={handleTextAreaChange}
            rows={8}
            style={{ width: '60%' }}
          />
          <br />
          <button
            id={`button${id}SubmitApproval`}
            type="submit"
            disabled={false}
          >
            {'Enviar Evaluación'}
          </button>
        </form>
      ) : (
        <p>Cargando...</p>
      )}
    </div>
  );
}

export default ApproveUserCompanyPresentation;
